export function generateAffiliateColumns(mobile) {  return (
!mobile ? [
    {
        name: 'User',
        selector: row => row.user,
        cell: (d) => {
            return (
                <div className="user-container">
                    <img src={d.user.pfp} />
                    <span>{d.user.username}</span>
                </div>
            )
        },
        width: "40%"
    },
    {
        name: 'Date',
        sortable: true,
        selector: row => row.date,
        cell: (d) => {
            let date = new Date(d.date);
            let month = date.toLocaleString('en-EN', { month: 'long' });
            date = date.toLocaleString("en-EN").split("/");
            return (
                <div className="date">
                    {`${date[1]} ${month} ${date[2].split(",")[0]}`}
                </div>
            )
        }
    },
    {
        name: 'Total wager',
        sortable: true,
        selector: row => row.wager,
        cell: (d) => {
            let wager = d.wager.toFixed(2).toString();
            return (
                <div className="currency-container">
                    <img src="images/robux.svg" />
                    <span>{wager.split(".")[0]}<span className="float">.{wager.split(".")[1]}</span></span>
                </div>
            )
        }
    },
    {
        name: 'Total earnings',
        sortable: true,
        selector: row => row.earning,
        cell: (d) => {
            let earning = d.earning.toFixed(2).toString();
            return (
                <div className="currency-container">
                    <img src="images/robux.svg" />
                    <span>{earning.split(".")[0]}<span className="float">.{earning.split(".")[1]}</span></span>
                </div>
            )
        }
    },
] : [
    //Mobile columns
    {
        name: 'User',
        selector: row => row.user,
        cell: (d) => {

            let earning = d.earning.toFixed(2).toString();
            let wager = d.wager.toFixed(2).toString();

            let date = new Date(d.date);
            let month = date.toLocaleString('en-EN', { month: 'long' });
            date = date.toLocaleString("en-EN").split("/");

            return (
                <div className="mobile-row">
                    <div className="mobile-row-data user-info">
                        <div>
                            <img className="pfp" src={d.user.pfp} />
                            <span className="username">{d.user.username}</span>
                        </div>
                        <div>
                            <div className="date">
                                <span className="gray">{`${date[1]} ${month} ${date[2].split(",")[0]}`}</span>
                            </div>
                        </div>
                    </div>
                    <div className="earning-info mobile-row-data">
                        <div>
                            <span className="gray">Total wager</span>
                            <div className="currency-container">
                                <img src="images/robux.svg" />
                                <span>{wager.split(".")[0]}<span className="float">.{wager.split(".")[1]}</span></span>
                            </div>
                        </div>
                        <div>
                            <span className="gray">Total earnings</span>
                            <div className="currency-container">
                                <img src="images/robux.svg" />
                                <span>{earning.split(".")[0]}<span className="float">.{earning.split(".")[1]}</span></span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
]
)};