import { useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import useMediaQuery from '@mui/material/useMediaQuery';
import $ from 'jquery';
import { useUser } from '../../lib/hooks';
import { toast } from 'react-toastify';
import socket from '../../socket';
import { seperateBalance } from '../../helpers/balance';
import './mines.scss';

const opt = {
    hideProgressBar: true,
    theme: "dark",
    autoClose: 2000
};

const minesOG = [
    0, 0, 0, 0, 0,
    0, 0, 0, 0, 0,
    0, 0, 0, 0, 0,
    0, 0, 0, 0, 0,
    0, 0, 0, 0, 0,
];

export default function Mines() {

    const { data: user, isLoading, refetch } = useUser();

    const [betAmountInput, setBetAmountInput] = useState("");
    const [mineCountInput, setMineCountInput] = useState(4);
    const [betAmount, setBetAmount] = useState(0);
    const [mineCount, setMineCount] = useState(0);

    const [currencyFilter, setCurrencyFilter] = useState("all");
    const [selectedCurrency, setSelectedCurrency] = useState("robux");
    const [gameState, setGameState] = useState(0);
    /**
     * 0 --> Game not started
     * 1 --> Game started
     * 2 --> Game over
     */

    const [revealing, setRevealing] = useState(false);
    const [betDatas, setBetDatas] = useState([]);
    const [clickedAreas, setClickedAreas] = useState([]);
    const [mines, setMines] = useState(minesOG);
    const [nextMultiplication, setNextMultiplication] = useState(1);
    const [totalProfit, setTotalProfit] = useState(0);
    const [currentMultiplier, setCurrentMultiplier] = useState(0);
    const [gameResult, setGameResult] = useState(null) // 1 -> Win, 0 -> Lost

    function startGame() {
        toast.dismiss();

        if (user) {
            if (mineCountInput <= 0 || mineCountInput > 24)
                toast.error(<span><b>Error</b><br/>Mine count must be between 1-24</span>, opt);
            else if (betAmountInput < 1)
                toast.error(<span><b>Error</b><br/>Bet amount must be greater than 1</span>, opt);
            else {
                socket.emit("start-mines", {
                    user_id: user.id,
                    secret_id: user._id,
                    bet: parseFloat(betAmountInput),
                    mine_count: parseFloat(mineCountInput),
                    type: selectedCurrency
                });

                socket.on("mines-game-started", data => {
                    setBetAmount(data.bet);
                    setMineCount(data.mineCount);
                    setNextMultiplication(data.next_ratio.toFixed(2));
                    setCurrentMultiplier(1);
                    setTotalProfit(data.bet);
                    setGameState(1);
                    refetch();

                    socket.off("mines-game-started");
                    socket.off("start-mines");

                    toast.info(<span><b>Good luck!</b><br/>There're {mineCountInput} mines, be careful!</span>, opt);
                });
            }
        } else {
            toast.error(<span><b>Error</b><br/>You have to login to play Mines</span>, opt);
        }
    }

    function revealArea(index, e) {
        $(e.currentTarget).addClass("clicked-mine-anim");
        setRevealing(true);

        if (gameState === 1) {
            socket.emit("mines-reveal-area", {
                user_id: user.id,
                secret_id: user._id,
                index: index
            });
        }

        //Update clicked areas
        let cloneClickedAreas = [...clickedAreas];
        cloneClickedAreas.push(index);
        setClickedAreas(cloneClickedAreas);
    }

    function cashout() {
        if (clickedAreas.length > 0) {
            socket.emit("mines-cashout", {
                user_id: user.id,
                secret_id: user._id
            });
        } else {
            toast.dismiss();
            toast.error(<span><b>Error</b><br/>You have to reveal at least 1 area</span>, opt);
        }
    }

    function restart() {
        setMines(minesOG);
        setGameState(0);
        setClickedAreas([]);
        setGameResult(null);
        setRevealing(false);
    }

    useEffect(() => {
        socket.on("mines-area-revealed", data => {
            setMines(data.currentArea);
            setNextMultiplication(data.next_ratio.toFixed(2));
            setCurrentMultiplier(data.ratio.toFixed(2));
            setTotalProfit((data.ratio * data.bet).toFixed(2));
            setRevealing(false);

            setTimeout(() => {
                $(".wall").css("display", "none");
            }, 200);
        });

        socket.on("mines-game-lost", data => {
            setMines(data.mines);
            setGameState(2);
            setGameResult(0);
            setRevealing(false);
            refetch();

            toast.dismiss();
            toast.error(<span><b>Bad luck!</b><br/>You've lost <div className="amount">{seperateBalance(data.bet)}{data.type === "robux" ? <img src="images/robux.svg" /> : <img src="images/points.png" className='points' />}</div> from Mines.</span>, opt);
        });

        socket.on("mines-cashout-response", data => {
            let totalProfit_ = (data.ratio * data.bet);
            setMines(data.mines);
            setGameState(2);
            setGameResult(1);
            setCurrentMultiplier(data.ratio.toFixed(2));
            setTotalProfit(totalProfit_.toFixed(2));
            setRevealing(false);
            refetch();

            toast.dismiss();
            toast.success(<span><b>Amazing!</b><br/>You've won <div className="amount">{seperateBalance(totalProfit_)}{data.type === "robux" ? <img src="images/robux.svg" /> : <img src="images/points.png" className='points' />}</div> from Mines!</span>, opt);
        });

        socket.emit("get-mines-history");
        socket.on("mines-history", data => {
            setBetDatas(data);
        });
    }, []);

    const res_550 = useMediaQuery('(max-width:550px)');
    const res_930 = useMediaQuery('(max-width:930px)');

    //Currency dropdown
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <div id="mines">
            <div className='content'>
                <div className={'bet-placement ' + (gameState === 1 && "disabled")}>
                    <div className="amount-container" style={open ? {transition: "100ms", borderRadius: "1000px 1000px 1000px 0"} : {borderRadius: "1000px"}}>
                        <div className="currency-selection" style={open ? {backgroundColor: "#2A2F3D", borderRadius: "35px 0 0 0"} : {}}>
                            <Button
                                id="currency-button"
                                className={open ? "active" : undefined}
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                style={{ backgroundColor: 'transparent' }} 
                                onClick={(e) => {if (gameState !== 1) {
                                    handleClick(e);
                                }}}
                            >
                                { selectedCurrency === "robux"
                                    ? <>
                                    { gameState === 1 
                                        ? <img src="images/robux-gray-3.svg" className='robux' />
                                        : <img src="images/robux.svg" className='robux' />
                                    }
                                    </>
                                    : <img src="images/points.png" className='points' />
                                }
                                { open ? (
                                    <img className="down" src="images/down-white.svg" style={open ? {transform: "rotateZ(180deg)"} : {}} />
                                ) : (
                                    <img className="down" src="images/down.svg" style={open ? {transform: "rotateZ(180deg)"} : {}} />
                                )}
                            </Button>
                            <Menu
                                id="currency-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{'aria-labelledby': 'currency-button'}}
                                style={{marginTop: selectedCurrency === "points" ? "-10px" : "5px"}}
                            >
                                {selectedCurrency === "robux" ? (
                                    <MenuItem
                                        className="currency-menu-item" 
                                        onClick={() => { setSelectedCurrency("points"); handleClose(); }}
                                    >
                                        <img src="images/points.png" className='points' />
                                    </MenuItem>
                                ) : (
                                    <MenuItem
                                        className="currency-menu-item" 
                                        onClick={() => { setSelectedCurrency("robux"); handleClose(); }}
                                    >
                                        <img src="images/robux.svg" />
                                    </MenuItem>
                                )}
                            </Menu>
                        </div>
                        <div className="enter-bet-amount">
                            <input
                                type="number"
                                placeholder='0.00'
                                value={betAmountInput}
                                onChange={(e) => setBetAmountInput(parseFloat(parseFloat(e.target.value).toFixed(2)))}
                                readOnly={gameState === 1}
                            />
                        </div>
                        <div className="controllers">
                            <button onClick={() => setBetAmountInput(1)}>Min</button>
                        </div>
                    </div>
                    <div className="mine-count">
                        <input
                            className="animated-input"
                            type="number"
                            value={mineCountInput}
                            onChange={(e) => setMineCountInput(e.target.value ? Math.min(24, Math.max(1, Math.round(e.target.value))) : "")}
                            readOnly={gameState === 1}
                            required
                        />
                        <span className='label'>Mines</span>
                        <div className="button-container">
                            <button disabled={gameState === 1} onClick={() => setMineCountInput(Math.min(24, Math.max(1, mineCountInput - 1)))}>
                                { gameState === 1 
                                    ? <img src="images/minus-gray.svg" />
                                    : <img src="images/minus.svg" />
                                }
                            </button>
                            <button disabled={gameState === 1} onClick={() => setMineCountInput(Math.min(24, Math.max(1,mineCountInput + 1)))}>
                                { gameState === 1 
                                    ? <img src="images/plus-gray.svg" />
                                    : <img src="images/plus.svg" />
                                }
                            </button>
                        </div>
                    </div>
                    <div className="buttons">
                        <div>
                            <PopupState variant="popover">
                                {(popupState) => (
                                <>
                                    <div
                                        className={"info-box " + (popupState?.isOpen ? "active" : "")}
                                        variant="contained"
                                        {...bindTrigger(popupState)}
                                    >
                                        <img src={popupState?.isOpen ? "images/question-white.svg" : "images/question.svg"} />
                                    </div>
                                    <Popover
                                        {...bindPopover(popupState)}
                                        className="header-info-popover"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                backgroundColor:"#1E222C",
                                                minHeight: "40px",
                                                padding: "20px",
                                                border: "1px solid #2E323D",
                                                boxShadow: "0px 24px 48px rgba(19, 22, 29, 0.24)",
                                                display: "flex",
                                                flexDirection: "column",
                                                borderRadius: "0 16px 16px 16px",
                                                width: res_550 ? "calc(100% - 30px)" : "450px"
                                            }}
                                        >
                                            <span style={{
                                                color: "white",
                                                fontSize: "18px",
                                                fontWeight: "500",
                                                marginBottom: "10px"
                                            }}>What is Mines?</span>
                                            <span style={{
                                                color:"#7D8496",
                                                fontSize:"17px",
                                                marginBottom: "15px"
                                            }}>Uncover hidden treasures in our thrilling Mines game mode, where players stake ROBUX and navigate a grid filled with buried riches and explosive surprises. Strategically select tiles to reveal rewards while avoiding hidden mines. Do you have the skill and luck to unearth a fortune?</span>
                                        </Typography>
                                    </Popover>
                                </>
                                )}
                            </PopupState>
                        </div>
                        <div className={'button-box'}>
                            <button onClick={() => {
                                switch (gameState) {
                                    case 0:
                                        startGame();
                                        break;
                                    case 1:
                                        cashout();
                                        break;
                                    case 2:
                                        restart();
                                        break;
                                }
                            }}>
                                {gameState === 0 && "Place a bet"}
                                {gameState === 1 && "Cashout"}
                                {gameState === 2 && "Play Again"}
                            </button>
                        </div>
                    </div>
                    { gameState !== 0 &&
                        <div className="total-profit">
                            <div className='info'>
                                <div>
                                    <img src="images/robux-gray.svg" />
                                </div>
                                <div>
                                    <span>Total profit</span>
                                    <p>{totalProfit}</p>
                                </div>
                            </div>
                            <div className={'multiplier '+ (gameResult === 0 ? "lose" : (gameResult === 1 ? "win" : ""))}>
                                <span>x{currentMultiplier}</span>
                            </div>
                        </div>
                    }
                </div>

                <div className='game'>
                    {gameState === 0 && mines.map((mine, index) => {
                        return <div key={index} className='mine'><img src="images/robux-dark.svg" /></div>
                    })}
                    {gameState === 1 && mines.map((mine, index) => {
                        if (mine === 2) { //Area revealed and no mine found
                            return (
                                <div 
                                    className='mine no-mine'
                                    key={index}
                                >
                                    {/* <div className="wall"></div> */ }
                                    <img src="images/no-mine.svg" />
                                    <img src="images/light-4.png" className='light' />
                                    <img src="images/light-4.png" className='light' />
                                </div>
                            )
                        }
                        return (
                            <div 
                                className='mine game-started'
                                onClick={e => { if (!revealing) revealArea(index, e); }}
                                key={index}
                            >
                                <img src="images/robux-gray-2.svg" />
                                <span className="multiplier">{nextMultiplication}x</span>
                            </div>
                        )
                    })}
                    {gameState === 2 && mines.map((mine, index) => {
                        return (
                            <div 
                                className={'mine game-started ' + (clickedAreas.includes(index) ? "revealed" : "not-revealed")}
                                key={index}
                            >
                                { mine === 2 &&
                                    <div className='mine no-mine'>
                                        <img src="images/no-mine.svg" />
                                        <img src="images/light-4.png" className='light' />
                                        <img src="images/light-4.png" className='light' />
                                    </div>
                                }
                                { mine === 1 &&
                                    <div className="mine mine-found">
                                        <img src="images/bomb.png" />
                                        <img src="images/light-5.png" className='light' />
                                        <img src="images/light-5.png" className='light' />
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>

            </div>
            
            <hr style={{border: "1px solid #2E323D", margin: "0 24px"}} />
            
            <div className='bets'>
                <div className="bets-header">
                    <div className='title'>
                        <h2>All bets</h2>
                    </div>
                    <div className="currency-filter">
                        <div
                            onClick={() => {
                                if (currencyFilter === "points" || currencyFilter === "all") setCurrencyFilter("robux");
                                else setCurrencyFilter("all");
                            }}
                            className={(currencyFilter === "robux" || currencyFilter === "all") ? undefined : "inactive"}
                        >
                            <img src="images/robux.svg" />
                            <span>Robux</span>
                        </div>
                        <div
                            onClick={() => {
                                if (currencyFilter === "robux" || currencyFilter === "all") setCurrencyFilter("points");
                                else setCurrencyFilter("all");
                            }}
                            className={(currencyFilter === "points" || currencyFilter === "all") ? undefined : "inactive"}
                        >
                            <img src="images/points.png" className='points' />
                            <span>Points</span>
                        </div>
                    </div>
                </div>

                <div className="bets-body">
                    {betDatas.map((d, i) => {
                        if (currencyFilter === d?.type || currencyFilter === "all") {
                            if (!res_930) {
                                return (
                                    <div className='bet' key={i}>
                                        <div>
                                            <img className='pfp' src={d.pfp} />
                                            <span className='username'>{d.username}</span>
                                            {d.username === user?.username && <span className='me'>Me</span>}
                                        </div>
                                        <div className="multiplier">
                                            <div className={d.ratio > 0 ? "win" : "lose"}>x{Math.abs(d.ratio).toFixed(2)}</div>
                                        </div>
                                        <div className="mines">
                                            <img src="images/mines.svg" />
                                            <span>{d.mines} Mines</span>
                                        </div>
                                        <div>
                                            {d.win > 0 && <span className='addup-value'>+{(parseFloat(d.win) - parseFloat(d.bet)).toFixed(2)}</span>}
                                            {seperateBalance(parseFloat(d.win))}
                                            {d?.type === "robux" ? <img src="images/robux.svg" /> : <img src="images/points.png" className='points' />}
                                        </div>
                                    </div>
                                )
                            }

                            return (
                                <div className='mobile-bet' key={i}>
                                    <div className='user-info'>
                                        <div>
                                            <img className='pfp' src={d.pfp} />
                                        </div>
                                        <div>
                                            <div>
                                                <span className='username'>{d.username}</span>
                                                {d.username === user?.username && <span className='me'>Me</span>}
                                            </div>
                                            
                                            <div className='amount'>
                                                {d.win && <span className='addup-value'>+{(parseFloat(d.win) - parseFloat(d.bet)).toFixed(2)}</span>}
                                                {seperateBalance(parseFloat(d.win))}
                                                {d?.type === "robux" ? <img src="images/robux.svg" /> : <img src="images/points.png" className='points' />}
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{border: "1px solid #2E323D", width: "calc(100% - 60px)", float:"right", margin:"0 10px 20px 0"}} />
                                    <div className='bet-info'>
                                        <div className="mines">
                                            <img src="images/mines.svg" />
                                            <span>{d.mines} Mines</span>
                                        </div>
                                        <div className="multiplier">
                                            <div className={d.ratio > 0 ? "win" : "lose"}>x{Math.abs(d.ratio).toFixed(2)}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        </div>
    )
}