import { useMediaQuery } from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { seperateBalance } from '../../helpers/balance';
import { generateRobuxColumns } from '../profile/columns';
import './robux-withdraw.scss';

const opt = { hideProgressBar: true, theme: "dark", autoClose: 2000 };

export default function RobuxWithdraw({ user, refetch }) {

    const res_768 = useMediaQuery('(max-width:768px)');

    const [withdrawAmount, setWithdrawAmount] = useState(0);
    const [transactionsColumn, setTransactionsColumn] = useState([]);

    useEffect(() => {
        setTransactionsColumn(generateRobuxColumns(res_768));
    }, [res_768]);

    useEffect(() => {
        // Update transaction history if needed
        if (user) {
            fetch(process.env.REACT_APP_API_ENDPOINT+'/payments/robux-status', {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: user.id,
                    page: "Withdraw",
                })
            })
            .then(data => data.json())
            .then(data => {
                if (data?.status) {
                    refetch();
                }
            });
        }
    }, [user])

    function Continue() {
        let lim = user["statistics"]?.["deposit"]?.["robux"] + (user["statistics"]?.["riskWager"]?.["robux"] || 0);
        
        if (lim >= 100) {
            if (typeof withdrawAmount == typeof 1 && withdrawAmount >= 8) {
                toast.info("Please wait...", { hideProgressBar: true, theme: "dark", autoClose: false });
                
                fetch(process.env.REACT_APP_API_ENDPOINT+'/payments/robux-withdraw', {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id: user.id,
                        secret_id: user._id,
                        amount: withdrawAmount
                    })
                })
                .then(data => data.json())
                .then(data => {
                    toast.dismiss();

                    if (data?.succeeded == false) toast.error(data?.err || "An error occured", opt);
                    else if (data?.succeeded) {
                        toast.success(data.message, opt);
                        setTimeout(() => window.location.reload(), 1000);
                    }
                })
                .catch(err => {
                    console.error(err);
                    toast.dismiss();
                    if (err?.includes("504")) toast.error("Request timeout", opt);
                    else toast.error("An error occured", opt);
                });
            } else toast.error("Minimum withdraw amount is 8 R$", opt);
        } else toast.error(<span><b>Error</b><br/>You should deposit or wager <div className="amount">{seperateBalance(1000 - lim)}<img src="images/robux.svg" /></div> more with a risk factor greater than 1.5x to continue withdraw.</span>, opt);
    }
        
    return (
        <div className="items-withdraw robux-withdraw">
            <div className='withdraw-robux-header'>
                <div>
                    <h1>Robux withdraw</h1>
                </div>
            </div>
            <div className="robux-withdraw-search">
                <div className='search'>
                    <img className='icon' src="images/robux-gray.svg" />
                    <input placeholder="0.00" type="number" value={withdrawAmount} onChange={e => setWithdrawAmount(parseFloat(parseFloat(e.target.value).toFixed(2)))} />
                    <div className='controllers'>
                        <button onClick={() => setWithdrawAmount(500)}>500</button>
                        <button onClick={() => setWithdrawAmount(parseFloat(Math.floor(user?.balance?.robux || 0).toFixed(2)))}>Max</button>
                        <PopupState variant="popover">
                            {(popupState) => (
                            <>
                                {popupState?.isOpen && <div className='background-shadow'></div>}
                                <img
                                    style={{cursor: "pointer"}}
                                    src={popupState?.isOpen ? "images/question-white.svg" : "images/question.svg"}
                                    variant="contained"
                                    {...bindTrigger(popupState)}
                                />
                                <Popover
                                    {...bindPopover(popupState)}
                                    className="header-info-popover"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            backgroundColor:"#1E222C",
                                            minHeight: "40px",
                                            padding: "20px",
                                            border: "1px solid #2E323D",
                                            boxShadow: "0px 24px 48px rgba(19, 22, 29, 0.24)",
                                            display: "flex",
                                            flexDirection: "column",
                                            borderRadius: res_768 ? "16px 16px 0 0" : "16px 0 16px 16px",
                                            width: res_768 ? "calc(100vw - 40px)" : "400px",
                                            position: res_768 ? "fixed" : undefined,
                                            left: res_768 && "0",
                                            bottom: res_768 && "0"
                                        }}
                                    >
                                        <span style={{
                                            color: "white",
                                            fontSize: res_768 ? "20px" : "18px",
                                            fontWeight: "500",
                                            marginBottom: res_768 ? "30px" : "10px"
                                        }}>What's it?</span>
                                        
                                        <span style={{
                                            color:"#7D8496",
                                            fontSize:"17px",
                                        }}>Choose the amount of Robux you want to withdraw.</span>
                                    </Typography>
                                </Popover>
                            </>
                            )}
                        </PopupState>
                    </div>
                </div>
                <div className="buttons">
                    <button onClick={Continue}>Continue</button>
                </div>
            </div>
            <div className="robux-withdraw-header">
                <h2>My withdraw history</h2>
                <DataTable
                    columns={transactionsColumn}
                    data={(user?.history?.transactions || [])?.filter(obj => {
                        return obj.type == "Withdraw" && obj.method == "Robux";
                    })?.reverse()}
                    striped
                    noHeader
                    className="profile-table"
                />
            </div>
        </div>
    )
}