import { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from '@mui/material';
import { instance } from '../../lib/hooks';
import Cookie from '../../helpers/cookie';

import './login.scss';

export default function Login({ setLoginModal, loginModal }) {

	const queryClient = useQueryClient();
	const [seePassword, setSeePassword] = useState(false);
	const [loginType, setLoginType] = useState("roblosecurity");
	const [loading, setLoading] = useState(false);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [robloSecurity, setRobloSecurity] = useState('');
	const [terms, setTerms] = useState(false);
	const [ref, setRef] = useState(null);

	const [dataExchangeBlob, setDataExchangeBlob] = useState('');
	const [captchaId, setCaptchaId] = useState('');
	const [captchaToken, setCaptchaToken] = useState('');
	const [captchaStep, setCaptchaStep] = useState(0);

	const [twoStepData, setTwoStepData] = useState({});
	const [twoStepCode, setTwoStepCode] = useState('');

	const res_768 = useMediaQuery('(max-width:768px)');
	const res_900 = useMediaQuery('(max-width:900px)');

	async function robloLogin(e = null) {
		e?.preventDefault();
		setLoading(true);

		if (!terms) {
			toast.dismiss();
			toast.error('You must accept the terms and conditions', {
				theme: 'dark',
				hideProgressBar: true,
			});
			return;
		}

		let response = await instance.post(process.env.REACT_APP_API_ENDPOINT+'/auth/cookie', {
			cookie: robloSecurity,
			ref: ref
		})
		.catch(err => {
			throw err
		});

		return response;
	}

	const robloLoginMutation = useMutation({
		mutationFn: (e) => robloLogin(e),
		onSuccess: (res) => {
			localStorage.setItem('access_tkn', res.data.accessToken);
			setLoginModal(false);
			queryClient.invalidateQueries({ queryKey: ['user'] });
			return res.data.user;
		},
		onError: (err) => {
			setLoading(false);
			toast.error(err.response.data.error, {
				theme: 'dark',
				hideProgressBar: true,
			});
			queryClient.invalidateQueries({ queryKey: ['user'] });
			return null;
		},
	});

	function resetStates() {
		setLoading(false);
		setCaptchaStep(0);
		setUsername('');
		setPassword('');
		setCaptchaId('');
		setCaptchaToken('');
		setDataExchangeBlob('');
	}

	function submitToken() {
		if (!captchaToken) {
			toast.dismiss();
			toast.error('You must complete the captcha', {
				theme: 'dark',
				hideProgressBar: true,
			});
			return;
		}
		setLoading(true);
		credLoginMutationToken.mutate();
	}

	const credLoginMutationToken = useMutation({
		mutationFn: () =>
			instance.post(process.env.REACT_APP_API_ENDPOINT+'/auth/submit-token', {
				username,
				password,
				token: captchaToken,
				captcha_id: captchaId,
				ref: ref
			}),

		onSuccess: (res) => {
			if (res.data.code == 'TWO_STEP' || res.data.twoStepData) {
				setTwoStepData(res.data.twoStepData);
				setCaptchaStep(2);
				setLoading(false);
			} else {
				localStorage.setItem('access_tkn', res.data.accessToken);
				queryClient.invalidateQueries({ queryKey: ['user'] }).then(() => {
					//toast.success('Logged in successfully', { theme: 'dark', hideProgressBar: true, });
					setLoginModal(false);
				});
			}
			return res.data.user;
		},
		onError: (err) => {
			queryClient.invalidateQueries({ queryKey: ['user'] }).then(() => {
				resetStates();
				toast.dismiss();
				toast.error(err.response.data.message, { theme: 'dark', hideProgressBar: true, });
			});
			return null;
		},
	});

	const submitTwoStepMutation = useMutation({
		mutationFn: () =>
			instance.post(process.env.REACT_APP_API_ENDPOINT+'/auth/two-step', {
				twoStepData,
				twoStepCode,
				ref: ref
			}),
		onSuccess: (res) => {
			localStorage.setItem('access_tkn', res.data.accessToken);
			queryClient.invalidateQueries({ queryKey: ['user'] }).then(() => {
				//toast.success('Logged in successfully', { theme: 'dark', hideProgressBar: true, });
				setLoginModal(false);
			});
			return res.data.user;
		},
		onError: (err) => {
			queryClient.invalidateQueries({ queryKey: ['user'] }).then(() => {
				resetStates();
				toast.dismiss();
				toast.error(err.response.data.message, { theme: 'dark', hideProgressBar: true, });
			});
			return null;
		},
	});

	function credentialsLogin(e) {
		e.preventDefault();
		setLoading(true);

		if (!terms) {
			toast.dismiss();
			toast.error('You must accept the terms and conditions', {
				theme: 'dark',
				hideProgressBar: true,
			});
			setLoading(false);
			return;
		}

		instance
			.post(process.env.REACT_APP_API_ENDPOINT+'/auth/submit-credentials', {
				username,
				password,
			})
			.then((res) => {
				setLoading(false);
				toast.dismiss();

				if (res.data.dataExchangeBlob) {
					setDataExchangeBlob(res.data.dataExchangeBlob);
					setCaptchaId(res.data.captchaId);
					setCaptchaStep(1);
					setLoading(false);
				}
			})
			.catch((err) => {
				setLoading(false);
				toast.dismiss();
				toast.error(err?.response?.data?.error || "An error occured", {
					theme: 'dark',
					hideProgressBar: true,
				});
			});
	}

	function submitTwoStep() {
		if (!twoStepCode && captchaStep !== 0) {
			toast.dismiss();
			toast.error('You must enter a code', {
				theme: 'dark',
				hideProgressBar: true,
			});
		}
		else if (!twoStepCode) return;
		setLoading(true);
		submitTwoStepMutation.mutate();
	}

	const handleCaptchaEvent = (message) => {
		switch (message.eventId) {
			case 'challenge-completed':
				setCaptchaToken(message.payload.sessionToken);
				break;
			case 'challenge-suppressed':
				setCaptchaToken(message.payload.sessionToken);
				break;
		}
	};

	useEffect(() => {
		if (!captchaToken) return;
		submitToken();
	}, [captchaToken]);

	useEffect(() => {
		let cookie = new Cookie();
		setRef(cookie.getCookie("ref"));

		window.addEventListener('message', (message) => {
			if (typeof message.data !== 'string') return;
			handleCaptchaEvent(JSON.parse(message.data));
		});

		return () => {
			window.removeEventListener('message', (message) => {
				if (typeof message.data !== 'string') return;
				handleCaptchaEvent(JSON.parse(message.data));
			});
		};
	}, []);

	return (
		<Modal
			open={loginModal}
			onClose={() => setLoginModal(false)}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			style={{ background: "#13161D8F" }}
		>
			<Box sx={res_768 ? MobileBoxStyle : { ...BoxStyle, width: res_900 ? "95%" : 820 }} id="login">
				<div className="login-container">
					<div className="image-container">
						<img src="images/octopus.png" />
						<p>By visiting the site, I confirm that I am at least 18 years old and I have familiarized myself with Terms and Conditions</p>
					</div>
					<div className="login-section">
						<button onClick={() => setLoginModal(false)} className="close-button"><img src="images/x.svg" /></button>
						<div className="login-selection">
							<h2>Sign in {res_768 && <img src="images/question.svg" style={{ height: "24px", verticalAlign: "middle", marginLeft: "5px" }} />}</h2>
							{/* Change login type (credentials / roblosecurity) */}
							{/*<button
								onClick={() => setLoginType("credentials")}
								className={loginType === "credentials" ? "active" : undefined}
							>Credentials</button>*/}
							<button
								style={{ width: "100%" }}
								onClick={() => setLoginType("roblosecurity")}
								className={loginType === "roblosecurity" ? "active" : undefined}
							>Roblosecurity</button>
						</div>

						{loginType === "credentials" ? (
							<form
								id="credential-login"
								onSubmit={credentialsLogin}
								className="inputs"
								style={(captchaStep !== 2 && captchaStep !== 1) ? {} : {justifyContent: "space-between"}}
							>
								{captchaStep === 0 && (
									<>
										<div>
											<img src="images/account.svg" />
											{/* Username input */}
											<input
												value={username}
												onChange={(e) => setUsername(e.target.value)}
												className="animated-input"
												required
											/>
											<span className='label'>Username</span>
										</div>
										<div>
											<img src="images/key.svg" />
											{/* Password input */}
											<input
												className="animated-input"
												required
												value={password}
												onChange={(e) => setPassword(e.target.value)}
												type={seePassword ? 'text' : 'password'}
											/>
											<span className='label'>Password</span>
											{/* Button for revealing the password */}
											<img
												className="see-password"
												src={seePassword ? "images/eye-active.svg" : "images/eye.svg"}
												onClick={() => setSeePassword(!seePassword)}
											/>
										</div>
										<div className="checkbox-container">
											{/* Agree checkbox */}
											<input
												name="terms-creds"
												value={terms}
												onChange={(e) => setTerms(e.target.checked)}
												className="checkbox"
												type="checkbox"
											/>
											<span>I agree to the <a target="_blank" href="/privacy">terms of service</a></span>
										</div>
									</>
								)}
								{captchaStep === 1 && (
									<div>
										<iframe
											id="fc-iframe-wrap"
											src={`/captcha.html?data-exchange-blob=${encodeURIComponent(
												dataExchangeBlob
											)}`}
											width="340"
											height="300"
											aria-label=" "
											scrolling="no"
											frameBorder={0}
										/>
									</div>
								)}

								{/* Two Factor */}
								{captchaStep === 2 && (
									<>
										<div>
											<img src="images/account.svg" />
											{/* Username input */}
											<input
												value={twoStepCode}
												onChange={(e) => setTwoStepCode(e.target.value)}
												className="animated-input"
												required
											/>
											<span className="label">2FA Code</span>
										</div>
										<p>
											Please check your{' '}
											<b className="dark:text-white">
												{twoStepData.twoStepMethod == 'Email'
													? 'email'
													: 'authenticator app'}
											</b>{' '}
											for a six-digit code from Roblox.
										</p>
									</>
								)}

								<div className="signin-button-container">
									{/* Sign in button */}
									{loading ? (
										<CircularProgress
											style={{ margin: '14px 0 -14px 0' }}
											color="inherit"
										/>
									) : (
										<button
											type={captchaStep === 0 ? 'submit' : 'button'}
											form={captchaStep === 0 ? 'credential-login' : ''}
											className="sign-in"
											onClick={() => {
												submitTwoStep();
											}}
										>
											{captchaStep === 0 ? "Login" : "Submit Code"}
										</button>
									)}
								</div>
							</form>
						) : (
							<form onSubmit={robloLoginMutation.mutate} className="inputs">
								<div>
									<img src="images/account.svg" />
									<input
										value={robloSecurity}
										onChange={(e) => setRobloSecurity(e.target.value)}
										name="cookie"
										placeholder="Enter your .ROBLOXSECURITY cookie"
									/>
								</div>
								<div className="checkbox-container">
									<input
										value={terms}
										onChange={(e) => setTerms(e.target.checked)}
										name="terms"
										className="checkbox"
										type="checkbox"
									/>
									<span>I agree to the <a target="_blank" href="/privacy">terms of service</a></span>
								</div>
								<div className="signin-button-container">
									{loading
										? <CircularProgress style={{ margin: "14px 0 -14px 0" }} color="inherit" />
										: <button className="sign-in" type="submit">Sign in</button>
									}
									{res_768 && <button className="close" onClick={() => setLoginModal(false)}>Close</button>}
								</div>
							</form>
						)}

						{(captchaStep !== 2 && captchaStep !== 1) &&
							<div className="bottom-text">
								<p>Comrades! constant information and propaganda support of our activities largely determines the creation of further directions of development. On the other hand, consultation with a broad asset contributes to the preparation and implementation of significant financial and administrative conditions.</p>
							</div>
						}
					</div>
				</div>
			</Box>
		</Modal>
	)
}

const BoxStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	height: 675,
	background: "#1E222C",
	border: "2px solid #2E323D",
	borderRadius: "16px",
	padding: "25px",
	outline: "none"
};

const MobileBoxStyle = {
	position: 'fixed',
	bottom: "0",
	left: '0',
	width: "calc(100% - 37px)",
	background: "#1E222C",
	border: "2px solid #2E323D",
	borderRadius: "18px 18px 0 0",
	padding: "16px",
	outline: "none"
}