import { useMediaQuery } from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { generateRobuxColumns } from '../profile/columns';
import './robux-deposit.scss';

const opt = {
    hideProgressBar: true,
    theme: "dark",
    autoClose: 2000
}

export default function RobuxDeposit({ user, refetch }) {

    const res_768 = useMediaQuery('(max-width:768px)');

    const [depositAmount, setDepositAmount] = useState(0);
    const [maxDeposit, setMaxDeposit] = useState(0);
    const [transactionsColumn, setTransactionsColumn] = useState([]);

    useEffect(() => {
        setTransactionsColumn(generateRobuxColumns(res_768));
    }, [res_768]);

    useEffect(() => {
        if (user) {
            (async() => {
                // Update transaction history if needed
                fetch(process.env.REACT_APP_API_ENDPOINT+'/payments/robux-status', {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id: user.id,
                        page: "Deposit",
                    })
                })
                .then(data => data.json())
                .then(data => {
                    if (data?.status) refetch();
                });

                // Get Roblox account balance
                fetch(process.env.REACT_APP_API_ENDPOINT+'/auth/get-robux-amount', {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id: user.id,
                        secret_id: user._id
                    })
                })
                .then(data => data.json())
                .then(data => {
                    if (typeof data?.robux === "number") {
                        setMaxDeposit(Math.floor(data.robux));
                    } else if (data?.err) {
                        setMaxDeposit(1000);
                    }
                })
            })()
        }
    }, [user]);

    function doDeposit() {
        // Max deposit amount has been exceeded (1000 Robux is default)
        if (maxDeposit !== 1000 && depositAmount > maxDeposit) {
            toast.error("You don't have enough Robux in your Roblox account.", opt);
        }
        // Everything is fine
        else if (typeof depositAmount == typeof 1 && depositAmount >= 10) {
            toast.info("Please wait...", {
                hideProgressBar: true, theme: "dark", autoClose: false
            });
            fetch(process.env.REACT_APP_API_ENDPOINT+'/payments/robux-deposit', {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: user.id,
                    secret_id: user._id,
                    amount: depositAmount
                })
            })
            .then(data => data.json())
            .then(data => {
                toast.dismiss();
                if (data?.succeeded == false) toast.error(data?.err || "An error occured", opt);
                else if (data?.succeeded) {
                    toast.success(data.message, opt);
                    setTimeout(() => window.location.reload(), 1000);
                }
            })
            .catch(err => {
                console.error(err);
                toast.dismiss();
                if (err?.includes("504")) toast.error("Request timeout", opt);
                else toast.error("An error occured", opt);
            });
        }
        // Deposit amount invalid
        else toast.error("Minimum deposit amount is 10 R$.", opt);
    }

    return (
        <div className="items-deposit robux-deposit">
            <div className='deposit-robux-header'>
                <div>
                    <h1>Robux deposit</h1>
                </div>
            </div>
            <div className="robux-deposit-search">
                <div className='search'>
                    <img className='icon' src="images/robux-gray.svg" />
                    <input placeholder="0.00" type="number" value={depositAmount} onChange={e => setDepositAmount(parseFloat(e.target.value))} />
                    <div className='controllers'>
                        <button onClick={() => setDepositAmount(500)}>500</button>
                        <button onClick={() => setDepositAmount(maxDeposit)}>Max</button>
                        <PopupState variant="popover">
                            {(popupState) => (
                            <>
                                {popupState?.isOpen && <div className='background-shadow'></div>}
                                <img
                                    style={{cursor: "pointer"}}
                                    src={popupState?.isOpen ? "images/question-white.svg" : "images/question.svg"}
                                    variant="contained"
                                    {...bindTrigger(popupState)}
                                />
                                <Popover
                                    {...bindPopover(popupState)}
                                    className="header-info-popover"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            backgroundColor:"#1E222C",
                                            minHeight: "40px",
                                            padding: "20px",
                                            border: "1px solid #2E323D",
                                            boxShadow: "0px 24px 48px rgba(19, 22, 29, 0.24)",
                                            display: "flex",
                                            flexDirection: "column",
                                            borderRadius: res_768 ? "16px 16px 0 0" : "16px 0 16px 16px",
                                            width: res_768 ? "calc(100vw - 40px)" : "400px",
                                            position: res_768 ? "fixed" : undefined,
                                            left: res_768 && "0",
                                            bottom: res_768 && "0"
                                        }}
                                    >
                                        <span style={{
                                            color: "white",
                                            fontSize: res_768 ? "20px" : "18px",
                                            fontWeight: "500",
                                            marginBottom: res_768 ? "30px" : "10px"
                                        }}>What's it?</span>
                                        
                                        <span style={{
                                            color:"#7D8496",
                                            fontSize:"17px",
                                        }}>Choose the amount of Robux you want to deposit.</span>
                                    </Typography>
                                </Popover>
                            </>
                            )}
                        </PopupState>
                    </div>
                </div>
                <div className="buttons">
                    <button onClick={doDeposit}>Continue</button>
                </div>
            </div>
            <div className="robux-deposit-header">
                <h2>My deposit history</h2>
                <DataTable
                    columns={transactionsColumn}
                    data={(user?.history?.transactions || [])?.filter(obj => {
                        return obj.type == "Deposit" && obj.method == "Robux"
                    })?.reverse()}
                    striped
                    noHeader
                    className="profile-table"
                />
            </div>
        </div>
    )
}