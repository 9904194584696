import { useState } from 'react';
import { toast } from 'react-toastify';
import Cookie from '../../helpers/cookie';
import './authorize.scss';

const cookie = new Cookie();

export default function Authorize({ setDeveloper }) {

    const [password, setPassword] = useState("");
    const [seePassword, setSeePassword] = useState(false);

    function login() {
        if (password) {
            fetch(process.env.REACT_APP_API_ENDPOINT + "/auth/login-developer", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    password: password,
                })
            })
            .then(data => data.json())
            .then(data => {
                if (data.err) {
                    toast.error(<span><b>Not Authorized</b><br/>Wrong password</span>, {
                        hideProgressBar: true,
                        theme: "dark",
                        autoClose: 2000
                    });
                } else {
                    cookie.setCookie("dev-key", data.key, 7);
                    setDeveloper(true);
                }
            });
        }
    }

    return (
        <div id="authorize">
            <h1>We're under <br /><span>maintenance</span>!</h1>
            <p>If you're an authorized user, <br /> please be our guest:</p>
            <div className='login-container'>
                <img src="images/key.svg" />
                <input 
                    type={seePassword ? 'text' : 'password'}
                    placeholder="Password"
                    onChange={e => setPassword(e.target.value)}
                />
                <img
                    className="see-password"
                    src={seePassword ? "images/eye-active.svg" : "images/eye.svg"}
                    onClick={() => setSeePassword(!seePassword)}
                />
                <button onClick={login}>Submit</button>
            </div>
        </div>
    )
}