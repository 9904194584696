import { useState } from 'react';
import { Link } from 'react-router-dom';
import Item from '../../components/item';
import '../../assets/css/items.scss';

export default function ItemsDeposit({ chatVisible, country }) {

    const [items, setItems] = useState([
        {
            image: "images/example-item.png",
            name: "Ginger Cat",
            price: 2380,
            rarity: "red"
        },
        {
            image: "images/example-item.png",
            name: "Ginger Cat",
            price: 2380,
            rarity: "purple"
        },
        {
            image: "images/example-item.png",
            name: "Ginger Cat",
            price: 2380,
            rarity: "green"
        },
        {
            image: "images/example-item.png",
            name: "Ginger Cat",
            price: 2380,
            rarity: "red"
        },
        {
            image: "images/example-item.png",
            name: "Ginger Cat",
            price: 2380,
            rarity: "green"
        },
        {
            image: "images/example-item.png",
            name: "Ginger Cat",
            price: 2380,
            rarity: "red"
        },
        {
            image: "images/example-item.png",
            name: "Ginger Cat",
            price: 2380,
            rarity: "purple"
        },
    ]);

    const [selectedItems, setSelected] = useState([]);
    const [searching, setSearching] = useState(false);
        
    return (
        <div className="items-container">
            <div className='header'>
                <div>
                    <h1>Items deposit</h1>
                    <div>
                        {!searching && <span className='tag'>Tower of Hell</span>}
                        <img src={"images/flags/" + country?.toLowerCase() + ".svg"} />
                    </div>
                </div>
                <Link to="/faq"><img src="images/question-active.svg" />How it works</Link>
            </div>
            {!searching &&
            <>
                <div className="search-container">
                    <div className='search'>
                        <img className='icon' src="images/search.svg" />
                        <input placeholder="Search for items..." />
                    </div>
                    <div>
                        <div className="sort">
                            <span>High to low</span>
                            <img src="images/down-white.svg" />
                        </div>
                        <div className='reload' onClick={() => setSelected([])}>
                            <img src="images/reload-white.svg" />
                        </div>
                    </div>
                </div>
                <div className="items-header">
                    <h2>My inventory</h2>
                    <span>24 items</span>
                </div>
                <div className='items'>
                    {items.map((item, index) => {
                        return (
                            <Item 
                                image={item.image} 
                                name={item.name} 
                                price={item.price} 
                                rarity={item.rarity} 
                                setSelected={setSelected}
                                selectedItems={selectedItems}
                                key={index}
                                index={index}
                            />
                        )
                    })}
                </div>
            </>
            }
            {searching &&
                <div className='searching'>
                    <h3>Searching for a bot</h3>
                    <p className='warning'>Don't leave the page</p>
                    <p className='counter'>00:18</p>
                    <button onClick={() => setSearching(false)}>Go back</button>
                </div>
            }
            {(selectedItems.length > 0 && !searching) &&
                <div className="items-footer" style={{width: chatVisible ? "calc(100% - 651px)" : "calc(100% - 200px)"}}>
                    <div className="receive-amount">
                        You will receive <div className="price">
                            <span>2 380<span className='float'>.00</span></span>
                            <img src="images/points.png" className="points" />
                        </div>
                    </div>
                    <div className="buttons">
                        <button onClick={() => setSelected([])}>Clear</button>
                        <button className='active' onClick={() => setSearching(true)}>Continue</button>
                    </div>
                </div>
            }
        </div>
    )
}