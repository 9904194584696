import Cookie from '../../helpers/cookie';

const cookie = new Cookie();

export default function Banner({ chatVisible, setBanner }) {
    return (
        <div className={"banner " + (chatVisible ? "" : "no-nav")}>
            <h1>Free skins<br />for subscribe!</h1>
            <div className='counter'>
                <div>
                    <span className='first'>01</span>
                    <span>Day</span>
                </div>
                <div>
                    <span className='first'>04</span>
                    <span>Hours</span>
                </div>
                <div>
                    <span className='first'>36</span>
                    <span>Min</span>
                </div>
            </div>
            <img className="gift-images" src="images/gifts.png" />
            {/* Button for closing the banner */}
            <button 
                onClick={() => {
                    setBanner(false);
                    cookie.setCookie("no-banner", true, 1);
                }} 
                className="close-banner"
            >
                <img src="images/x-white.svg" />
            </button>
            {/* More info button */}
            <div className="more-info">
                <div>
                    <span className="first">Don't miss</span>
                    <span>More info</span>
                </div>
                <a href="#">
                    <div>
                        <img src="images/right-white.svg" />
                    </div>
                </a>
            </div>
        </div>
    )
}