import { useEffect, useState } from "react";
import $ from 'jquery';
import {
    AreaChart,
    Area,
    ResponsiveContainer,
    YAxis
} from "recharts";

export default function Chart({ multiplier, time, mobile, gameOver }) {

    const [data, setData] = useState([]);
    const [width, setWidth] = useState(Math.min(100, (mobile ? 25 : 5) + (time / 5000) * 10));
    const [rotate, setRotate] = useState(0);

    function createMultiplierData() {
        let arr = [];
        for (let i = 1; i < Math.min(3.3, multiplier); i += 0.01) {
            arr.push({
                multiplier: i ** (i * 1.2)
            });
        }
        setData(arr);
    }

    useEffect(createMultiplierData, []);
    useEffect(() => {

        if (data.length > 0 && multiplier <= 3.3) {
            createMultiplierData();
        }

        const element = document.querySelector('.recharts-area');
        const parent = document.querySelector('.chart-content');

        //Octopus position
        if (element && parent && time > 1000) {
            const rect = element.getBoundingClientRect();
            const parentRect = parent.getBoundingClientRect();

            const x = rect.left - parentRect.left;
            const y = rect.top - parentRect.top;

            if (!$(".chart-submarine").length) {
                $(".chart-content").append("<img src='images/submarine-dynamic.gif' class='chart-submarine' />");
            }

            $(".chart-submarine").css({
                position: "absolute",
                transition: "200ms",
                height: mobile ? "140px" : "200px",
                zIndex: 2,
                top: mobile ? y - ($(".chart-submarine").height() / 2) - (rotate / 3) - 20 : y - ($(".chart-submarine").height() / 2) - ((rotate * 1.3) + 40),
                right: mobile ? x - $(".chart-submarine").width() / 3 - 30 : x - $(".chart-submarine").width() / 3 - 15,
                transform: "rotateZ(" + rotate + "deg)"
            });
        }

        if (gameOver) {
            $(".chart-submarine").hide();
            $(".chart-submarine").attr("src", "images/boom.svg");
            $(".chart-submarine").addClass("game-over");
            $(".game-over").css({
                "top": parseFloat($(".chart-submarine")?.css("top")?.slice(0, -2) || 0) - 90,
                "right": parseFloat($(".chart-submarine")?.css("right")?.slice(0, -2) || 0) - 90,
                "height": mobile ? "300px" : "450px",
                "width": mobile ? "300px" : "450px",
            });
            setTimeout(() => $(".game-over").show(), 50);
        }

    }, [time, gameOver]);

    useEffect(() => {
        if (time / 5000 > 12) setWidth(100);
        else {
            let widthInterval = setInterval(() => {
                //Increase chart width by the time
                if (width <= 100 && !gameOver) setWidth(width + 0.16);
            }, 1);
            return () => clearInterval(widthInterval);
        }
    }, [width]);

    useEffect(() => {
        let rotateInterval = setInterval(() => {
            if (multiplier >= 1.2 && rotate > -45 && !gameOver) {
                setRotate((1.2 - Math.min(3.3, multiplier)) * 15.65);
            }
        }, 1);
        return () => clearInterval(rotateInterval);
    }, [multiplier]);

    return (
        <div
            className="chart-content"
            style={{
                height: Math.min(((multiplier - .7) ** 1.4), 3) * 31 + "%",
                width: width + "%",
                position: "relative"
            }}
        >
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                    width="100%"
                    height="100%"
                    data={data}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >

                    {/* Y Axis is invisible, made invisible with CSS (.yAxis) */}
                    <YAxis
                        dataKey="multiplier"
                        axisLine={false}
                        stroke="#1E222C"
                        domain={[1, "dataMax"]}
                    />

                    <Area
                        type="linear"
                        dataKey="multiplier"
                        stroke="#2456D6"
                        strokeWidth={3}
                        fillOpacity={1}
                        fill="url(#total)"
                    />

                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}