import { seperateBalance } from "../../helpers/balance"

export function generateGamesColumns(mobile) { return (
!mobile ? [
    //Desktop Columns
    {
        name: 'Game',
        selector: row => row.game,
        cell: (d) => {
            return (
                <div className="game-container">
                    <img src={"images/"+d.game?.toLowerCase()+".svg"} />
                    <span>{d.game}</span>
                </div>
            )
        }
    },
    {
        name: 'Bet',
        sortable: true,
        selector: row => row.bet,
        cell: (d) => {
            return (
                <div className="currency-container">
                    {d.bet?.type === "robux" ? <img src="images/robux.svg" /> : <img className="points" src="images/points.png" />}
                    {seperateBalance(d.bet?.amount)}
                </div>
            )
        }
    },
    {
        name: 'Ratio',
        sortable: true,
        selector: row => row.ratio,
        cell: (d) => {
            return (
                <div className={"ratio-container " + (d.earn > 0 ? "win" : "lose")}>
                    <span>x{Math.abs(d.ratio).toFixed(2)}</span>
                </div>
            )
        }
    },
    {
        name: 'Earn',
        sortable: true,
        selector: row => row.earn,
        cell: (d) => {
            return (
                <>
                    {parseFloat(d.earn) > 0 ?
                        <div className="currency-container">
                            {d.bet?.type === "robux" ? <img src="images/robux.svg" /> : <img className="points" src="images/points.png" />}
                            {seperateBalance(d.earn)}
                        </div>
                    : <div style={{color: "rgb(125, 132, 150)"}}>-</div>}
                </>
            )
        }
    },
] : [
    //Mobile columns
    {
        name: 'Game',
        selector: row => row.game,
        cell: (d) => {
            return (
                <div className="mobile-row">
                    <div className="mobile-row-data">
                        <div>
                            <img className="game-icon" src={"images/"+d.game?.toLowerCase()+".svg"} />
                            <span>{d.game}</span>
                        </div>
                        <div>
                            <div className={"ratio-container " + (d.earn > 0 ? "win" : "lose")}>
                                <span>x{Math.abs(d.ratio).toFixed(2)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="mobile-row-data">
                        <span className="gray">Bet</span>
                        <div className="currency-container">
                            {d.bet?.type === "robux" ? <img src="images/robux.svg" /> : <img className="points" src="images/points.png" />}
                            {seperateBalance(d.bet?.amount)}
                        </div>
                    </div>
                    <div className="mobile-row-data">
                        <span className="gray">Earn</span>
                        {parseFloat(d.earn) > 0 ?
                            <div className="currency-container">
                                {d.bet?.type === "robux" ? <img src="images/robux.svg" /> : <img className="points" src="images/points.png" />}
                                {seperateBalance(d.earn)}
                            </div>
                        : <span className="gray">-</span>}
                    </div>
                </div>
            )
        }
    }
]
)};

export function generateRobuxColumns(mobile) { return (
!mobile ? [
    //Desktop columns
    {
        name: 'Method',
        width: "20%",
        selector: row => row.method,
        cell: (d) => {
            return (
                <div className="method-container">
                    <span>{d.method}</span>
                </div>
            )
        }
    },
    {
        name: 'Date',
        width: "20%",
        sortable: true,
        selector: row => row.date,
        cell: (d) => {
            return (
                <div className="date">{d.date}</div>
            )
        }
    },
    {
        name: 'Quantity',
        width: "25%",
        cell: (d) => {
            let quantityAmount = d?.quantity?.amount;
            let quantityTotal = d?.quantity?.total;
            return (
                <div className="transaction-currency currency-container">
                    { d.quantity?.type === "robux" ?
                    <>
                        <img src="images/robux.svg" />
                        {seperateBalance(quantityAmount)}&nbsp;/&nbsp;{seperateBalance(quantityTotal)}
                    </>
                    :
                    <>
                        <img src="images/points.png" className="points" />
                        {seperateBalance(quantityAmount)}&nbsp;/&nbsp;{seperateBalance(quantityTotal)}
                    </>
                    }
                </div>
            )
        }
    },
    {
        name: 'Queue',
        width: "15%",
        selector: row => row.queue,
        cell: (d) => {
            return (
                <div className="queue-container">
                    { d.queue ? <span>#{d.queue}</span> : <span>-</span> }
                </div>
            )
        }
    },
    {
        name: 'Status',
        width: "20%",
        cell: (d) => {
            return (
                <div className={'status ' + d.status?.toLowerCase()}>
                    {d.status}
                </div>
            )
        }
    }
] : [
    //Mobile columns
    {
        name: 'Game',
        selector: row => row.type,
        cell: (d) => {
            let quantityAmount = d?.quantity?.amount;
            let quantityTotal = d?.quantity?.total;
            return (
                <div className="mobile-row">
                    <div className="mobile-row-data">
                        <div>
                            <img className="type-icon" src={"images/" + (d.type === "Deposit" ? "plus-gray" : "minus-gray") + ".svg"} />
                            <span>{d.type}</span>
                        </div>
                        <div>
                            <div className={'status ' + d.status?.toLowerCase()}>
                                {d.status}
                            </div>
                        </div>
                    </div>
                    <div style={{border: "none", width: "100%", marginBottom: "15px"}}>
                        <div className="date">{d.date}</div>
                    </div>
                    <div className="mobile-row-data">
                        <span className="gray">Method</span>
                        <div className="method-container">
                            <span>{d.method}</span>
                        </div>
                    </div>
                    <div className="mobile-row-data">
                        <span className="gray">Quantity</span>
                        {parseFloat(d?.quantity?.amount) > 0 ?
                            <div className="currency-container">
                                <img src={d?.quantity?.type === "robux" ? "images/robux.svg" : "images/points.png"} className={d?.quantity?.type === "points" ? "points" : undefined}/>
                                <span>{seperateBalance(quantityAmount)}&nbsp;/&nbsp;{seperateBalance(quantityTotal)}</span>
                            </div>
                        : <span className="gray">-</span>}
                    </div>
                </div>
            )
        }
    }
]
)};

export function generateTransactionsColumns(mobile) { return (
    !mobile ? [
        //Desktop columns
        {
            name: 'Method',
            width: "20%",
            selector: row => row.method,
            cell: (d) => {
                return (
                    <div className={"method-container " + (d?.id ? "contains-id" : "")}>
                        <span>{d.method}</span>
                        { d?.id && <span className="id">{d.id}</span> }
                    </div>
                )
            }
        },
        {
            name: 'Type',
            width: "20%",
            selector: row => row.type,
            cell: (d) => {
                return (
                    <div className="type">
                        <span>{d.type == "Deposit" ? <img src="/images/plus-gray.svg" /> : <img src="/images/minus-gray.svg" />} {d.type}</span>
                    </div>
                )
            }
        },
        {
            name: 'Date',
            width: "20%",
            sortable: true,
            selector: row => row.date,
            cell: (d) => {
                return (
                    <div className="date">{d.date}</div>
                )
            }
        },
        {
            name: 'Quantity',
            width: "25%",
            cell: (d) => {
                let quantityAmount = d?.quantity?.amount;
                return (
                    <div className="quantity">
                        <img src="/images/robux.svg" /> {seperateBalance(quantityAmount)}
                    </div>
                )
            }
        },
        {
            name: 'Status',
            width: "20%",
            cell: (d) => {
                return (
                    <div className={'status ' + d.status?.toLowerCase()}>
                        {d.status}
                    </div>
                )
            }
        }
    ] : [
        //Mobile columns
        {
            name: 'Game',
            selector: row => row.type,
            cell: (d) => {
                return (
                    <div className="mobile-row">
                        <div className="mobile-row-data">
                            <div>
                                <img className="type-icon" src={"images/" + (d.type === "Deposit" ? "plus-gray" : "minus-gray") + ".svg"} />
                                <span>{d.type}</span>
                            </div>
                            <div>
                                <div className={'status ' + d.status?.toLowerCase()}>
                                    {d.status}
                                </div>
                            </div>
                        </div>
                        <div style={{border: "none", width: "100%", marginBottom: "15px"}}>
                            <div className="date">{d.date}</div>
                        </div>
                        <div className="mobile-row-data">
                            <span className="gray">Method</span>
                            <div className="method-container">
                                <span>{d.method}</span>
                            </div>
                        </div>
                        <div className="mobile-row-data">
                            <span className="gray">Quantity</span>
                            {parseFloat(d?.quantity?.amount) > 0 ?
                                <div className="currency-container">
                                    <img src={d?.quantity?.type === "robux" ? "images/robux.svg" : "images/points.png"} className={d?.quantity?.type === "points" ? "points" : undefined}/>
                                    <span>{d.quantity?.amount?.toString()?.split(".")[0]}<span className="float">.{d.quantity?.amount?.toString()?.split(".")[1]}</span></span>
                                </div>
                            : <span className="gray">-</span>}
                        </div>
                    </div>
                )
            }
        }
    ]
    )};