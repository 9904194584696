export function seperateBalance(bal) {
    try {
        if (!bal) bal = 0;
        if (typeof bal != typeof 1.01) bal = parseFloat(bal);
        
        let arr = bal.toFixed(2).toString().split(".");
        let integerPart = "";

        for (let i = arr[0].length - 1; i >= 0; i--) {
            if ((arr[0].length - i - 1) % 3 === 0 && i != arr[0].length - 1) integerPart = " " + integerPart;
            integerPart = arr[0][i] + integerPart;
        }

        return (
            <span style={{color: "white"}}>{integerPart}<span className="float" style={{color: "#7D8496"}}>.{arr[1]}</span></span>
        )
    } catch(e) { return 0 }
}