import {useQuery} from "@tanstack/react-query";
import axios from "axios";

export const instance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
        "Content-Type": "application/json",
    }
});

export const useUser = () => {
    return useQuery({
        queryKey: ['user'],
        queryFn: ({signal}) =>
            instance
                .get('/auth/user', {
                    signal,
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem('access_tkn')}`
                    }
                })
                .then((res) => {
                    if (res.data.user?.ban?.type == "site") return null;
                    return res.data.user;
                })
                .catch((err) => {
                    return null;
                }),
        retry: 3,
    });
};
