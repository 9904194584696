import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useUser } from '../../lib/hooks';
import socket from '../../socket';
import { seperateBalance } from '../../helpers/balance';
import Chart from './chart';
import Banner from './banner';
import Bubbles from './bubbles';
import './crash.scss';

const opt = { hideProgressBar: true, theme: "dark", autoClose: 2000 };

export default function Crash({chatVisible, banner=false}) {

    const { data: user, refetch } = useUser();

    //Media Queries for responsive design
    const res_1500 = useMediaQuery('(max-width:1500px)');
    const res_950 = useMediaQuery('(max-width:950px)');
    const res_768 = useMediaQuery('(max-width:768px)');
    const res_550 = useMediaQuery('(max-width:550px)');

    //Game Screen Ref
    const gameScreen = useRef(0);

    const [showBanner, setBanner] = useState(banner);
    const [betAmountInput, setBetAmountInput] = useState("");
    const [autoCashoutInput, setAutoCashoutInput] = useState("");
    const [selectedCurrency, setSelectedCurrency] = useState("robux");
    const [currencyFilter, setCurrencyFilter] = useState("all");
    const [totalRobuxBet, setTotalRobuxBet] = useState(0);
    const [totalPointsBet, setTotalPointsBet] = useState(0);
    const [crashCountdown, setCrashCountdown] = useState(10.0);
    const [gameState, setGameState] = useState(0);
    const [betPlaced, setBetPlaced] = useState(false);
    const [cashouted, setCashouted] = useState(false);
    const [gameOver, setGameOver] = useState(false);
    const [chartMultipliers, setChartMultipliers] = useState([3, 2, 1]);
    const [multiplier, setMultiplier] = useState(1);
    const [time, setTime] = useState(1);
    const [players, setPlayers] = useState([]);
    const [oldGames, setOldGames] = useState([]);
    const [times, setTimes] = useState(res_768 ? [1, 3.8, 6.6, 8] : [1, 2.4, 3.8, 5.2, 6.6, 8]);
    const [timeWidth, setTimeWidth] = useState(100);
    const [multiplierPositions, setMultiplierPositions] = useState([-20, 90, 200, 305]);

    useEffect(() => {
        socket.emit("get-crash-info", {});
        socket.on("crash-info", data => {
            if (data?.game_over) {
                setBetPlaced(false);
                setCashouted(false);
                setTotalRobuxBet(0);
                setTotalPointsBet(0);
                setChartMultipliers([3, 2, 1]);
                setPlayers([]);
                setTimes(res_768 ? [1, 3.8, 6.6, 8] : [1, 2.4, 3.8, 5.2, 6.6, 8]);
                setMultiplierPositions([-20, 90, 200, 305]);
                setTimeWidth(100);
            } else {
                setPlayers(data?.players);
                let sumR = 0, sumP = 0;
                data?.players?.forEach(player => {
                    if (player.type === "robux") sumR += player.bet;
                    else sumP += player.bet;
                });
                setTotalRobuxBet(sumR);
                setTotalPointsBet(sumP);
            }

            if (data?.old_games) setOldGames(data.old_games);
            if (data?.isPlayerPlaying) setBetPlaced(true);
        });

        socket.on("crash-countdown-timer", data => {
            setGameOver(false);
            setGameState(0);
            setCrashCountdown(data?.timer?.toFixed(2));
        });

        socket.on("crash-bet-response", data => {
            toast.dismiss();
            if (data?.err) toast.error(<span><b>Error</b><br/>{data.err}</span>, opt);
            if (data?.success) toast.success(<span><b>Success</b><br/>{data.success}</span>, opt);
            if (data?.info) toast.info(<span><b>Information</b><br/>{data.info}</span>, opt);
            if (data?.betPlaced) setBetPlaced(true);
            refetch();
        });

        socket.on("crash-cashout-response", data => {
            toast.dismiss();
            if (data?.autoCashout) {
                toast.info(<span><b>Auto Cashout</b><br/>Auto Cashout at <b>x{data.autoCashout}</b> worked successfully</span>, opt);
            }
            if (data?.win) {
                toast.success(<span><b>Amazing!</b><br/>You've won <div className="amount">{seperateBalance(data?.win)}{data.type === "robux" ? <img src="images/robux.svg" /> : <img src="images/points.png" className='points' />}</div> from Crash!</span>, opt);
                setCashouted(true);
            }
            else if (data?.lost) {
                toast.error(<span><b>Bad luck!</b><br/>You've lost <div className="amount">{seperateBalance(data.lost)}{data.type === "robux" ? <img src="images/robux.svg" /> : <img src="images/points.png" className='points' />}</div> from Crash.</span>, opt);
            }
            refetch();
        });
    }, []);

    useEffect(() => {
        socket.on("crash-multiplier", data => {
            if (data?.ratio) {
                setMultiplier(data.ratio);
                setGameState(1);
            }
            if (data?.time) {
                setTime(data.time * 5);
            }
            else if (data?.final_ratio) {
                setGameState(1);
                
                if (!gameOver) {
                    setMultiplier(data.final_ratio);
                    setGameOver(true);
                    setPlayers(data.players);
                    setTimeout(() => setGameState(0), 4000);
                }
            }

            //Close currency selection menu if open
            if (open) handleClose();
        });

        return () => socket.off("crash-multiplier");
    }, [multiplier])

    useEffect(() => {
        let time_ = time / 5;
        let interval = setInterval(() => {
            if (Math.floor((time_ / 1000)) >= 8 && gameState === 1) {
                setTimeWidth(
                    Math.floor(time_ / 1000) >= 20
                    ?  res_768
                        ? timeWidth - 0.09
                        : timeWidth - 0.06
                    : res_768
                        ? timeWidth - 0.045
                        : timeWidth - 0.055
                );
                if (!times.includes(Math.floor((time_ / 1000)))) {
                    if ((Math.floor((time_ / 1000)) + 20) % 40 === 0) {
                        setTimes([Math.floor((time_ / 1000)) - 10, Math.floor((time_ / 1000))]);
                        setTimeWidth(100);
                    } else if (Math.floor((time_ / 1000)) >= 20 && Math.floor((time_ / 1000)) % 10 === 0) {
                        if (times.includes(8)) setTimes([Math.floor((time_ / 1000)) - 10, Math.floor((time_ / 1000))]);
                        else setTimes([...times, Math.floor((time_ / 1000))]);
                        setTimeWidth(100);
                    } else if (Math.floor((time_ / 1000)) < 20 && (((res_768) ? Math.floor((time_ / 1000)) % 6 : Math.floor((time_ / 1000)) % 4) === 0)) {
                        setTimes([...times, Math.floor((time_ / 1000))]);
                    }
                }
            } else setTimeWidth(timeWidth + .000001);
        }, 10);

        return () => clearInterval(interval);
    }, [timeWidth]);

    useEffect(() => {
        //Multiplier positions
        if (multiplier >= 3) {
            if (chartMultipliers.length === 3 && multiplier >= 3) setChartMultipliers([
                Math.round(multiplier) + 1,
                Math.round(multiplier),
                Math.round(multiplier) - 1,
                1
            ]);

            let pos_clone = [...multiplierPositions];
            for (let i = 0; i < pos_clone.length; i++) {
                if (i !== multiplierPositions.length - 1) {
                    pos_clone[i] += 1;
                    
                    if (pos_clone[i] >= 305) {
                        pos_clone[i] = -20;

                        let arr = [...chartMultipliers];
                        if (multiplier > 700) arr[i] = Math.floor(multiplier * 1.12);
                        else if (multiplier > 20) arr[i] = Math.floor(multiplier * 1.1 + 5);
                        else if (multiplier < 9) {
                            if (!arr.includes(Math.floor(multiplier + 1))) arr[i] = Math.floor(multiplier + 1);
                            else arr[i] = Math.floor(multiplier + 2);
                        }
                        else arr[i] = Math.floor(multiplier + 2);
                        setChartMultipliers(arr);
                    }
                }
            }
            setMultiplierPositions(pos_clone);
        }
    }, [Math.round(time / 100) * 100]);

    function placeBet() {
        toast.dismiss();
        if (user && gameState === 0) {
            let autoCashoutLegit = true;
            if (autoCashoutInput <= 1) {
                setAutoCashoutInput(null);
                if (typeof autoCashoutInput == "number") {
                    toast.error(<span><b>Error</b><br/>Auto cashout must be greater than 1</span>, opt);
                    autoCashoutLegit = false;
                }
            }
            if (betAmountInput < 1) toast.error(<span><b>Error</b><br/>Bet amount must be greater than 1</span>, opt);
            else if (autoCashoutLegit) {
                socket.emit("crash-place-bet", {
                    user_id: user.id,
                    secret_id: user._id,
                    bet: betAmountInput,
                    type: selectedCurrency,
                    auto_cashout: autoCashoutInput || 0,
                });
            }
        } else {
            toast.error(<span><b>Error</b><br/>You have to login to play Crash</span>, opt);
        }
    }

    function cashout() {
        if (betPlaced) {
            socket.emit("crash-cashout", {
                user_id: user.id,
                secret_id: user._id,
            });
        }
    }

    //Currency dropdown
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <div id="crash">
            <div className="crash-bg" ></div>
            
            {/* Banner */}
            {showBanner && <Banner chatVisible={chatVisible} setBanner={setBanner} /> }

            {/* Game History */}
            <div className="past-games">
                {oldGames.map((ratio, index) => {
                    return <div key={index} className={ratio >= 2 ? "win" : "not-win"}>x {ratio?.toFixed(2)}</div>
                })}
            </div>

            <div className='game' ref={gameScreen}>
                <div className='game-screen'>

                    <div className='section-1'></div>
                    <div className='section-2'></div>
                    <div className='section-3'></div>

                    {/* Ratio Axis */}
                    {chartMultipliers.map((m, i) => {
                        if (gameState !== 1) return;
                        return (
                            <div key={i}>
                                <div
                                    className={"multiplier " + (i === chartMultipliers.length - 1 ? "active" : "")}
                                    style={{top: multiplierPositions[chartMultipliers.length === 3 ? i + 1 : i]}}
                                >
                                    {m}x
                                </div>
                            </div>
                        )
                    })}
                    
                    {/* Time Axis */}
                    <div className='section-time' style={{paddingRight: 100 - timeWidth + "%"}}>
                        {times.map((t, i) => {
                            return (
                                <div
                                    className='second'
                                    key={i}
                                    style={([12, 16].includes(t) && i === times.length - 1) ? {
                                        marginRight: "-70px"
                                    } : {}}
                                >{t.toFixed(1)}s</div>
                            )
                        })}
                    </div>
                    
                </div>
                { gameState === 1 &&
                    <>
                        <Bubbles w={gameScreen.current.offsetWidth} h={gameScreen.current.offsetHeight} />
                        <div id="chart-container">
                            <Chart
                                multiplier={multiplier}
                                time={Math.round(time * 100) / 100}
                                mobile={res_768}
                                gameOver={gameOver}
                            />
                        </div>
                    </>
                }
                { gameState === 0 &&
                    <div className='next-round-container'>
                        <div className="next-round">
                            <div className={"left-bubbles" + (parseFloat(crashCountdown) <= .4 ? " send-bubbles" : "")} >
                                <img src="images/bubbles/bubble-1.svg" />
                                <img src="images/bubbles/bubble-4.svg" />
                                <img src="images/bubbles/bubble-5.svg" />
                                <img src="images/bubbles/bubble-7.svg" />
                            </div>
                            <div className="counter">
                                <img src="images/submarine-static.gif" className={"submarine" + (parseFloat(crashCountdown) <= .4 ? " send-submarine" : "")} />
                                <p>Next round in {crashCountdown}s</p>
                            </div>
                            <div className={"right-bubbles" + (parseFloat(crashCountdown) <= .4 ? " send-bubbles" : "")} >
                                <img src="images/bubbles/bubble-3.svg" />
                                <img src="images/bubbles/bubble-8.svg" />
                                <img src="images/bubbles/bubble-6.svg" />
                                <img src="images/bubbles/bubble-2.svg" />
                            </div>
                        </div>
                    </div>
                }
                { gameState === 1 &&
                    <div className="game-started">
                        <div>
                            {/*<p>Game started</p>*/}
                            <h2 style={{...(gameOver ? {color: "#C11B1B"} : {}), width: 130 + (parseInt(multiplier).toString().length * 40) + "px"}}>x{multiplier?.toFixed(2)}</h2>
                            { (res_768 && gameOver) &&
                            <>
                                <img src="images/light-6.svg" className='light' />
                                <img src="images/light-6.svg" className='light' />
                            </>
                            }
                        </div>
                    </div>
                }
                { gameState === 2 &&
                    <div className="game-over">
                        <div>
                            <p>Crash</p>
                            <h2>x{multiplier?.toFixed(2)}</h2>
                            { res_768 &&
                            <>
                                <img src="images/light-6.svg" className='light' />
                                <img src="images/light-6.svg" className='light' />
                            </>
                            }
                            <img src="images/gameover-octopus.png" />
                        </div>
                    </div>
                }
            </div>
            <div className={'place-bet ' + ((chatVisible || res_1500) ? "" : "chat-visible") + ((res_950) ? "small" : "")}>
                <div className="amount-container" style={open ? {transition: "100ms", borderRadius: "1000px 1000px 1000px 0"} : {borderRadius: "1000px"}}>
                    <div className="currency-selection" style={open ? {backgroundColor: "#2A2F3D", borderRadius: "35px 0 0 0"} : {}}>
                        <Button
                            id="currency-button"
                            className={open ? "active" : undefined}
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            style={{ backgroundColor: 'transparent' }} 
                            onClick={(e) => {if (gameState !== 1) {
                                handleClick(e);
                            }}}
                        >
                            { selectedCurrency === "robux"
                                ? <>
                                { gameState === 1 
                                    ? <img src="images/robux-gray-3.svg" className='robux' />
                                    : <img src="images/robux.svg" className='robux' />
                                }
                                </>
                                : <img src="images/points.png" className='points' />
                            }
                            { open ? (
                                <img className="down" src="images/down-white.svg" style={open ? {transform: "rotateZ(180deg)"} : {}} />
                            ) : (
                                <img className="down" src="images/down.svg" style={open ? {transform: "rotateZ(180deg)"} : {}} />
                            )}
                        </Button>
                        <Menu
                            id="currency-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{'aria-labelledby': 'currency-button'}}
                            style={{marginTop: selectedCurrency === "points" ? "-10px" : "5px"}}
                        >
                            {selectedCurrency === "robux" ? (
                                <MenuItem
                                    className="currency-menu-item" 
                                    onClick={() => { setSelectedCurrency("points"); handleClose(); }}
                                >
                                    <img src="images/points.png" className='points' />
                                </MenuItem>
                            ) : (
                                <MenuItem
                                    className="currency-menu-item" 
                                    onClick={() => { setSelectedCurrency("robux"); handleClose(); }}
                                >
                                    <img src="images/robux.svg" />
                                </MenuItem>
                            )}
                        </Menu>
                    </div>
                    <div className="enter-bet-amount">
                        <input
                            type="number"
                            placeholder='0.00'
                            value={betAmountInput}
                            onChange={(e) => setBetAmountInput(parseFloat(parseFloat(e.target.value).toFixed(2)))}
                        />
                    </div>
                    <div className="controllers">
                        <button onClick={() => setBetAmountInput(1)}>Min</button>
                        <button onClick={() => setBetAmountInput(parseFloat(parseFloat(betAmountInput / 2).toFixed(2)))}>1/2</button>
                        <button onClick={() => setBetAmountInput(parseFloat(parseFloat(user?.balance?.[selectedCurrency] || 9999).toFixed(2)))}>Max</button>
                    </div>
                </div>
                <div className="auto-cashout-container">
                    <div className="auto-cashout">
                        <div>
                            <span>Auto cashout</span>
                            <input
                                type="number"
                                placeholder='2'
                                value={autoCashoutInput}
                                onChange={(e) => setAutoCashoutInput(parseFloat(parseFloat(e.target.value).toFixed(2)))}
                            />
                        </div>
                        <div className='controllers'>
                            <button onClick={() => setAutoCashoutInput(2)}>x2</button>
                            <button onClick={() => setAutoCashoutInput(4)}>x4</button>
                        </div>
                    </div>
                    <div className="buttons">
                        <div>
                            <PopupState variant="popover">
                                {(popupState) => (
                                <>
                                    <div
                                        className={"info-box " + (popupState?.isOpen ? "active" : "")}
                                        variant="contained"
                                        {...bindTrigger(popupState)}
                                    >
                                        <img src={popupState?.isOpen ? "images/question-white.svg" : "images/question.svg"} />
                                    </div>
                                    <Popover
                                        {...bindPopover(popupState)}
                                        className="header-info-popover"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                backgroundColor:"#1E222C",
                                                minHeight: "40px",
                                                padding: "20px",
                                                border: "1px solid #2E323D",
                                                boxShadow: "0px 24px 48px rgba(19, 22, 29, 0.24)",
                                                display: "flex",
                                                flexDirection: "column",
                                                borderRadius: "16px 0 16px 16px",
                                                width: res_550 ? "calc(100% - 30px)" : "450px"
                                            }}
                                        >
                                            <span style={{
                                                color: "white",
                                                fontSize: "18px",
                                                fontWeight: "500",
                                                marginBottom: "10px"
                                            }}>What is Crash?</span>
                                            <span style={{
                                                color:"#7D8496",
                                                fontSize:"17px",
                                                marginBottom: "15px"
                                            }}>Experience the adrenaline rush in our fast-paced Crash game, where players stake ROBUX on a soaring multiplier. Test your instincts and cash out before the multiplier crashes, or watch your bet sink into the depth of the ocean. Do you have what it takes to beat the odds?</span>
                                        </Typography>
                                    </Popover>
                                </>
                                )}
                            </PopupState>
                        </div>
                        <div className='button-box'>
                            <button onClick={() => {
                                if (gameState === 0 && !betPlaced) placeBet();
                                else if (gameState === 1 && betPlaced) cashout();
                            }} className={
                                gameState !== 0 
                                    ? betPlaced
                                        ? gameState === 1
                                            ? cashouted
                                                ? "inactive"
                                                : undefined
                                            : "inactive"
                                        : "inactive" 
                                    : betPlaced
                                        ? "inactive"
                                        : undefined
                                }
                            >
                                { (gameState === 0 || (gameState !== 0 && !betPlaced)) && "Place a bet" }
                                { (gameState !== 0 && betPlaced) && "Cashout" }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <hr style={{border: "1px solid #2E323D", margin: "0 24px"}} />
            <div className='bets'>
                <div className="bets-header">
                    <div className='title'>
                        <h2>All bets</h2>
                        <span>{players?.length} players</span>
                    </div>
                    <div className="currency-filter">
                        <div
                            onClick={() => {
                                if (currencyFilter === "points" || currencyFilter === "all") setCurrencyFilter("robux");
                                else setCurrencyFilter("all");
                            }}
                            className={(currencyFilter === "robux" || currencyFilter === "all") ? undefined : "inactive"}
                        >
                            <img src="images/robux.svg" />
                            {seperateBalance(totalRobuxBet)}
                        </div>
                        <div
                            onClick={() => {
                                if (currencyFilter === "robux" || currencyFilter === "all") setCurrencyFilter("points");
                                else setCurrencyFilter("all");
                            }}
                            className={(currencyFilter === "points" || currencyFilter === "all") ? undefined : "inactive"}
                        >
                            <img src="images/points.png" className='points' />
                            {seperateBalance(totalPointsBet)}
                        </div>
                    </div>
                </div>
                <div className="bets-body">
                    {players.map((d, i) => {
                        if (currencyFilter === d?.type || currencyFilter === "all") {
                            return (
                                <div className='bet' key={i}>
                                    <div>
                                        <img className='pfp' src={d.pfp} />
                                        <span className='username'>{d.username}</span>
                                        {d.username === user?.username && <span className='me'>Me</span>}
                                    </div>
                                    { d.ratio != 0 &&
                                        <div className="multiplier">
                                            <div className={d.ratio > 0 ? "win" : "lose"}>x{(Math.abs(d.ratio)).toFixed(2)}</div>
                                        </div>   
                                    }
                                    <div>
                                        {(d.ratio <= 0) ? (
                                        <>
                                            {seperateBalance(d.bet)}
                                            {d.type === "robux" ? <img src="images/robux.svg" /> : <img src="images/points.png" className='points' />}
                                        </>
                                        ) : (
                                        <>
                                            <span className='addup-value'>+{(parseFloat(d.bet * d.ratio) - parseFloat(d.bet)).toFixed(2)}</span>
                                            {seperateBalance(d.bet * d.ratio)}
                                            {d.type === "robux" ? <img src="images/robux.svg" /> : <img src="images/points.png" className='points' />}
                                        </>
                                        )}
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        </div>
    )
}