import { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { generateAffiliateColumns } from "./columns";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from "@mui/material";
import { useUser } from '../../lib/hooks';
import { seperateBalance } from "../../helpers/balance";
import './affiliate.scss';

let timeout = 0;

const opt = {
    hideProgressBar: true,
    theme: "dark",
    autoClose: 2000
};

export default function Affiliate() {

    const { data: user } = useUser();

    const res_1000 = useMediaQuery('(max-width:1000px)');

    function randomText(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    }

    const [code, setCode] = useState(user.affiliate.code)
    const [affiliateColumns, setAffiliateColumns] = useState([]);
    const [affiliateDatas, setAffiliateDatas] = useState([...user.affiliate.users]);
    const [activatedCode, setActivatedCode] = useState(user.affiliate.usedCode);
    const [activatedCodeInput, setActivatedCodeInput] = useState(false);

    const [sorted, setSorted] = useState(true);
    
    const codeRef = useRef(null);

    useEffect(() => {
        setAffiliateColumns(generateAffiliateColumns(res_1000));
    }, [res_1000]);

    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    function newPromoCode(code) {
        toast.dismiss();

        if (!/^[a-z0-9]+$/i.test(code)) { //If not only letters and numbers
            toast.error(<span><b>Error</b><br/>You can only use letters and numbers.</span>, opt);
        } else if (timeout === 0) {
            toast(<span>Loading...</span>, opt);
            fetch(process.env.REACT_APP_API_ENDPOINT + "/affiliate/set-promocode", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    code: code,
                    user_id: user.id,
                    secret_id: user._id
                })
            })
            .then(data => data.json())
            .then(data => {
                if (data?.err) {
                    toast.error(<span><b>Error</b><br/>{data.err}</span>, opt);
                }
                else if (data?.success) {
                    toast.success(<span><b>Success</b><br/>Your promocode has been saved.</span>, opt);
                    codeRef.current.blur();
                    setCode(code)
                }

                timeout = 5;
                let intrvl = setInterval(() => {
                    timeout--;
                    if (timeout === 0) clearInterval(intrvl);
                }, 1000);
            });
        } else {
            toast.error(<span><b>Spam</b><br/>Please wait {timeout} seconds</span>, opt);
        }
    }

    function activatePromoCode() {
        toast(<span>Loading...</span>,
        {
            hideProgressBar: true,
            theme: "dark"
        });

        fetch(process.env.REACT_APP_API_ENDPOINT + "/affiliate/use-promocode", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                code: activatedCodeInput,
                user_id: user.id,
                secret_id: user._id
            })
        })
        .then(data => data.json())
        .then(data => {
            toast.dismiss();
            
            if (data?.err) {
                toast.error(<span><b>Error</b><br/>{data.err}</span>, opt);
            }
            else if (data?.success) {
                toast.success(<span><b>Success</b><br/>Promocode successfully used.</span>, opt);
                setActivatedCode(activatedCodeInput);
            }
        });
    }

    function claimBalance() {
        if (user.affiliate.balance > 0) {
            toast(<span>Loading...</span>,
            {
                hideProgressBar: true,
                theme: "dark"
            });

            fetch(process.env.REACT_APP_API_ENDPOINT + "/affiliate/claim-earnings", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_id: user.id,
                    secret_id: user._id
                })
            })
            .then(data => data.json())
            .then(data => {
                toast.dismiss();

                if (data?.err) {
                    toast.error(<span><b>Error</b><br/>{data.err}</span>, opt);
                }
                else if (data?.success) {
                    toast.success(<span><b>Success</b><br/>Affiliate balance successfully claimed.</span>, opt);
                    
                    //Force refresh
                    setTimeout(() => window.location.reload(), 1000);
                }
            });
        } else {
            toast.error(<span><b>Error</b><br/>Claimable amount must be larger than 0</span>, opt);
        }
    }
    
    return (
        <div id="affiliate">
            <div className="affiliate-header">
                <h1>Affiliate</h1>
                <div>
                    <button onClick={claimBalance}>Claim</button>
                    <div>
                        {seperateBalance(user.affiliate.balance)}
                        <img src="images/robux.svg" />
                    </div>
                </div>
            </div>
            <div className="affiliate-nav">
                <p>Get started by creating your own personalized affiliate code/link below. You will earn 10% of the house edge from each user you have invited. Once a user uses your code they will also receive 20<img src="images/robux.svg" /></p>
                <div>
                    <div>
                        <input
                            id="users-code"
                            className="animated-input"
                            onChange={e => setCode(e.target.value)}
                            value={code}
                            ref={codeRef}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") { //When ENTER key pressed
                                    newPromoCode(e.target.value);
                                }
                            }}
                            required
                        />
                        <span className='label'>Your Code</span>
                        <div className="button-container">
                            <Popover
                                id="mouse-over-popover"
                                sx={{
                                    pointerEvents: 'none',
                                }}
                                open={open}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                onClose={handlePopoverClose}
                                disableRestoreFocus
                            >
                                <Typography
                                    style={{
                                        color: "#7D8496",
                                        padding: "18px 25px",
                                        background: "#1E222C",
                                        border: "2px solid #2E323D",
                                        borderRadius: "0px 16px 16px 16px",
                                        marginLeft: "20px",
                                        fontSize: "18px"
                                    }}
                                >Copy</Typography>
                            </Popover>
                            <button 
                                onClick={() => {
                                    toast.dismiss();
                                    try {
                                        navigator.clipboard.writeText(
                                            window.location.origin + "?ref=" + document.querySelector("#users-code").value
                                        );
                                        toast.success(<span><b>Success</b><br/>Affiliate link has been copied to clipboard.</span>, opt);
                                    } catch(e) {
                                        toast.error(<span><b>Error</b><br/>Affiliate link cannot be copied to clipboard.</span>, opt);
                                    }
                                }} 
                                className="copy-button"
                                aria-owns={open ? 'mouse-over-popover' : undefined}
                                aria-haspopup="true"
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                            >
                                <img src="images/copy.svg" />
                            </button>
                            <button 
                                onClick={() => {
                                    newPromoCode(randomText(7));
                                }}
                                className="regenerate-button"
                            >
                                <img src="images/reload-white.svg" />
                            </button>
                        </div>
                    </div>
                    <div className={activatedCode ? "read-only" : undefined}>
                        <input
                            className={"animated-input " + (activatedCode ? "disabled" : "")}
                            onChange={(e) => setActivatedCodeInput(e.target.value)}
                            readOnly={activatedCode}
                            defaultValue={activatedCode}
                            required
                        />
                        <span className='label'>Promocode</span>
                        <div className="button-container">
                            { !activatedCode 
                            ? (
                                <button
                                    onClick={() => activatePromoCode()}
                                >Activate</button>
                            )  
                            : (
                                <span>Already in use</span>
                            )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="affiliate-overall">
                <div>
                    <h3>Affiliated users</h3>
                    <p>{affiliateDatas.length}</p>
                </div>
                <div>
                    <h3>Total earnings</h3>
                    <div>
                        <img src="images/robux.svg" />
                        {seperateBalance(user.affiliate.datas.earning)}
                    </div>
                </div>
                <div>
                    <h3>Total wagered</h3>
                    <div>
                        <img src="images/robux.svg" />
                        {seperateBalance(user.affiliate.datas.wager)}
                    </div>
                </div>
            </div>
            { res_1000 &&
                <div className="sorting-container">
                    <div className="sort" onClick={() => {
                        setSorted(!sorted);
                        setAffiliateDatas([...affiliateDatas].reverse());
                    }}>
                        {sorted ? (
                            <>
                                <span>New ones first</span>
                                <img src="images/down.svg" />
                            </>
                        ) : (
                            <>
                                <span>Old ones first</span>
                                <img src="images/down.svg" style={{transform: "rotateZ(180deg)"}} />
                            </>
                        )
                        }
                    </div>
                </div>
            }
            <div className="affiliate-datas">
                <DataTable
                    columns={affiliateColumns}
                    data={affiliateDatas}
                    striped
                    noHeader
                    className="affiliate-table"
                />
            </div>
        </div>
    )
}