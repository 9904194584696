import { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import LinearProgress from '@mui/material/LinearProgress';
import { useMediaQuery } from '@mui/material';
import { toast } from 'react-toastify';
import { useUser } from '../../lib/hooks';
import Currencies from '../currencies';
import './rakeback.scss';

let counterOG = 3600; // 3600 = 1 Hour

export default function Rakeback({setRakebackModal, rakebackModal}) {

    const { data: user, refetch } = useUser();

    const [progress, setProgress] = useState(0);
    const [datas, setDatas] = useState({});
    const [counter, setCounter] = useState(null);
    const [counterAsDate, setCounterDate] = useState("00:00");
    const [refetched, setRefetched] = useState(false);

    const res_768 = useMediaQuery('(max-width:768px)');

    useEffect(() => {
        if (counter !== null) {
            let counterTimeout = setTimeout(() => {
                if (counter > 0) {
                    setCounter(counter - 1);
                    let minutes = Math.floor(counter / 60).toString();
                    let seconds = (counter - minutes * 60).toString();
                    if (minutes.length === 1) minutes = "0" + minutes;
                    if (seconds.length === 1) seconds = "0" + seconds;
                    
                    setCounterDate(minutes + ":" + seconds);
                }
                else {
                    setCounter(0);
                    setCounterDate(0);
                    clearTimeout(counterTimeout);
                }
            }, 1000);
        }
    }, [counter]);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_ENDPOINT + "/user/get-rakeback", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                user_id: user.id,
                secret_id: user._id
            })
        })
        .then(data => data.json())
        .then(data => {
            if (data?.err) {
                toast.dismiss();
                toast.error(data.err, {
                    theme: 'dark',
                    hideProgressBar: true,
                    autoClose: 2000
                });
            } else if (data?.data) {
                let r = data.data;
                setDatas(r);
                
                //Set progress
                if (r.rank === "gold") {
                    setProgress(100);
                } else {
                    let levelTable = r.levels;
                    let levelDif = levelTable[r.rank][1] - levelTable[r.rank][0];
                    setProgress((r.level - levelTable[r.rank][0]) / levelDif * 100);
                }

                //Cooldown
                let cooldown = r.cooldownDate,
                    date = r.currentDate;

                if (!cooldown) return;

                let diff = date - cooldown,
                    diff_s = Math.floor(diff / (1000));

                if (diff_s >= counterOG) return;
                else setCounter(counterOG - diff_s);
            }
        });
    }, [refetched, rakebackModal]);

    function claimBalance() {
        toast.dismiss();
        if (counter !== 0 && counter !== null) return;
        if (datas?.balance?.robux > 0 || datas?.balance?.points > 0) {
            fetch(process.env.REACT_APP_API_ENDPOINT + "/user/claim-rakeback", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_id: user.id,
                    secret_id: user._id
                })
            })
            .then(data => data.json())
            .then(data => {
                if (data?.err) {
                    toast.error(data.err, {
                        theme: 'dark',
                        hideProgressBar: true,
                        autoClose: 2000
                    });
                } else {
                    toast.success("Rakeback balance successfully claimed.", {
                        theme: 'dark',
                        hideProgressBar: true,
                        autoClose: 2000
                    });
                    setCounter(counterOG);
                    setRefetched(!refetched);
                    refetch();

                    //setRakebackModal(false);
                    ///Force refresh
                    //setTimeout(() => window.location.reload(), 1000);
                }
            });
        } else {
            toast.error(<span><b>Error</b><br/>Claimable amount must be larger than 0</span>, {
                hideProgressBar: true,
                theme: "dark",
                autoClose: 2000
            });
        }
    }

    return (
        <Modal
            open={rakebackModal}
            onClose={() => setRakebackModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{background: "#13161D8F"}}
        >
            <Box sx={res_768 ? MobileBoxStyle : BoxStyle} id="rakeback">
                <button onClick={() => setRakebackModal(false)} className="close-button"><img src="images/x.svg" /></button>
                <div className='rakeback-header'>
                    <h1>Rakeback</h1>
                    <PopupState variant="popover">
                        {(popupState) => (
                        <>
                            <div
                                className={"info-box " + (popupState?.isOpen ? "active" : "")}
                                variant="contained"
                                {...bindTrigger(popupState)}
                                style={{height:"25px", cursor:"pointer"}}
                            >
                                <img src={popupState?.isOpen ? "images/question-white.svg" : "images/question.svg"} />
                            </div>
                            <Popover
                                {...bindPopover(popupState)}
                                className="header-info-popover"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <Typography
                                    style={{
                                        backgroundColor:"#1E222C",
                                        padding: "20px",
                                        border: "1px solid #2E323D",
                                        boxShadow: "0px 24px 48px rgba(19, 22, 29, 0.24)",
                                        display: "flex",
                                        flexDirection: "column",
                                        borderRadius: "0 16px 16px 16px",
                                        width:res_768 ? "100%" : "450px",
                                    }}
                                >
                                    <span style={{
                                        color: "white",
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        marginBottom: "10px"
                                    }}>What's it?</span>

                                    <span style={{
                                        color:"#7D8496",
                                        fontSize:"17px",
                                        marginBottom: "15px"
                                    }}>
                                        With rakeback you get a percentage of the house edge returned, this percentage depends on your level. Wager data for levels will only be used from the past 7 DAYS!
                                        <br/><br/>
                                        Rakeback levels:
                                        <br/>
                                        <img style={{marginBottom: "-5px"}} src="images/levels/bronze.svg" /> Bronze 5% | 0 - 150.000 wagered
                                        <br/>
                                        <img style={{marginBottom: "-5px"}} src="images/levels/silver.svg" /> Silver 6,5% | 150.000 - 500.000 wagered
                                        <br/>
                                        <img style={{marginBottom: "-5px"}} src="images/levels/gold.svg" /> Gold 8% | 500.000+ wagered
                                    </span>
                                </Typography>
                            </Popover>
                        </>
                        )}
                    </PopupState>
                </div>
                <div className="rakeback-body">
                    { (datas?.rank && datas?.levels) &&
                        <div>
                            <div>
                                <img src={"images/levels/"+datas.rank+".svg"} />
                                <span>{datas.rank}</span>
                            </div>
                            <div>
                                { (Object.keys(datas.levels).indexOf(datas.rank) !== Object.keys(datas.levels).length - 1) &&
                                    <>
                                        <span>{Object.keys(datas.levels)[Object.keys(datas.levels).indexOf(datas.rank) + 1]}</span>
                                        <img src={"images/levels/"+Object.keys(datas.levels)[Object.keys(datas.levels).indexOf(datas.rank) + 1]+".svg"} />
                                    </>
                                }
                            </div>
                        </div>
                    }
                    <div className="progress-container">
                        <LinearProgress value={progress} variant="determinate" />
                        <div className="circle" style={{left: progress + "%"}}></div>
                    </div>
                </div>
                <div className='rakeback-footer'>
                    <div>
                        <Currencies balances={datas.balance} withoutInfo={true} style={{justifyContent: "flex-start", width:"fit-content"}} />
                    </div>
                    <div className="buttons">
                        { (counter !== 0 && counter !== null)
                        ? (
                            <button className="cooldown">
                                <span>{counterAsDate}</span>
                            </button>
                        ) : (
                            <button onClick={claimBalance} className='available'>
                                <span>Claim</span>
                            </button>
                        )}
                        <button className="cancel" onClick={() => setRakebackModal(false)}>
                            <span>Cancel</span>
                        </button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

const BoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:630,
    height:280,
    background: "#1E222C",
    border: "2px solid #2E323D",
    borderRadius:"16px",
    padding: "25px",
    outline: "none"
};

const MobileBoxStyle = {
    position: 'fixed !important',
    width: "calc(100vw - 33px)",
    bottom: "0",
    background: "#1E222C",
    borderRadius:"18px 18px 0 0",
    outline: "none"
}