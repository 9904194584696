import { Link } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';
import { seperateBalance } from '../../helpers/balance';
import './currencies.scss';

export default function Currencies({ style = {}, withoutInfo = false, user, balances = null }) {

    const res_768 = useMediaQuery('(max-width:768px)');

    if (!user && !balances) return;
    return (
        <div className="currencies" style={style}>
            <div>
                <img src="images/robux.svg" />
                {seperateBalance(balances ? balances["robux"] : user?.balance?.robux)}
            </div>
            <div>
                <img src="images/points.png" className="points" />
                {seperateBalance(balances ? balances["points"] : user?.balance?.points)}
            </div>
            {!withoutInfo &&
            <PopupState variant="popover">
                {(popupState) => (
                <>
                    {popupState?.isOpen && <div className='background-shadow'></div>}
                    <img
                        className="what" 
                        src={popupState?.isOpen ? "images/question-white.svg" : "images/question.svg"}
                        variant="contained"
                        {...bindTrigger(popupState)}
                    />
                    <Popover
                        {...bindPopover(popupState)}
                        className="header-info-popover"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <Typography
                            style={{
                                backgroundColor:"#1E222C",
                                minHeight: "40px",
                                padding: "20px",
                                border: "1px solid #2E323D",
                                boxShadow: "0px 24px 48px rgba(19, 22, 29, 0.24)",
                                display: "flex",
                                flexDirection: "column",
                                borderRadius: res_768 ? "16px 16px 0 0" : "16px 0 16px 16px",
                                width: res_768 ? "calc(100vw - 40px)" : "400px",
                                position: res_768 ? "fixed" : undefined,
                                left: res_768 && "0",
                                bottom: res_768 && "0"
                            }}
                        >
                            <span style={{
                                color: "white",
                                fontSize: res_768 ? "20px" : "18px",
                                fontWeight: "500",
                                marginBottom: res_768 ? "30px" : "10px"
                            }}>What's it?</span>
                            
                            <span style={{
                                color:"#7D8496",
                                fontSize:"17px",
                                marginBottom: res_768 ? "23px" : "15px"
                            }}>This shows the balance of your virtual currency. You can deposit virtual currency by Robux, limited items and fiat. You can also withdraw virtual currency by Robux and limited items.</span>
                            
                            <Link style={{
                                background: "linear-gradient(258.68deg, #4571E2 -25.12%, #2456D6 57.73%, #11358E 130.84%)",
                                boxShadow: "0px 24px 48px rgba(31, 82, 214, 0.16)",
                                borderRadius: "1000px",
                                color:"white",
                                fontSize:"18px",
                                padding:"20px 0",
                                textAlign: "center"
                            }}
                            to="/deposit">Deposit</Link>

                            { res_768 &&
                                <Link style={{
                                background: "#2A2F3D",
                                borderRadius: "1000px",
                                color:"white",
                                fontSize:"18px",
                                padding:"20px 0",
                                textAlign: "center",
                                marginTop: "15px"
                            }} onClick={() => popupState.setOpen(false)}>Close</Link>}
                            
                            <button style={{
                                borderRadius: "100%",
                                background: "#2A2F3D",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "34px",
                                height: "34px",
                                border: "none",
                                cursor: "pointer",
                                position: "absolute",
                                right: "15px",
                                top: "15px"
                            }} onClick={() => popupState.setOpen(false)}><img src="images/x.svg" /></button>
                        </Typography>
                    </Popover>
                </>
                )}
            </PopupState>
            }
        </div>
    )
}