import { Link } from 'react-router-dom';
import './bottomnav.scss';

export default function BottomNav({path, setChatVisible}) {
    return (
        <div id="bottom-nav">
            {/* Items */}
            <div className={"item " + ((path === "/crash" || path === "/") ? "active" : "")}>
                <Link className={path === "/crash" ? "active" : undefined} to="/crash">
                    {(path === "/crash" || path === "/")
                        ? <img src="images/crash-active.svg" />
                        : <img src="images/crash.svg" />}
                    <p>Crash</p>
                </Link>
            </div>
            <div className={"item " + (path === "/mines" ? "active" : "")}>
                <Link to="/mines">
                    {path === "/mines" 
                        ? <img src="images/mines-active.svg" />
                        : <img src="images/mines.svg" />}
                    <p>Mines</p>
                </Link>
            </div>
            <div className={"item " + (path === "/roulette" ? "active" : "")}>
                <Link to="/roulette">
                    {path === "/roulette" 
                        ? <img src="images/roulette-active.svg" />
                        : <img src="images/roulette.svg" />}
                    <p>Roulette</p>
                </Link>
            </div>
            <div className="item">
                <a onClick={() => setChatVisible(true)}>
                    <img src="images/chat.svg" />
                    <p>Chat</p>
                </a>
            </div>
        </div>
    )
}