import Popover from '@mui/material/Popover';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify'
import { useUser } from '../../lib/hooks';
import { setLang } from '../../store/reducers/languageReducer';
import Currencies from '../currencies';
import Login from '../login';
import Rakeback from '../rakeback';
import Leaderboard from '../leaderboard';
import { seperateBalance } from '../../helpers/balance';
import './header.scss';
import './hamburger.scss';

const opt = {
    hideProgressBar: true,
    theme: "dark",
    autoClose: 2000
}

function Hamburger({ user, setHamburgerMenu, setRakebackModal, logout }) {

    const [leaderboardModal, setLeaderboard] = useState(false);

    function close() {
        setHamburgerMenu(false);
    }

    return (
        <div id="hamburger">
            {user &&
                //Show user datas
                <div className='user-data'>
                    <Link onClick={close} to="profile"><img src="images/custom-avatar.png" /></Link>
                    <Currencies user={user} />
                </div>
            }
            <ul className='list-one'>
                <li onClick={close}><Link to={user ? "affiliate" : undefined} onClick={() => {
                    if (!user) {
                        toast.error(<span><b>Error</b><br />You need to login first.</span>, opt);
                    }
                }}><img src="images/affiliate.svg" />Affiliate</Link></li>
                <li onClick={close}><Link to="faq"><img src="images/question.svg" />FAQ</Link></li>
                <li onClick={close}><Link to="privacy"><img src="images/privacy.svg" />Privacy</Link></li>
                <li className='rakeback' onClick={() => {
                    if (user) {
                        setRakebackModal(true);
                        close();
                    } else {
                        toast.error(<span><b>Error</b><br />You need to login first.</span>, opt);
                    }
                }}>
                    <Link>
                        <img src="images/wallet.svg" />Rakeback
                    </Link>
                </li>
            </ul>
            <ul className='list-two'>
                <li onClick={close}><Link to="#"><img src="images/support.svg" />Support</Link></li>
                <li onClick={() => { setLeaderboard(true); }}><Link><img src="images/trophy.svg" />Leaderboard</Link></li>
                {user &&
                    <li onClick={() => { logout.mutate(); close(); window.location.href = "/"; }}><Link><img src="images/logout.svg" />Leave</Link></li>
                }
            </ul>

            <Leaderboard
                leaderboardModal={leaderboardModal}
                setLeaderboard={setLeaderboard}
            />
        </div>
    )
}

function HeaderMobile({ user, setRakebackModal, setLoginModal, logout, dev }) {

    const dispatch = useDispatch();
    const lang = useSelector(state => state.lang);

    const [showHamburgerMenu, setHamburgerMenu] = useState(false);
    const res_1283 = useMediaQuery('(max-width:1283px)');

    useEffect(() => { if (!res_1283) setHamburgerMenu(false) }, [res_1283])

    return (
        <>
            <div className='header-mobile'>
                <div className='start'>
                    <div className="header-logo-container">
                        <Link to="/"><img src="images/favicon.svg" /></Link>
                    </div>
                    <div className="language-selection">
                        <PopupState variant="popover">
                            {(popupState) => (
                                <>
                                    <a
                                        variant="contained"
                                        {...bindTrigger(popupState)}
                                    >
                                        <img src={lang.flags[lang.lang]} />
                                    </a>
                                    <Popover
                                        {...bindPopover(popupState)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: "#1E222C",
                                                display: "flex",
                                                flexDirection: "column",
                                                borderRadius: "0px 0 16px 16px",
                                                border: "1px solid #2E323D",
                                                width: "70px",
                                            }}
                                        >
                                            <div className="language-menu right-nav-lang header-nav">
                                                {Object.keys(lang.flags).map((key, i) => {
                                                    if (key !== lang.lang) {
                                                        return (
                                                            <div className='item' key={key}>
                                                                <a onClick={() => {
                                                                    localStorage.setItem("language", key);
                                                                    dispatch(setLang(key));
                                                                    popupState.setOpen(false);
                                                                }}>
                                                                    <img src={lang.flags[key]} />
                                                                </a>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </Popover>
                                </>
                            )}
                        </PopupState>
                    </div>
                </div>
                { dev !== false &&
                    <div className='end'>
                        {user ? (
                            <div className="balance">
                                {seperateBalance(user?.balance?.robux)}
                                <img src="images/robux.svg" />
                            </div>
                        ) : (
                            <a onClick={() => setLoginModal(true)} className="sign-in">
                                Sign In
                            </a>
                        )
                        }
                        <div className='hamburger-container'>
                            <img className={showHamburgerMenu ? "close" : undefined} src={showHamburgerMenu ? "images/x-white.svg" : "images/hamburger.svg"} onClick={() => {
                                if (dev !== false) setHamburgerMenu(!showHamburgerMenu);
                            }} />
                        </div>
                    </div>
                }
            </div>
            {showHamburgerMenu &&
                <Hamburger
                    user={user}
                    logout={logout}
                    setHamburgerMenu={setHamburgerMenu}
                    setRakebackModal={setRakebackModal}
                />
            }
        </>
    )
}

export default function Header({ activePage = null, logout, dev }) {

    const { data: user, isLoading } = useUser();

    const [loginModal, setLoginModal] = useState(false);
    const [rakebackModal, setRakebackModal] = useState(false);

    return (
        <header>
            <div className="large-header"> {/* Header for larger resolution */}
                <div className="header-logo-container">
                    <Link to="/"><img src="images/favicon.svg" /></Link>
                </div>

                <div className="header-content">
                    <div className="header-content-routes">
                        <div className="rakeback-container">
                            <a onClick={() => {
                                if (user) setRakebackModal(true);
                                else {
                                    toast.error(<span><b>Error</b><br />You need to login first.</span>, opt);
                                }
                            }}>
                                <div className="orange-circle"></div>
                                <div className='bg-light'></div>
                                <img className='icon' src="images/wallet.svg" />
                                <span>Rakeback</span>
                            </a>
                        </div>
                        <div>
                            <Link to={user ? "affiliate" : undefined} onClick={() => {
                                if (!user) {
                                    toast.error(<span><b>Error</b><br />You need to login first.</span>, opt);
                                }
                            }}>
                                {activePage === "/affiliate" ?
                                    <>
                                        <img src="images/affiliate-active.svg" />
                                        <span className="active">
                                            Affiliate
                                        </span>
                                    </>
                                    :
                                    <>
                                        <img src="images/affiliate.svg" />
                                        <span>
                                            Affiliate
                                        </span>
                                    </>
                                }
                            </Link>
                        </div>
                        <div>
                            <Link to="faq">
                                {activePage === "/faq" ?
                                    <>
                                        <img src="images/question-active.svg" />
                                        <span className="active">
                                            FAQ
                                        </span>
                                    </>
                                    :
                                    <>
                                        <img src="images/question.svg" />
                                        <span>
                                            FAQ
                                        </span>
                                    </>
                                }
                            </Link>
                        </div>
                        <div>
                            <Link to="privacy">
                                {activePage === "/privacy" ?
                                    <>
                                        <img src="images/privacy-active.svg" />
                                        <span className="active">
                                            Privacy
                                        </span>
                                    </>
                                    :
                                    <>
                                        <img src="images/privacy.svg" />
                                        <span>
                                            Privacy
                                        </span>
                                    </>
                                }
                            </Link>
                        </div>
                    </div>

                    {user &&
                        <div className="header-balance">
                            <div className="deposit-withdraw-buttons">
                                <Link to="withdraw"><img src="images/minus.svg" /></Link>
                                <Link to="deposit"><img src="images/plus.svg" /></Link>
                            </div>

                            <Currencies user={user} />
                        </div>
                    }
                </div>

                {user &&
                    <>
                        <div className="user-avatar-container">
                            <img className='light' src="images/light-2.png" />
                            <Link to="profile">
                                <img src={user.pfp} />
                            </Link>
                        </div>

                        <div className="logout-container">
                            <a onClick={() => { logout.mutate(); window.location.href = "/"; }}>
                                <img src="images/logout.svg" />
                            </a>
                        </div>
                    </>
                }
                {(!user && !isLoading && dev !== false) &&
                    <>
                        <a onClick={() => setLoginModal(true)} className="sign-in">
                            Sign In
                        </a>
                        {/* Login Modal  */}
                        <Login loginModal={loginModal} setLoginModal={setLoginModal} />
                    </>
                }

                {/* Rakeback Modal */}
                {user && <Rakeback rakebackModal={rakebackModal} setRakebackModal={setRakebackModal} />}
            </div>

            {/* Header for smaller resolution */}
            <HeaderMobile
                setLoginModal={setLoginModal}
                setRakebackModal={setRakebackModal}
                user={user}
                logout={logout}
                dev={dev}
            />
        </header>
    )
}