import { useState, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useUser } from '../../lib/hooks';
import { seperateBalance } from '../../helpers/balance';
import './leaderboard.scss';

export default function Leaderboard({ setLeaderboard, leaderboardModal }) {

    const { data: user } = useUser();

    const [currency, setCurrency] = useState("robux");
    const [counter, setCounter] = useState(1);
    const [datas, setData] = useState({ robux: [], points: [] });
    const [requestedUser, setRequestedUser] = useState(null);

    useEffect(() => {
        let options = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
        };

        if (user) {
            options.body = JSON.stringify({ user: user });
        }

        fetch(process.env.REACT_APP_API_ENDPOINT + "/user/leaderboard", options)
            .then(data => data.json())
            .then(data => {
                if (data?.data) setData(data.data);
                if (data?.user) setRequestedUser(data.user)
            });
    }, [user]);

    const res_980 = useMediaQuery('(max-width:980px)');
    const res_768 = useMediaQuery('(max-width:768px)');

    if (datas?.robux?.length < 3 || datas?.points?.length < 3) return <></>
    return (
        <Modal
            open={leaderboardModal}
            onClose={() => setLeaderboard(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ background: "#13161D8F" }}
        >
            <Box sx={res_768 ? MobileBoxStyle : { ...BoxStyle, ...{ width: res_980 ? "90%" : 870 } }} id="leaderboard">
                <button onClick={() => setLeaderboard(false)} className="close-button"><img src="images/x.svg" /></button>
                <div className='leaderboard-header'>
                    <h1>Leaderboard</h1>
                    {!res_768 &&
                        <div className="currency-selector">
                            <button
                                className={currency === "robux" ? "active" : undefined}
                                onClick={() => setCurrency("robux")}
                            >Robux</button>
                            <button
                                className={currency === "points" ? "active" : undefined}
                                onClick={() => setCurrency("points")}
                            >Points</button>
                        </div>
                    }
                </div>
                {res_768 &&
                    <div className="currency-selector">
                        <button
                            className={currency === "robux" ? "active" : undefined}
                            onClick={() => setCurrency("robux")}
                        >Robux</button>
                        <button
                            className={currency === "points" ? "active" : undefined}
                            onClick={() => setCurrency("points")}
                        >Points</button>
                    </div>
                }
                <div className='leaderboard-ranking'>
                    {res_768 &&
                        <button className='change-counter' onClick={() => {
                            if (counter === 1) setCounter(3);
                            else setCounter(counter - 1)
                        }}>
                            <img src="images/right.svg" style={{ transform: "rotateZ(180deg)" }} />
                        </button>
                    }

                    {currency == "robux" && datas.robux.map((data, i) => {
                        if (!data?.user) return <></>
                        return (
                            <div className={'rank rank-' + (i + 1)} key={i}>
                                <div className="pfp-container">
                                    <div>
                                        <img src={data.user.pfp} />
                                    </div>
                                    <div className="bg-light"></div>
                                    <div className="ranking">
                                        <img src={"images/rank" + (i + 1) + ".svg"} />
                                    </div>
                                </div>
                                <div className='user-info'>
                                    <span className='username'>{data.user.username}</span>
                                    <span>{data.robux.game} x{data.robux.ratio}</span>
                                </div>
                                <div className='win-amount'>
                                    <div>
                                        <img src="images/robux.svg" />
                                        {seperateBalance(data.robux.earn)}
                                        <span className="plus-amount">+{(data.robux.earn - data.robux.bet).toFixed(2)}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                    {currency == "points" && datas.points.map((data, i) => {
                        if (!data?.user) return <></>
                        return (
                            <div className={'rank rank-' + (i + 1)}>
                                <div className="pfp-container">
                                    <div>
                                        <img src={data.user.pfp} />
                                    </div>
                                    <div className="bg-light"></div>
                                    <div className="ranking">
                                        <img src={"images/rank" + (i + 1) + ".svg"} />
                                    </div>
                                </div>
                                <div className='user-info'>
                                    <span className='username'>{data.user.username}</span>
                                    <span>{data.points.game} x{data.points.ratio}</span>
                                </div>
                                <div className='win-amount'>
                                    <div>
                                        <img src="images/points.png" className='points' />
                                        {seperateBalance(data.points.earn)}
                                        <span className="plus-amount">+{(data.points.earn - data.points.bet).toFixed(2)}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                    {res_768 &&
                        <button className='change-counter' onClick={() => {
                            if (counter === 3) setCounter(1);
                            else setCounter(counter + 1)
                        }}>
                            <img src="images/right.svg" />
                        </button>
                    }
                </div>
                <div className='leaderboard-footer'>
                    {requestedUser &&
                        <>
                            <div>
                                <h3>My best win</h3><span>#{currency === "robux" ? requestedUser.robux_ranking : requestedUser.points_ranking}</span>
                            </div>
                            <div>
                                {currency === "robux" ?
                                    <>
                                        <span className="plus-amount">+{(requestedUser.robux.earn - requestedUser.robux.bet).toFixed(2)}</span>
                                        {seperateBalance(requestedUser.robux.earn)}
                                        <img src="images/robux.svg" />
                                    </>
                                    :
                                    <>
                                        <span className="plus-amount">+{(requestedUser.points.earn - requestedUser.points.bet).toFixed(2)}</span>
                                        {seperateBalance(requestedUser.points.earn)}
                                        <img src="images/points.png" className='points' />
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
                {res_768 &&
                    <div className='close'>
                        <button onClick={() => setLeaderboard(false)}>Close</button>
                    </div>
                }
            </Box>
        </Modal>
    )
}

const BoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 450,
    background: "#1E222C",
    border: "2px solid #2E323D",
    borderRadius: "16px",
    padding: "25px",
    outline: "none"
};

const MobileBoxStyle = {
    position: 'fixed !important',
    width: "calc(100vw - 33px)",
    bottom: "0",
    background: "#1E222C",
    borderRadius: "18px 18px 0 0",
    outline: "none"
}