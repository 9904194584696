export default async function getLocalization(pages) {
    return new Promise((res, rej) => {
        fetch(process.env.REACT_APP_API_ENDPOINT + "/localization/get-page", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                pages: pages
            })
        })
        .then(data => data.json())
        .then(data => {
            if (data?.data) return res(data.data);
            else return rej();
        });
    })
}