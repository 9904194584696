import { createSlice } from "@reduxjs/toolkit";

let roomFlagList = {
    "en": "images/flags/england.svg",
    //"fr": "images/flags/france.svg",
    //"jp": "images/flags/japan.svg",
    "ru": "images/flags/russian.png",
    "mx": "images/flags/mexico.svg",
}

export const languageSlice = createSlice({
    name: "lang",
    initialState: { lang: "en", flags: roomFlagList },
    reducers: {
        setLang: (state, action) => {
            let to = action.payload;
            if (!Object.keys(roomFlagList).includes(to)) to = "en";
            state.lang = to;
            return state;
        },
    },
});

export const { setLang } = languageSlice.actions;
export default languageSlice.reducer;
