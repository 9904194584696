import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import useMediaQuery from '@mui/material/useMediaQuery';
import socket from '../../socket';
import { useUser } from '../../lib/hooks';
import { seperateBalance } from '../../helpers/balance';
import './roulette.scss';

const opt = { hideProgressBar: true, theme: "dark", autoClose: 2000 };

export default function Roulette({chatVisible}) {

    const { data: user, refetch } = useUser();

    // Media Queries for responsive design
    const res_1450 = useMediaQuery('(max-width:1450px)');
    const res_950 = useMediaQuery('(max-width:950px)');
    const res_768 = useMediaQuery('(max-width:768px)');
    const res_550 = useMediaQuery('(max-width:550px)');

    const [oldGames, setOldGames] = useState([]);
    const [players, setPlayers] = useState([]);
    const [winners, setWinners] = useState([]);
    
    // Bet Input Related
    const [betAmountInput, setBetAmountInput] = useState("");
    const [multiplierInput, setMultiplierInput] = useState(2);
    const [selectedCurrency, setSelectedCurrency] = useState("robux");
    const [currencyFilter, setCurrencyFilter] = useState("all");
    const [totalRobuxBet, setTotalRobuxBet] = useState(0);
    const [totalPointsBet, setTotalPointsBet] = useState(0);
    const [totalEarning, setTotalEarning] = useState(0);
    // const [totalWagerRaw, setTotalWagerRaw] = useState(0);
    const [totalWager, setTotalWager] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [betPlaced, setBetPlaced] = useState(false);
    const [betLoop, setBetLoop] = useState(false);
    const [lastBet, setLastBet] = useState({ });
    // const [betLoopActive, setBetLoopActive] = useState(false);
    const [percentageStatus, setPercentageStatus] = useState(null);
    const [userBetUpdated, setUserBetUpdated] = useState(false);

    // Game Related
    const [timer, setTimer] = useState(0);
    const [gameState, setGameState] = useState(0);
    const [multipliers, setMultipliers] = useState([]);
    const [force, setForce] = useState(0);
    const [finalMultiplier, setFinalMultiplier] = useState(0);

    // Cases
    const [caseWidth, setCaseWidth] = useState(200);
    const [canSpin, setCanSpin] = useState(false);
    const [randomOffset, setRandomOffset] = useState(0);

    //Currency dropdown
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    useEffect(() => {
        socket.emit("get-roulette", {});

        socket.on("roulette-data", data => {
            setGameState(data.state);
            setOldGames(data.oldGames);
            setMultipliers(data.multipliers);
            socket.off("roulette-data");
        });

        socket.on("roulette-timer", data => {
            setPlayers(data.attenders);
            setTimer(Math.abs(data.timer));
            if (data.timer <= 0) setGameState(1);
            else {
                setGameState(0);
                setWinners([]);
                setFinalMultiplier(0);
            }
        });

        socket.on("roulette-spin", data => {
            setGameState(1);
            setRandomOffset(Math.floor(Math.random() * (caseWidth + 1)));
            setMultipliers([...data.multipliers, ...data.multipliers]);
            setForce(data.force);
            setCanSpin(true);
        });

        socket.on("roulette-winners", data => {
            let winners_raw = data.winners;
            let final = data.multipliers[data.force];

            setGameState(2);
            setWinners(winners_raw);
            setFinalMultiplier(final);

            let totalRobuxBet_raw = 0,
                totalPointsBet_raw = 0;

                winners_raw.forEach(w => {
                if (w.bet.type == "robux") totalRobuxBet_raw += w.bet.amount;
                else totalPointsBet_raw += w.bet.amount;
            })

            setTotalRobuxBet(totalRobuxBet_raw);
            setTotalPointsBet(totalPointsBet_raw);
        });
    }, []);

    useEffect(() => {
        if (user) {
            // After attend
            socket.on("attend-response", data => {
                if (data.success) {
                    toast.success(<span><b>Success</b><br/>Your bet is successfully placed.</span>, {...opt, toastId: 'success-toast'});

                    refetch();
                    setBetLoop(true);
                    setBetPlaced(true);
                    setUserBetUpdated(false);
                }
                else toast.error(<span><b>Error</b><br/>{data.message}</span>, {...opt, toastId: 'fail-toast',});
            });
        }
    }, [user]);

    useEffect(() => {
        if (user) {
            let userBet = winners.filter(w => w.username == user?.username);
            let didUserWin = userBet.length;

            let totalEarning_raw = totalEarning;

            // User won
            if (didUserWin && !userBetUpdated) {
                for (let i = 0; i < winners.length; i++) {
                    userBet = winners[i];
    
                    totalEarning_raw += Number(userBet.bet.amount * userBet.multiplier);
                    setTotalEarning(totalEarning_raw);
                    // setPercentage(((totalEarning_raw - totalWager) / (totalWager || 1) * 100) || 0);
                }

                setUserBetUpdated(true);
                setPercentageStatus("win");
                refetch();
            }
            else if (betPlaced && gameState == 2) setPercentageStatus("lose");

            if (betPlaced && gameState == 2) {
                let totalWagerRaw = totalWager;

                players.forEach(p => {
                    if (p.username == user?.username) totalWagerRaw += p.bet.amount;
                })

                setTotalWager(totalWagerRaw);
                // setPercentage(((totalEarning_raw - totalWagerRaw) / (totalWagerRaw || 1) * 100) || 0);
                setBetPlaced(false);
            }
        }

        if (gameState == 2) {
            setTimeout(() => {
                setPlayers([]);
                setOldGames([finalMultiplier, ...oldGames].slice(0, 25));
            }, 2000);
        }
        else if (gameState == 0) setPercentageStatus(null);
    }, [gameState, user]);

    useEffect(() => {
        socket.emit("get-percentage", { multiplier: multiplierInput });
        socket.on("percentage", datas => {
            setPercentage(datas.percentage);
            socket.off("percentage");
        });
    }, [multiplierInput, betAmountInput]);

    useEffect(() => {
        if (res_768) setCaseWidth(130);
        else setCaseWidth(200);
    }, [res_768]);

    function placeBet() {
        if (user && gameState === 0) {
            if (betAmountInput < 1) {
                toast.error(<span><b>Error</b><br/>Bet amount must be greater than 1</span>, opt);
                return;
            }

            if (multiplierInput < 1.01 || multiplierInput > 1_000_000) {
                toast.error(<span><b>Error</b><br/>Multiplier must between 1.01 and 1,000,000</span>, opt);
                return;
            }
            
            setLastBet({ bet: betAmountInput, multiplier: multiplierInput });
            socket.emit("roulette-attend", {
                user_id: user.id,
                user_sid: user._id,
                multiplier: multiplierInput,
                bet: {
                    amount: betAmountInput,
                    type: selectedCurrency
                }
            });
        }
        
        else toast.error(<span><b>Error</b><br/>You have to login to play Crash</span>, opt);
    }
    
    return (
        <div id="roulette">
            {/* Game History */}
            <div className="past-games">
                {oldGames.map((ratio, index) => {
                    return <div key={index} className={ratio >= 2 ? "win" : "not-win"}>x {ratio?.toFixed(2)}</div>
                })}
            </div>

            {/* Game Screen */}
            <div id="game">
                { gameState != 0 && 
                    <img
                        className="line"
                        src="/images/cases-line.svg"
                    />
                }

                <div
                    className="cases"
                    style={
                        {
                            transform: (gameState != 0 && canSpin) ? 
                                "translateX(calc(-"
                                    + ((force * caseWidth) + (randomOffset))
                                    + "px + (15px + 100vw / 2 - "
                                    + ((chatVisible && !res_1450) ? "320px" : res_768 ? "-30px" : "95px")
                                    + " - 50px)))" 
                                : "translateX(0)",
                            opacity: (gameState == 0 || (gameState != 0 && !canSpin)) ? 0.2 : 1,
                            transitionDuration: gameState == 1 ? "6s" : "0ms",
                        }
                    }
                >
                    { multipliers.map((m, i) => {

                        let casePath = "/images/cases/";
                        let color = "#";

                        if (m >= 1 && m < 2) {
                            casePath += "1-2";
                            color += "7D8496";
                        } else if (m >= 2 && m < 5) {
                            casePath += "2-5";
                            color += "D7492C";
                        } else if (m >= 5 && m < 20) {
                            casePath += "5-20";
                            color += "EB991E";
                        } else if (m >= 20 && m < 100) {
                            casePath += "20-100";
                            color += "9CBD17"
                        } else {
                            casePath += "100+";
                            color += "485BDF"
                        }
                        
                        casePath += ".svg";

                        return (
                            <div
                                key={i}
                                className="case"
                            >
                                <img
                                    src={casePath}
                                    className={((gameState == 2 && i != force) ? "gray" : undefined)}
                                    style={{ height: caseWidth, width: caseWidth }}
                                />
                                <span style={{ color }}>{m.toFixed(2)}</span>
                            </div>
                        )
                    })}
                </div>

                { gameState == 0 && (
                    <div className="timer">
                        <span>Next round in {(timer || 0).toFixed(1)}s</span>
                    </div>
                )}

                { (gameState != 0 && !canSpin) && (
                    <div className="waiting">
                        <span>Waiting for the next round...</span>
                    </div>
                )}
            </div>
            
            <div className={'place-bet ' + ((chatVisible) ? "chat-visible " : "") + ((res_950) ? "small" : "")}>
                <div className="amount-container" style={open ? {transition: "100ms", borderRadius: "1000px 1000px 1000px 0"} : {borderRadius: "1000px"}}>
                    <div className="currency-selection" style={open ? {backgroundColor: "#2A2F3D", borderRadius: "35px 0 0 0"} : {}}>
                        <Button
                            id="currency-button"
                            className={open ? "active" : undefined}
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            style={{ backgroundColor: 'transparent' }} 
                            onClick={(e) => {if (gameState !== 1) { handleClick(e) }}}
                        >
                            { selectedCurrency === "robux"
                                ? <>
                                { gameState === 1 
                                    ? <img src="images/robux-gray-3.svg" className='robux' />
                                    : <img src="images/robux.svg" className='robux' />
                                }
                                </>
                                : <img src="images/points.png" className='points' />
                            }
                            { open ? (
                                <img className="down" src="images/down-white.svg" style={open ? {transform: "rotateZ(180deg)"} : {}} />
                            ) : (
                                <img className="down" src="images/down.svg" style={open ? {transform: "rotateZ(180deg)"} : {}} />
                            )}
                        </Button>
                        <Menu
                            id="currency-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{'aria-labelledby': 'currency-button'}}
                            style={{marginTop: selectedCurrency === "points" ? "-10px" : "5px"}}
                        >
                            {selectedCurrency === "robux" ? (
                                <MenuItem
                                    className="currency-menu-item" 
                                    onClick={() => { setSelectedCurrency("points"); handleClose(); }}
                                >
                                    <img src="images/points.png" className='points' />
                                </MenuItem>
                            ) : (
                                <MenuItem
                                    className="currency-menu-item" 
                                    onClick={() => { setSelectedCurrency("robux"); handleClose(); }}
                                >
                                    <img src="images/robux.svg" />
                                </MenuItem>
                            )}
                        </Menu>
                    </div>
                    <div className="enter-bet-amount">
                        <input
                            type="number"
                            placeholder='0.00'
                            value={betAmountInput}
                            onChange={(e) => setBetAmountInput(parseFloat(parseFloat(e.target.value).toFixed(2)))}
                        />
                    </div>
                    <div className="controllers">
                        <button onClick={() => setBetAmountInput(1)}>Min</button>
                        <button onClick={() => setBetAmountInput(parseFloat(parseFloat(betAmountInput / 2).toFixed(2)))}>1/2</button>
                        <button onClick={() => setBetAmountInput(parseFloat(parseFloat(user?.balance?.[selectedCurrency] || 9999).toFixed(2)))}>Max</button>
                    </div>
                </div>

                <div className="multiplier-container">
                    <div className="multiplier">
                        <div>
                            <span>Multiplier</span>
                            <input
                                type="number"
                                placeholder='2'
                                value={multiplierInput}
                                onChange={(e) => setMultiplierInput(parseFloat(parseFloat(e.target.value).toFixed(2)))}
                            />
                        </div>
                        <div className='controllers'>
                            <button onClick={() => setMultiplierInput(1.01)}>Min</button>
                            <button onClick={() => setMultiplierInput(2)}>x2</button>
                            <button onClick={() => setMultiplierInput(4)}>x4</button>
                        </div>
                    </div>

                    {/* Profit */}
                    <div className="profit">
                        <div className="image">
                            <img src="/images/robux-gray-2.svg" />
                        </div>
                        <div className="value">
                            <p>Total Profit</p>
                            {/* <span>{seperateBalance((betAmountInput || 0) * (multiplierInput || 0) - (betAmountInput || 0))}</span> */}
                            <span>{seperateBalance(Math.min((betAmountInput || 0) * (multiplierInput || 0), 1_000_000))}</span>
                        </div>
                        <div className="percent">
                            <span style={percentageStatus == "win" ? { color: "#039700" } : percentageStatus == "lose" ? { color: "#C11B1B" } : undefined}>{(percentage || 0).toFixed(2)}%</span>
                        </div>
                    </div>

                    <div className="buttons">
                        <div>
                            <PopupState variant="popover">
                                {(popupState) => (
                                <>
                                    <div
                                        className={"info-box " + (popupState?.isOpen ? "active" : "")}
                                        variant="contained"
                                        {...bindTrigger(popupState)}
                                    >
                                        <img src={popupState?.isOpen ? "images/question-white.svg" : "images/question.svg"} />
                                    </div>
                                    <Popover
                                        {...bindPopover(popupState)}
                                        className="header-info-popover"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                backgroundColor:"#1E222C",
                                                minHeight: "40px",
                                                padding: "20px",
                                                border: "1px solid #2E323D",
                                                boxShadow: "0px 24px 48px rgba(19, 22, 29, 0.24)",
                                                display: "flex",
                                                flexDirection: "column",
                                                borderRadius: "16px 0 16px 16px",
                                                width: res_550 ? "calc(100% - 30px)" : "450px"
                                            }}
                                        >
                                            <span 
                                                style={{
                                                    color: "white",
                                                    fontSize: "18px",
                                                    fontWeight: "500",
                                                    marginBottom: "10px"
                                                }}
                                            >
                                                What is Roulette?
                                            </span>
                                            <span
                                                style={{
                                                    color:"#7D8496",
                                                    fontSize:"17px",
                                                    marginBottom: "15px"
                                                }}
                                            >
                                                Hop aboard and ride the waves of excitement on Roulette. Here, players have the chance to select a multiplier and earn amazing rewards if you make the right guess, dive into the fun with us!
                                            </span>
                                        </Typography>
                                    </Popover>
                                </>
                                )}
                            </PopupState>
                        </div>

                        {/* Bet Loop */}
                        {/*<div
                            className={"loop " + (betLoopActive ? "bet-loop" : gameState == 0 ? "" : "inactive")}
                            onClick={() => {
                                if (gameState == 0) {
                                    setBetLoopActive(!betLoopActive);
                                    if (betLoopActive) toast.success("Bet loop is deactivated.", opt);
                                    else toast.success("Bet loop is activated, place a bet and it'll be played automatically in next rounds.", opt);
                                }
                                else setBetLoopActive(false);
                            }}
                        >
                            <img src={ betLoopActive ? "/images/reload-white.svg" : gameState == 0 ? "/images/reload.svg" : "/images/reload-inactive.svg"} />
                        </div>*/}
                        <div
                            className={"loop " + ((gameState == 0 && lastBet?.bet && lastBet?.multiplier) ? "" : "inactive")}
                            onClick={() => {
                                if (lastBet?.bet && lastBet?.multiplier && gameState == 0) {
                                    setBetAmountInput(lastBet.bet);
                                    setMultiplierInput(lastBet.multiplier);
                                    placeBet();
                                }
                            }}
                        >
                            <img src={ gameState == 0 ? "/images/reload.svg" : "/images/reload-inactive.svg"} />
                        </div>

                        <div className='button-box'>
                            <button 
                                onClick={() => {
                                    if (gameState == 0) placeBet();
                                }}
                                className={gameState !== 0 ? "inactive" : undefined}
                            >
                                { (gameState === 0) ? "Place a bet" : "Waiting..." }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <hr style={{border: "1px solid #2E323D", margin: "0 24px"}} />
            <div className='bets'>
                <div className="bets-header">
                    <div className='title'>
                        <h2>All bets</h2>
                        <span>{players?.length} players</span>
                    </div>
                    <div className="currency-filter">
                        <div
                            onClick={() => {
                                if (currencyFilter === "points" || currencyFilter === "all") setCurrencyFilter("robux");
                                else setCurrencyFilter("all");
                            }}
                            className={(currencyFilter === "robux" || currencyFilter === "all") ? undefined : "inactive"}
                        >
                            <img src="images/robux.svg" />
                            {seperateBalance(totalRobuxBet)}
                        </div>
                        <div
                            onClick={() => {
                                if (currencyFilter === "robux" || currencyFilter === "all") setCurrencyFilter("points");
                                else setCurrencyFilter("all");
                            }}
                            className={(currencyFilter === "points" || currencyFilter === "all") ? undefined : "inactive"}
                        >
                            <img src="images/points.png" className='points' />
                            {seperateBalance(totalPointsBet)}
                        </div>
                    </div>
                </div>
                <div className="bets-body">
                    {players.map((d, i) => {
                        if (currencyFilter === d?.bet?.type || currencyFilter === "all") {
                            return (
                                <div className='bet' key={i}>
                                    <div>
                                        <img className='pfp' src={d.pfp} />
                                        <span className='username'>{d.username}</span>
                                        {d.username === user?.username && <span className='me'>Me</span>}
                                    </div>
                                    <div className="multiplier">
                                        <div className={gameState != 2 ? undefined : d.multiplier > finalMultiplier ? "lose" : "win"}>x{(Math.abs(d.multiplier)).toFixed(2)}</div>
                                    </div>
                                    <div>
                                        {(d.multiplier > finalMultiplier || gameState != 2) ? (
                                        <>
                                            {seperateBalance(d.bet.amount)}
                                            {d.bet.type === "robux" ? <img src="images/robux.svg" /> : <img src="images/points.png" className='points' />}
                                        </>
                                        ) : (
                                        <>
                                            <span className='addup-value'>+{(parseFloat(d.bet.amount * d.multiplier) - parseFloat(d.bet.amount)).toFixed(2)}</span>
                                            {seperateBalance(d.bet.amount * d.multiplier)}
                                            {d.bet.type === "robux" ? <img src="images/robux.svg" /> : <img src="images/points.png" className='points' />}
                                        </>
                                        )}
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        </div>
    )
}