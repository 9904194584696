import { useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Item from '../../components/item';
import { useUser } from '../../lib/hooks';
import { seperateBalance } from '../../helpers/balance';
import '../../assets/css/items.scss';

const opt = { hideProgressBar: true, theme: "dark", autoClose: 2000 };

export default function ItemsWithdraw({chatVisible}) {

    const { data: user } = useUser();

    const [items, setItems] = useState([
        {
            image: "images/example-item.png",
            name: "Ginger Cat",
            price: 2380,
            rarity: "red"
        },
        {
            image: "images/example-item.png",
            name: "Ginger Cat",
            price: 2380,
            rarity: "purple"
        },
        {
            image: "images/example-item.png",
            name: "Ginger Cat",
            price: 2380,
            rarity: "green"
        },
        {
            image: "images/example-item.png",
            name: "Ginger Cat",
            price: 2380,
            rarity: "red"
        },
        {
            image: "images/example-item.png",
            name: "Ginger Cat",
            price: 2380,
            rarity: "green"
        },
        {
            image: "images/example-item.png",
            name: "Ginger Cat",
            price: 2380,
            rarity: "red"
        },
        {
            image: "images/example-item.png",
            name: "Ginger Cat",
            price: 2380,
            rarity: "purple"
        },
    ]);

    const [selectedItems, setSelected] = useState([]);
    const [showWithdrawModal, setShowWithdrawModal] = useState(false);

    function Continue() {
        let lim = user["statistics"]?.["deposit"]?.["robux"] + (user["statistics"]?.["riskWager"]?.["robux"] || 0);
        if (lim >= 100) {
            setShowWithdrawModal(true);
        } else {
            toast.error(<span><b>Error</b><br/>You should deposit or wager <div className="amount">{seperateBalance(100 - lim)}<img src="images/robux.svg" /></div> more with a risk factor greater than 1.5x to continue withdraw.</span>, opt);
        }
    }
        
    return (
        <div className="items-container">
            <div className='header'>
                <div>
                    <h1>Items withdraw</h1>
                    <span className='tag'>Tower of Hell</span>
                </div>
                <Link to="/faq"><img src="images/question-active.svg" />How it works</Link>
            </div>
            <div className="search-container">
                <div className='search'>
                    <img className='icon' src="images/search.svg" />
                    <input placeholder="Search for items..." />
                </div>
                <div>
                    <div className="sort">
                        <span>High to low</span>
                        <img src="images/down-white.svg" />
                    </div>
                    <div className='reload' onClick={() => setSelected([])}>
                        <img src="images/reload-white.svg" />
                    </div>
                </div>
            </div>
            <div className="items-header">
                <h2>My inventory</h2>
                <span>24 items</span>
            </div>
            <div className='items'>
                { items.map((item, index) => {
                    return (
                        <Item 
                            image={item.image} 
                            name={item.name} 
                            price={item.price} 
                            rarity={item.rarity} 
                            setSelected={setSelected}
                            selectedItems={selectedItems}
                            key={index}
                            index={index}
                        />
                    )
                })}
            </div>
            { selectedItems.length > 0 &&
                <div className="items-footer" style={{width: chatVisible ? "calc(100% - 651px)" : "calc(100% - 200px)"}}>
                    <div className="receive-amount">
                        You will receive <div className="price">
                            <span>2 380<span className='float'>.00</span></span>
                            <img src="images/points.png" className="points" />
                        </div>
                    </div>
                    <div className="buttons">
                        <button onClick={() => setSelected([])}>Clear</button>
                        <button className='active' onClick={Continue}>Continue</button>
                    </div>
                </div>
            }

            <Modal
                open={showWithdrawModal}
                onClose={() => setShowWithdrawModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{background: "#13161D8F"}}
            >
                <Box sx={BoxStyle}>
                    <div className="confirm-modal deposit-modal">
                        <button onClick={() => setShowWithdrawModal(false)} className="close-button"><img src="images/x.svg" /></button>
                        <h1>Are you sure?</h1>
                        <p>
                            Buy {selectedItems.length} items for 
                            <div className="price">
                                <img src="images/points.png" className="points" />
                                <span>2 380<span className='float'>.00</span></span>
                            </div>
                        </p>
                        <div className="buttons">
                            <button onClick={() => setShowWithdrawModal(false)}>Cancel</button>
                            <button className='active' onClick={() => { setShowWithdrawModal(false) }}>Continue</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

const BoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width:600,
    height:220,
    background: "#1E222C",
    border: "2px solid #2E323D",
    borderRadius:"16px",
    padding: "25px 40px 50px 40px",
    outline: "none"
};