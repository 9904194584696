import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from './lib/hooks';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useMediaQuery } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { useUser } from './lib/hooks';
import { setLang } from './store/reducers/languageReducer';
import socket from './socket';
import Cookie from './helpers/cookie';

//Game Imports
import Crash from './pages/crash';
import Mines from './pages/mines';
import Roulette from './pages/roulette';

//User-related Pages
import Affiliate from './pages/affiliate';
import Deposit from './pages/deposit';
import Profile from './pages/profile';
import Withdraw from './pages/withdraw';

//Confirmation pages
import Confirmation from './pages/confirmation';
import Failed from './pages/failed';

//Other Pages
import FAQ from './pages/faq';
import Authorize from './pages/authorize';

//Components
import BottomNav from './components/bottomnav';
import Header from './components/header';
import LeftNav from './components/leftnav';
import RightNav from './components/rightnav';

import 'react-toastify/dist/ReactToastify.css';
import './assets/css/main.scss';

let tempClientID = uuid();
const cookie = new Cookie();

export default function App() {

	const dispatch = useDispatch();
	const location = useLocation();
	const queryClient = useQueryClient();
	const { data: user, isLoading } = useUser();
	const res_768 = useMediaQuery('(max-width:768px)');

	const logoutFn = () => {
        toast('Logging out...', {
            theme: 'dark',
			hideProgressBar: true,
        });
        return instance
            .post(
                '/auth/logout', {},
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_tkn')}`,
                    },
                }
            )
    };

    const logout = useMutation({
        mutationFn: () => logoutFn,
        onSuccess: () => {
            localStorage.removeItem('access_tkn');
            queryClient.invalidateQueries({ queryKey: ['user'] }).then(() => {
				toast.dismiss();
                toast.success("Logged out successfully", {
                    theme: 'dark',
					hideProgressBar: true,
                });
            });
        },
        onError: (err) => {
			toast.dismiss();
            toast.error("Something went wrong", {
				theme: 'dark',
				hideProgressBar: true,
			});
        },
    });

	const [chatVisible, setChatVisible] = useState(true);
	useEffect(() => {
		//Set affiliate cookie if user visits website with someone elses link
		let url = new URL(window.location.href);
		let searchParams = new URLSearchParams(url.search);
		let ref_raw = searchParams.get("ref");
		if (ref_raw) cookie.setCookie("ref", ref_raw, 3);

		//Right Nav visibility
		let localExpandedData = localStorage.getItem("right-menu-expanded");
		if (localExpandedData === null) localExpandedData = !res_768; //Make it active on desktop as default
		else localExpandedData = Boolean(parseInt(localExpandedData));
		setChatVisible(localExpandedData);

		//Set Language
		let localLang = localStorage.getItem("language");
		if (localLang) dispatch(setLang(localLang));
	}, [location]);

	// A global definition to collect all datas to a user-specified socket connection
	const [socketCounter, setSocketCounter] = useState(0); //Keep track of how many data received from Socket client
	useEffect(() => {
		let opt = { hideProgressBar: true, theme: "dark" };
		if (user) {
			socket.on("m-"+user.id, data => {
				toast.dismiss();
				if (data?.err) toast.error(<span><b>Error</b><br/>{data.err}</span>, opt);
				else if (data?.success) toast.success(<span><b>Success</b><br/>{data.success}</span>, opt);
				else if (data?.warning) toast.warning(<span><b>Warning</b><br/>{data.warning}</span>, {...opt, autoClose: false});
				else if (data?.forceLogout) logout.mutate();

				setSocketCounter(socketCounter + 1);
			});

			if (!cookie.getCookie("hide-welcome")) {
				toast.info(
					<span>
						<b>Hi, {user.username}</b><br/>
						We are glad to see you again on our website
					</span>, { ...opt, autoClose: 1000 }
				);
				cookie.setCookie("hide-welcome", true, 1/24);
			}
		}
	}, [user]);

	//Development Environment
	const [developer, setDeveloper] = useState(false);
	useEffect(() => {
		if (process.env.REACT_APP_ENV === "development") {
			if (cookie.getCookie("dev-key")) {
				fetch(process.env.REACT_APP_API_ENDPOINT + "/auth/check-developer", {
					method: "POST",
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						key: cookie.getCookie("dev-key"),
					})
				})
				.then(data => data.json())
				.then(data => {
					if (data.response === 1) setDeveloper(true);
				});
			}
		} else setDeveloper(null);
	}, []);

	return (
		<>
			<Header activePage={location.pathname} logout={logout} dev={developer} />
			<div id="main">
				<LeftNav path={location.pathname} dev={developer} />
				<main className={(location.pathname === "/crash" || location.pathname === "/") ? "no-bg" : undefined}>
					{/*(location.pathname === "/crash" || location.pathname === "/") && <div className="crash-bg" ></div>*/}
					{ developer === false ? (
						<Routes>
							<Route path="*" element={<Authorize setDeveloper={setDeveloper} />} />
						</Routes>
					) : (
						<Routes>
							<Route path="/" element={<Crash banner={/*!cookie.getCookie("no-banner")*/ false} chatVisible={chatVisible} />} />
							<Route path="/faq" element={<FAQ />} />
							<Route path="/privacy" element={<FAQ page="privacy" />} />

							{/* Game Modes */}
							<Route path="/crash" element={<Crash banner={/*!cookie.getCookie("no-banner")*/ false} chatVisible={chatVisible} />} />
							<Route path="/mines" element={<Mines />} />
							<Route path="/roulette" element={<Roulette chatVisible={chatVisible} />} />

							{/* Confirmation pages */}
							<Route path="/confirmation" element={<Confirmation />} />
							<Route path="/failed" element={<Failed />} />

							{/* Profile Pages */}
							{ (user && !isLoading) &&
							<>
								<Route path="/affiliate" element={<Affiliate />} />
								<Route path="/profile" element={<Profile />} />
								<Route path="/deposit" element={<Deposit chatVisible={chatVisible} />} />
								<Route path="/withdraw" element={<Withdraw chatVisible={chatVisible} />} />
							</>
							}
							<Route path="*" element={<Crash chatVisible={chatVisible} />} />
						</Routes>
					)}
				</main>
				<RightNav socketCounter={socketCounter} tempID={tempClientID} chatVisible={chatVisible} setChatVisible={setChatVisible} dev={developer} />

				{/* Mobile-Only Components */}
				<BottomNav path={location.pathname} setChatVisible={setChatVisible} />
			</div>
		</>
	);
}
