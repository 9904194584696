import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import getLocalization from '../../helpers/localization';
import './faq.scss';
import './privacy.scss';

export default function FAQ({ page="faq" }) {

    const lang = useSelector(state => state.lang).lang;
    const [expanded, setExpanded] = useState({});
    const [localization, setLocalization] = useState({});

    useEffect(() => {
        if (page)
            document.querySelector("main").scrollTo({ behavior: "smooth", top: document.getElementById(page).getBoundingClientRect().top - 100 });
    }, [page]);

    useEffect(() => {
        getLocalization(["faq"]).then((d) => setLocalization(d["faq"]?.[lang]));
    }, [lang]);

    function handleChange(panelName) {
        let expanded_ = { ...expanded }
        if (!expanded_[panelName]) expanded_[panelName] = true;
        else expanded_[panelName] = !expanded_[panelName];
        setExpanded(expanded_);
    }

    return (
        <>
            <div id="faq">
                <div className="faq-header">
                    <h1>FAQ</h1>
                    <a target="_blank" href="https://discord.gg/dxucmhMSMv">
                        <img src="images/support-active.svg" />{localization?.["contact"]}
                    </a>
                </div>
                <div className="faq-search">
                    <p>{localization?.["description"]}</p>
                    <img className='icon' src="images/search.svg" />
                    <input placeholder="What are we looking for?" />
                </div>
                <div className="faq-content">
                    {[1, 2, 3, 4, 5].map((n, i) => {
                        return (
                            <Accordion key={i} sx={{ color: "#7D8496", borderBottom: "1px solid #7D8496" }} onChange={() => handleChange("panel" + n)}>
                                <AccordionSummary
                                    expandIcon={expanded["panel1"] ? <RemoveIcon sx={{ color: "#7D8496" }} /> : <AddIcon sx={{ color: "#7D8496" }} />}
                                    aria-controls={"panel"+n+"a-content"}
                                    id={"panel"+n+"a-header"}
                                >
                                    <Typography className="accordion-title">{localization?.["title-" + n]}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accordion-text">
                                        <p>{localization?.["item-" + n]}</p>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                    <br /><br />
                </div>
            </div>

            <div id="privacy">
                <h1>User agreement</h1>
                <p>The fish text website will help a designer, a layout designer, a webmaster to generate several paragraphs of more or less meaningful fish text in Russian, and a novice speaker to hone the skill of public speaking at home. When creating the generator, we used a well-known universal speech code. The text is generated by paragraphs randomly from two to ten sentences per paragraph, which makes the text more attractive and lively for visual and auditory perception.</p>
                <h2>TERMS OF PARTICIPATION</h2>
                <div>
                    <h3>1. TERMS AND DEFINITIONS</h3>
                    <p>The fish text website will help a designer, a layout designer, a webmaster to generate several paragraphs of more or less meaningful fish text in Russian, and a novice speaker to hone the skill of public speaking at home. When creating the generator, we used a well-known universal speech code.</p>
                </div>
                <div>
                    <h3>2. SUBJECT OF THE AGREEMENT</h3>
                    <p>The fish text website will help a designer, a layout designer, a webmaster to generate several paragraphs of more or less meaningful fish text in Russian, and a novice speaker to hone the skill of public speaking at home. When creating the generator, we used a well-known universal speech code. The text is generated by paragraphs randomly from two to ten sentences per paragraph, which makes the text more attractive and lively for visual and auditory perception.</p>
                </div>
                <div>
                    <h3>3. CONCLUSION, MODIFICATION, TERMINATION OF THE AGREEMENT</h3>
                    <p>The fish text website will help a designer, a layout designer, a webmaster to generate several paragraphs of more or less meaningful fish text in Russian, and a novice speaker to hone the skill of public speaking at home.</p>
                </div>
                <div>
                    <h3>4. POLICY TEARMS</h3>
                    <p>The fish text website will help a designer, a layout designer, a webmaster to generate several paragraphs of more or less meaningful fish text in Russian, and a novice speaker to hone the skill of public speaking at home. When creating the generator, we used a well-known universal speech code.</p>
                </div>
                <br /><br />
            </div>
        </>
    )
}