import { useMediaQuery } from '@mui/material';
import Popover from '@mui/material/Popover';
import $ from 'jquery';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import socket from '../../socket';
import { seperateBalance } from '../../helpers/balance';
import { useUser } from '../../lib/hooks';
import Chat from '../chat';
import './rightnav.scss';

let localExpandedData = localStorage.getItem("right-menu-expanded");
if (localExpandedData === null) localExpandedData = true;
else localExpandedData = Boolean(parseInt(localExpandedData));

function LanguageDropdown({ bottom = false, room, setRoom }) {

    let flags = useSelector(state => state.lang).flags;

    return (
        <div className="language-selection">
            <PopupState variant="popover">
                {(popupState) => (
                    <>
                        <a
                            variant="contained"
                            {...bindTrigger(popupState)}
                        >
                            <img src={flags[room]} />
                        </a>
                        <Popover
                            {...bindPopover(popupState)}
                            className="right-nav-language-popup"
                            anchorOrigin={{
                                vertical: bottom ? 'top' : 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: bottom ? 'bottom' : 'top',
                                horizontal: 'center',
                            }}
                        >
                            <div
                                style={{
                                    backgroundColor:"#1E222C",
                                    display: "flex",
                                    flexDirection: "column",
                                    borderRadius: bottom ? "16px 16px 0 0" : "0px 0 16px 16px",
                                    border: "1px solid #2E323D",
                                    width: "100px",
                                }}
                            >
                                <div className="language-menu right-nav-lang">
                                    { Object.keys(flags).map((key, i) => {
                                        if (key !== room) {
                                            return (
                                                <div className='item' key={key}>
                                                    <a onClick={() => {
                                                        setRoom(key);
                                                        popupState.setOpen(false);
                                                    }}>
                                                        <img src={flags[key]} />
                                                    </a>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        </Popover>
                    </>
                )}
            </PopupState>
        </div>
    )
}

function RightNavExpanded({ shrink, style, onlineUsers, rainStarted, rainTimer, rainPrize, joinedRain, setJoinedRain, setTip, user, tempID, socketCounter, dev }) {

    const [tipModal, setTipModal] = useState(false);
    const [tipAmount, setTipAmount] = useState(0);

    const [room, setRoom] = useState(localStorage.getItem("language") || "en");

    const res_768 = useMediaQuery('(max-width:768px)');

    return (
        <>
        {res_768 && <div className='background-shadow'></div>}
        <div id="right-nav" className={"expanded " + (res_768 ? "mobile" : "")} style={style}>
            <div className="right-nav-header">
                <div className="close-nav">
                    <button onClick={shrink}><img src="images/right.svg" /></button>
                    <p>Online chat</p>
                    <div className="online-count">
                        <span>{dev !== false ? onlineUsers : 0}</span>
                        <div className="flick-effect">
                            {/* Empty divs for animating flick effect in CSS */}
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
                { res_768 ? (
                    <button onClick={shrink} className="close-button"><img src="images/x.svg" /></button>
                ) : (
                    <LanguageDropdown room={room} setRoom={setRoom} />
                )}
            </div>
            <div 
                onClick={() => {
                    if (dev !== false) {
                        if (!rainStarted && user) setTipModal(true);
                        else if (rainStarted && user) {
                            if (joinedRain) setTipModal(true);
                            else setJoinedRain(true);
                        } else if (!user) {
                            toast.dismiss();
                            toast.error(<span><b>Error</b><br/>You have to login to append rain event.</span>,
                            {
                                hideProgressBar: true,
                                theme: "dark"
                            });
                        }
                    }
                }}
                className={"rain-container " + (rainStarted ? "rain-active" : "")}
            >
                <div className="rain-info">
                    <img src={rainStarted ? "images/rain-white.svg" : "images/rain-active.svg"} />
                    <span>Rain</span>
                    <div className="rain-timer">
                        <span>{new Date(rainTimer * 1000).toISOString().slice(11, 19) /* HH:MM:SS */}</span>
                    </div>
                    {joinedRain && <div className="joined-text">Joined</div>}
                </div>
                <div className="rain-amount">
                    <div>
                        <img src={rainStarted ? "images/robux-white.svg" : "images/robux.svg"} />
                        {seperateBalance(rainPrize)}
                    </div>
                </div>

                { (tipModal && res_768) && <div className="background-shadow"></div> }

                { tipModal &&
                    <div className="tip" style={res_768 ? {
                        top: "auto",
                        bottom: 0,
                        position: "fixed",
                        zIndex: "100",
                        paddingBottom: "15px",
                        borderRadius: "16px 16px 0 0",
                        border: "none"
                    } : {}}>
                        { res_768 &&
                            <div className='tip-header'>
                                <div>
                                    <h3>Rain</h3>
                                    <div className="rain-timer">
                                        <span>{new Date(rainTimer * 1000).toISOString().slice(11, 19) /* HH:MM:SS */}</span>
                                    </div>
                                </div>
                                <button onClick={() => setTimeout(() => setTipModal(false), 1)} className="close-button"><img src="images/x.svg" /></button>
                            </div>
                        }
                        <div>
                            <img src="images/robux-gray.svg" />
                            <input type="number" placeholder="Your amount" onChange={(e) => setTipAmount(parseFloat(e.target.value))} />
                        </div>
                        <div style={res_768 ? {
                            flexDirection: "column-reverse"
                        } : {}}>
                            <button style={res_768 ? {margin: "15px 0 0 0"} : {}} onClick={() => setTimeout(() => setTipModal(false), 1)}>Close</button>
                            <button style={res_768 ? {margin: 0} : {}} onClick={() => setTimeout(
                                () => {
                                    setTipModal(false);
                                    setTip(tipAmount);
                                    setTipAmount(0);
                                }, 1)
                            }>Tip</button>
                        </div>
                    </div>
                }
            </div>

            {/* Chat messages + Send message box */}
            <Chat tempID={tempID} mobile={res_768} room={room} socketCounter={socketCounter} dev={dev}>
                <LanguageDropdown bottom={true} room={room} setRoom={setRoom} />
            </Chat>
        </div>
        </>
    )
}

function RightNavShrank({expand}) {
    return (
        <div id="right-nav" className="shrank">
            <div className="items">
                <div className="item">
                    <a onClick={expand}>
                        <img src="images/chat.svg" />
                        <p>Chat</p>
                    </a>
                </div>
                <div className="item">
                    <a onClick={expand}>
                        <img src="images/rain.svg" />
                        <p>Rain</p>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default function RightNav({ setChatVisible, chatVisible, tempID, socketCounter, dev }) {
    
    const { data: user, refetch } = useUser();

    const [showExpanded, setExpanded] = useState(chatVisible || localExpandedData);
    const [showShrunk, setShrunk] = useState(! (chatVisible && localExpandedData));
    const [expandedStyle, setExpandedStyle] = useState({});
    const [onlineUsers, setOnlineUsers] = useState(0);

    const [rainStarted, setRainStarted] = useState(false);
    const [rainTimer, setRainTimer] = useState(0);
    const [rainPrize, setRainPrize] = useState(null);
    const [joinedRain, setJoinedRain] = useState(false);
    const [tip, setTip] = useState(0);

    useEffect(() => {
        socket.on("online-users", datas => {
            setOnlineUsers(datas.count);
        });
    }, []);

    useEffect(() => {
        if (dev !== false) {
            socket.on("rain-timer", data => {
                setRainTimer(data.timer);
                setRainPrize(data.prize);
                setRainStarted(data.rainStarted);
    
                if (!joinedRain && user) {
                    data.joinedUsers.forEach(joined_user => {
                        if (joined_user.id === user.id) setJoinedRain(true);
                    })
                }
            });
        }
    }, [user, dev]);

    useEffect(() => {
        if (joinedRain && user) {
            let wager = (user?.statistics?.wager?.robux || 1);
            socket.emit("join-rain", { user_id: user.id, wager: wager });

            socket.on("rain-prize", data => {
                setJoinedRain(false);

                toast.dismiss();
                toast.success(<span><b>Well done!</b><br/><span style={{display: "flex", alignItems: "center"}}>You've raised&nbsp;{seperateBalance(data[user.id])}&nbsp;<img src="images/robux.svg" />&nbsp;from rain.</span></span>,
                {
                    hideProgressBar: true,
                    theme: "dark"
                });

                refetch();
                socket.off("rain-prize");
            });
        }
    }, [user, joinedRain]);

    useEffect(() => {
        if (tip > 0 && user) {
            socket.emit("tip-rain", {
                user_id: user.id,
                secret_id: user._id,
                tip: tip
            });

            socket.on("tip-response", data => {
                toast.dismiss();
                if (data.err) {
                    toast.error(<span><b>Error</b><br/>{data.err}</span>,
                    {
                        hideProgressBar: true,
                        theme: "dark"
                    });
                } else if (data.success) {
                    toast.success(<span><b>Thanks!</b><br/>{data.success}</span>,
                    {
                        hideProgressBar: true,
                        theme: "dark"
                    });
                }
            });

            setTip(0);
        }
    }, [tip]);

    function expand() {
        setExpandedStyle({position: "absolute", right: "-550px"});
        setExpanded(true);
        localStorage.setItem("right-menu-expanded", "1");

        setTimeout(() => {
            $("#right-nav.expanded").animate({
                right: "0"
            }, 300,
            function() {
                setShrunk(false);
                setExpandedStyle({});
            });
        }, 1);

        setChatVisible(true);
    }

    function shrink() {
        //Shrink Animation
        setExpandedStyle({position: "absolute", right: "0"});
        setShrunk(true);
        localStorage.setItem("right-menu-expanded", "0");

        $("#right-nav.expanded").animate({
            right: "-550"
        }, 300,
        function() {
            setExpanded(false);
        });

        setChatVisible(false);
    }

    useEffect(() => {
        setExpanded(chatVisible)
    }, [chatVisible]);

    return (
        <>
            {showExpanded && (
                <RightNavExpanded
                    /* Rain Datas */
                    rainTimer={rainTimer}
                    rainPrize={rainPrize}
                    rainStarted={rainStarted}
                    joinedRain={joinedRain}
                    setJoinedRain={setJoinedRain}
                    setTip={setTip}
                    tempID={tempID}
                    user={user}
                    socketCounter={socketCounter}
                    
                    /* Online user count to display */
                    onlineUsers={onlineUsers}

                    /* Style specified datas */
                    shrink={shrink}
                    style={expandedStyle}

                    /* Authorize */
                    dev={dev}
                />
            )}
            {showShrunk && <RightNavShrank expand={expand} />}
        </>
    )
}