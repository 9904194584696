import Sketch from "react-p5";

const bubbles = [];
let timer = 0, timerTarget = 0;
let img;

class Bubble {
    constructor(p5, img, width, height) {
        this.p5 = p5;
        this.img = img;
        this.x = p5.random(-60, width - 180);
        this.r = p5.random(90, 220);
        this.y = height;
        this.vx = 0.4;
        this.vy = 3;
        this.vd = 0;
        this.alpha = p5.random(120, 30);
    }
    draw() {
        this.p5.tint(255, this.alpha);
        this.p5.image(this.img, this.x, this.y, this.r, this.r);
        this.x += this.vx;
        this.vd += this.vx;
        if (this.vd >= 6 || this.vd <= -6) this.vx *= -1;
        this.y -= this.vy;
    }
    delete() {
        if (this.y < -185) return true;
        else return false;
    }
}

export default function Bubbles({ w, h }) {
    
    const setup = async (p5, canvasParentRef) => {
        img = await p5.loadImage('images/bubbles/bubble-8.svg');
        p5.createCanvas(w, h).parent(canvasParentRef);
    }

    const draw = (p5) => {
        p5.clear();
        p5.background(0, 0);
        if (timer == timerTarget) {
            timerTarget = p5.floor(p5.random(50, 70));
            timer = 0;
            let bubble = new Bubble(p5, img, w, h);
            bubbles.push(bubble);
        }
        bubbles.forEach(b => {
            b.draw();
            if (b.delete()) {
                bubbles.splice(1, bubbles.indexOf(b));
            }
        });
        timer++;
    }

    return <Sketch className="bubbles" setup={setup} draw={draw} />;
}