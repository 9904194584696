import { seperateBalance } from '../../helpers/balance';

export default function MiniProfile({
    message,
    miniProfilePage,
    setMiniProfilePage,
    clickedUser,
    setMessage,
    handleClose,
    userAuth
}) {
    return (
        <div id="mini-profile">
            <div className="top">
                <img src={message.pfp} />
                <p>{message.sender}</p>
            </div>

            <div className="nav">
                <button className={miniProfilePage == "Main" ? "active" : undefined} onClick={() => setMiniProfilePage("Main")}>Main</button>
                <button className={miniProfilePage == "Last deposit & withdraw" ? "active" : undefined} onClick={() => setMiniProfilePage("Last deposit & withdraw")}>Last deposit & withdraw</button>
                <button className={miniProfilePage == "Action history" ? "active" : undefined} onClick={() => setMiniProfilePage("Action history")}>Action history</button>
            </div>

            <div className="content">
                {miniProfilePage == "Main" &&
                    <div className="main">
                        <div className="stats">
                            <div className="stat">
                                <span>Total wagered</span>
                                <div className="balance">
                                    <img src="images/robux.svg" />
                                    {seperateBalance(clickedUser?.statistics?.total_wager)}
                                </div>
                            </div>
                            <div className="stat">
                                <span>Net profit</span>
                                <div className="balance">
                                    <img src="images/robux.svg" />
                                    {seperateBalance(clickedUser?.statistics?.net_profit)}
                                </div>
                            </div>
                            <div className="stat">
                                <span>Last active bet</span>
                                <div className="balance">
                                    <img src="images/robux.svg" />
                                    {seperateBalance(clickedUser?.statistics?.last_bet)}
                                </div>
                            </div>
                        </div>
                        <div className="buttons">
                            <button onClick={() => {
                                handleClose();
                                setMessage("/warn @" + message.sender + " reason");
                            }}>Warn</button>
                            <button onClick={() => {
                                handleClose();
                                setMessage("/mute @" + message.sender + " 5m/0h/0d Reason");
                            }}>Mute</button>
                            { userAuth == "admin" ? (
                                <button onClick={() => {
                                    handleClose();
                                    setMessage("/chatban @" + message.sender + " Reason");
                                }}>Ban</button>
                            ) : (userAuth == "owner") && (
                                <button onClick={() => {
                                    handleClose();
                                    setMessage("/chatban @" + message.sender + " Reason");
                                }}>Ban</button>
                            )}
                        </div>
                    </div>
                }
                {miniProfilePage == "Last deposit & withdraw" &&
                    <div className="transactions">
                        {clickedUser?.transactions_history?.map((t, i) => {
                            return (
                                <div key={i}>
                                    <div className="info">
                                        <span className="type">{t.type}</span>
                                        <span className="date">{t.date}</span>
                                    </div>
                                    <div className="balance">
                                        {seperateBalance(t.quantity.amount)}
                                        <img src="images/robux.svg" />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
                {miniProfilePage == "Action history" &&
                    <div className="actions">
                        {clickedUser?.action_history?.map((a, i) => {
                            return (
                                <div key={i}>
                                    <div className="info">
                                        <span className="type">{a.type}</span>
                                        <span className="date">{new Date(a.date).toLocaleDateString()} {a.type == "Mute" && <> for {a.duration}</>}</span>
                                    </div>
                                    <div className="reason">
                                        {a.reason}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
        </div>
    )
}