import CancelIcon from '@mui/icons-material/Cancel';
import '../confirmation/confirmation.scss';

export default function Failed() {
    return (
        <div id="confirmation">
            <CancelIcon sx={{color: "red", fontSize: 200}} />
            <h1>Failed</h1>
            <p>Your payment cannot be confirmed</p>
        </div>
    )
}