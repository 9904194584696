import TaskAltIcon from '@mui/icons-material/TaskAlt';
import './confirmation.scss';

export default function Confirmation() {
    return (
        <div id="confirmation">
            <TaskAltIcon sx={{color: "green", fontSize: 200}} />
            <h1>Confirmed!</h1>
            <p>Your payment successfully confirmed.</p>
        </div>
    )
}