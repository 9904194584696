import { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from '@mui/material';
import ItemsDeposit from './items-deposit';
import RobuxDeposit from './robux-deposit';
import Cookie from '../../helpers/cookie';
import { instance, useUser } from '../../lib/hooks';
import { toast } from 'react-toastify';
import './deposit.scss';

const opt = {
    hideProgressBar: true,
    theme: "dark",
    autoClose: 2000
}

const methodRegions = {
	NA: "North America",
	EU: "Europe",
	RU: "Russia"
}

const cookie = new Cookie();

export default function Deposit({ chatVisible }) {
	const { data: user, refetch } = useUser();

	const [depositType, setDepositType] = useState('menu');
	const [selectedGame, setSelectedGame] = useState(null);
	const [showGameModal, setShowGameModal] = useState(false);
	const [confirmGame, setConfirmGame] = useState(null);
	const [showDepositModal, setDepositModal] = useState(false);

	const [depositRobuxAmount, setDepositRobuxAmount] = useState(0);
	const [total, setTotal] = useState(0);

	const [region, setRegion] = useState(localStorage.getItem('region'));
	const [currency, setCurrency] = useState(
		cookie.getCookie('currency') || 'USD'
	);
	const [depositMethods, setDepositMethods] = useState([
		'robux',
		/* 'items', */
		// 'visa',
		// 'mastercard',
		'litecoin',
		'dogecoin',
		'bitcoin',
	]);

	const res_768 = useMediaQuery('(max-width:768px)');

	function methodsByRegions(country) {
		switch (country) {
			case 'RU':
				setDepositMethods([
					'robux',
					/* 'items', */
					'visa',
					'mastercard',
					'litecoin',
					'bitcoin',
					'dogecoin',
					'pm',
					// 'mg',
					// 'yo',
					// 'qw',
				]);
				break;
			case "NA":
				setDepositMethods([
					'robux',
					/* 'items', */
					// 'visa',
					// 'mastercard',
					'litecoin', 
					'bitcoin',
					'dogecoin',
				]);
				break;
			case "EU":
				setDepositMethods([
					'robux',
					/* 'items', */
					// 'visa',
					// 'mastercard',
					'litecoin', 
					'bitcoin',
					'dogecoin',
				]);
				break;
			default:
				setDepositMethods([
					'robux',
					/* 'items', */
					// 'visa',
					// 'mastercard',
					'litecoin', 
					'bitcoin',
					'dogecoin',
				]);
		}
	}

	useEffect(() => {
		if (!region || !cookie.getCookie('currency')) {
			axios.get('https://ipapi.co/json/').then((response) => {
				let data = response.data;
				let data_country = data.country;
				let data_continent = data.continent_code;
				let local_region;

				if (Object.keys(methodRegions).includes(data_country)) local_region = data_country;
				else if (Object.keys(methodRegions).includes(data_continent)) local_region = data_continent;
				else local_region = "EU";

				localStorage.setItem("region", local_region);
				cookie.setCookie('currency', data.currency, 30);
				setCurrency(data.currency);
				setRegion(local_region);
				methodsByRegions(local_region);
			});
		} else methodsByRegions(region);
	}, []);

	function changeCountry(c) {
		localStorage.setItem('region', c);
		setRegion(c);
		methodsByRegions(c);
		handleClose();
	}

	function setGame(game) {
		setConfirmGame(game);
		setShowGameModal(true);
	}

	// Country dropdown
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	function handleClick(event) {
		setAnchorEl(event.currentTarget);
	}
	function handleClose() {
		setAnchorEl(null);
	}

	function handleDeposit() {
		if (depositType === 'crypto') {
			instance
				.post(process.env.REACT_APP_API_ENDPOINT + '/payments/crypto', {
					amount: depositRobuxAmount,
					user: user,
				})
				.then((res) => {
					if (res.data.success) window.location.href = res.data.url;
				})
				.catch((res) => {
					if (res.data?.message) toast.error(res.data.message, opt);
				});
		} else {
			if (depositRobuxAmount < 250) toast.error("Minimum deposit amount is 250 Robux", opt);
			else 
				instance
					.post(process.env.REACT_APP_API_ENDPOINT + '/payments/fiat', {
						amount: depositRobuxAmount,
						user: user,
						method: depositType,
					})
					.then((res) => {
						if (res.data?.url) window.location.href = res.data.url;
					})
					.catch((res) => {
						console.log(res);
						if (res?.response?.data?.message) toast.error(res?.response?.data?.message, opt);
					})
		}
	}

	return (
		<div id="deposit" className={chatVisible ? 'chat-visible' : undefined}>
			{!['robux', 'items'].includes(depositType) && (
				<>
					<div className="deposit-header">
						<h1>Choose a deposit method</h1>

						<Button
							id="country-button"
							className={open ? 'active' : undefined}
							aria-controls={open ? 'basic-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={open ? 'true' : undefined}
							onClick={handleClick}
							style={open ? { borderRadius: '18px 18px 0 0' } : {}}
						>
							<img
								className="flag"
								src={
									'images/flags/' + methodRegions[region]?.toLowerCase()?.replaceAll(' ', '-') + '.png'
								}
							/>
							<div className="text">
								<span className="top-text">Your Country</span>
								<span className="country-name">{methodRegions[region]}</span>
							</div>
							<img
								src="images/down-white.svg"
								className={open ? 'opened' : undefined}
							/>
						</Button>
						<Menu
							id="country-menu"
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							MenuListProps={{ 'aria-labelledby': 'country-button' }}
						>
							{Object.keys(methodRegions).map((name, i) => {
								if (region !== name) {
									return (
										<MenuItem
											className="country-name-item"
											onClick={() => {
												changeCountry(name);
											}}
											key={i}
										>
											<img
												src={
													'images/flags/' + methodRegions[name]?.toLowerCase().replaceAll(' ', '-') + '.png'
												}
											/>
											{methodRegions[name]}
										</MenuItem>
									);
								}
							})}
						</Menu>
					</div>
					<div className="deposit-row">
						<div className="row-header">
							<p>Roblox</p>
							<div className="line"></div>
						</div>
						<div className="deposit-item-container">
							{depositMethods.includes('robux') && (
								<div
									className="deposit-item"
									onClick={() => setDepositType('robux')}
								>
									<img src="images/deposit-icons/robux.png" alt="robux" />
									<p>Robux</p>
								</div>
							)}
							{depositMethods.includes('items') && (
								<div
									className="deposit-item"
									onClick={() => setDepositType('items')}
								>
									<img
										src="images/deposit-icons/items.png"
										alt="roblox items"
									/>
									<p>Roblox items</p>
								</div>
							)}
						</div>
					</div>

					{(
						depositMethods.includes("visa") || 
						depositMethods.includes('mastercard') || 
						depositMethods.includes('qw') || 
						depositMethods.includes('pm') || 
						depositMethods.includes('mg') || 
						depositMethods.includes('yo')
					) &&
						<div className="deposit-row">
							<div className="row-header">
								<p>Fiat</p>
								<div className="line"></div>
							</div>
							<div className="deposit-item-container">
								{depositMethods.includes('visa') && (
									<div
										className="deposit-item"
										onClick={() => {
											setDepositModal(true);
											setDepositType('visa');
										}}
									>
										<img src="images/deposit-icons/visa.png" alt="visa" />
										<img
											src="images/deposit-icons/lights/light-visa.png"
											className="light"
											alt="visa-light"
											loading="lazy"
										/>
										<p>Visa</p>
									</div>
								)}
								{depositMethods.includes('mastercard') && (
									<div
										className="deposit-item"
										onClick={() => {
											setDepositModal(true);
											setDepositType('mastercard');
										}}
									>
										<img
											src="images/deposit-icons/mastercard.png"
											alt="mastercard"
										/>
										<img
											src="images/deposit-icons/lights/light-mastercard.png"
											className="light"
											alt="mastercard-light"
											loading="lazy"
										/>
										<p>Mastercard</p>
									</div>
								)}
								{depositMethods.includes('pm') && (
									<div
										className="deposit-item"
										onClick={() => {
											setDepositModal(true);
											setDepositType('pm');
										}}
									>
										<img src="images/deposit-icons/perfectmoney.png" alt="perfectmoney" loading="lazy" />
										<p>Perfect Money</p>
									</div>
								)}
							</div>
							<div className="deposit-item-container">
								{depositMethods.includes('qw') && (
									<div
										className="deposit-item"
										onClick={() => {
											setDepositModal(true);
											setDepositType('qw');
										}}
									>
										<img src="images/deposit-icons/qiwi.png" alt="qiwi" loading="lazy" />
										<img
											src="images/deposit-icons/lights/light-qiwi.png"
											className="light"
											alt="qiwi-light"
										/>
										<p>Qiwi</p>
									</div>
								)}
								{depositMethods.includes('mg') && (
									<div
										className="deposit-item"
										onClick={() => {
											setDepositModal(true);
											setDepositType('mg');
										}}
									>
										<img src="images/deposit-icons/megafon.png" alt="megafon" loading="lazy" />
										<p>Megafon</p>
									</div>
								)}
								{depositMethods.includes('yo') && (
									<div
										className="deposit-item"
										onClick={() => {
											setDepositModal(true);
											setDepositType('yo');
										}}
									>
										<img src="images/deposit-icons/yoomoney.png" alt="yoomoney" loading="lazy" />
										<p>ЮMoney</p>
									</div>
								)}
							</div>
						</div>
					}

					{(depositMethods.includes("litecoin") || depositMethods.includes("dogecoin") || depositMethods.includes("bitcoin")) &&
						<div className="deposit-row">
							<div className="row-header">
								<p>Crypto</p>
								<div className="line"></div>
							</div>
							<div className="deposit-item-container">
								{depositMethods.includes('litecoin') && (
									<div
										className="deposit-item"
										onClick={() => {
											setDepositModal(true);
											setDepositType('crypto');
										}}
									>
										<img src="images/deposit-icons/litecoin.png" alt="litecoin" />
										<img
											src="images/deposit-icons/lights/light-litecoin.png"
											className="light crypto-light"
										/>
										<p>Litecoin</p>
									</div>
								)}
								{depositMethods.includes('dogecoin') && (
									<div
										className="deposit-item"
										onClick={() => {
											setDepositModal(true);
											setDepositType('crypto');
										}}
									>
										<img src="images/deposit-icons/doge.png" alt="doge" />
										<img
											src="images/deposit-icons/lights/light-doge.png"
											className="light crypto-light"
										/>
										<p>Doge</p>
									</div>
								)}
								{depositMethods.includes('bitcoin') && (
									<div
										className="deposit-item"
										onClick={() => {
											setDepositModal(true);
											setDepositType('crypto');
										}}
									>
										<img
											src="images/deposit-icons/lights/light-btc.png"
											className="light crypto-light"
										/>
										<img src="images/deposit-icons/bitcoin.png" alt="bitcoin" />
										<p>Bitcoin</p>
									</div>
								)}
							</div>
						</div>
					}
				</>
			)}

			{depositType === 'items' && !selectedGame && (
				<div id="deposit" className="select-game items-withdraw">
					<div className="header">
						<div>
							<h1>Choose a game for deposit</h1>
							<span className="tag">11 games</span>
						</div>
						<div>
							<button className="go-back" onClick={() => {setDepositType("menu")}}>
								<img src="images/right.svg" />
							</button>
						</div>
					</div>
					<div className="search">
						<div className="search game-search">
							<img className="icon" src="images/search.svg" />
							<input placeholder="What are we looking for?" />
						</div>
					</div>
					<div className="games">
						<div className="game" onClick={() => setGame('adopt-me')}>
							<img src="images/games/adopt-me.png" />
							<p>Adopt Me</p>
						</div>
						<div className="game" onClick={() => setGame('murder-mystery-2')}>
							<img src="images/games/murder-mystery.png" />
							<p>Murder Mystery 2</p>
						</div>
						<div className="game" onClick={() => setGame('blox-fruits')}>
							<img src="images/games/blox-fruits.png" />
							<p>Blox Fruits</p>
						</div>
						<div className="game" onClick={() => setGame('murder-mystery-2')}>
							<img src="images/games/murder-mystery.png" />
							<p>Murder Mystery 2</p>
						</div>
						<div className="game" onClick={() => setGame('blox-fruits')}>
							<img src="images/games/blox-fruits.png" />
							<p>Blox Fruits</p>
						</div>
						<div className="game" onClick={() => setGame('adopt-me')}>
							<img src="images/games/adopt-me.png" />
							<p>Adopt Me</p>
						</div>
						<div className="game" onClick={() => setGame('murder-mystery-2')}>
							<img src="images/games/murder-mystery.png" />
							<p>Murder Mystery 2</p>
						</div>
						<div className="game" onClick={() => setGame('blox-fruits')}>
							<img src="images/games/blox-fruits.png" />
							<p>Blox Fruits</p>
						</div>
						<div className="game" onClick={() => setGame('adopt-me')}>
							<img src="images/games/adopt-me.png" />
							<p>Adopt Me</p>
						</div>
						<div className="game" onClick={() => setGame('murder-mystery-2')}>
							<img src="images/games/murder-mystery.png" />
							<p>Murder Mystery 2</p>
						</div>
						<div className="game" onClick={() => setGame('blox-fruits')}>
							<img src="images/games/blox-fruits.png" />
							<p>Blox Fruits</p>
						</div>
					</div>
				</div>
			)}
			{depositType === 'items' && selectedGame && (
				<ItemsDeposit
					country={methodRegions[region]}
					chatVisible={chatVisible}
				/>
			)}
			{depositType === 'robux' && <RobuxDeposit refetch={refetch} user={user} />}

			<Modal
				open={showGameModal}
				onClose={() => setShowGameModal(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				style={{ background: '#13161D8F' }}
			>
				<Box
					sx={BoxStyle}
					style={
						res_768
							? {
								width: '100%',
								height: 'auto',
								padding: '15px 25px',
								bottom: '0',
								top: 'auto',
								transform: 'none',
								left: '0',
								boxSizing: 'border-box',
							}
							: {}
					}
				>
					<div className="confirm-modal deposit-modal">
						<button
							onClick={() => setShowGameModal(false)}
							className="close-button"
						>
							<img src="images/x.svg" />
						</button>
						<h1>Are you sure?</h1>
						<p>Your chosen game is {confirmGame?.replaceAll('-', ' ')}</p>
						<div className="buttons">
							<button onClick={() => setShowGameModal(false)}>Cancel</button>
							<button
								className="active"
								onClick={() => {
									setGame(setSelectedGame);
									setShowGameModal(false);
								}}
							>
								Continue
							</button>
						</div>
					</div>
				</Box>
			</Modal>

			<Modal
				open={showDepositModal}
				onClose={() => setDepositModal(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				style={{ background: '#13161D8F' }}
			>
				<Box
					sx={VisaBoxStyle}
					style={
						res_768
							? {
								width: '100%',
								height: 'auto',
								padding: '0px 25px 15px 25px',
								bottom: '0',
								top: 'auto',
								transform: 'none',
								left: '0',
								boxSizing: 'border-box',
							}
							: {}
					}
				>
					<div className="visa-modal deposit-modal">
						<button
							onClick={() => setDepositModal(false)}
							className="close-button"
						>
							<img src="images/x.svg" />
						</button>
						<h1>
							{depositType.split('-').map((word, i) => {
								if (i === depositType.split('-').length - 1)
									return word[0].toUpperCase() + word.slice(1) + ' deposit';
								return word[0].toUpperCase() + word.slice(1) + ' ';
							})}
						</h1>
						<div className="inputs">
							<div className="robux-deposit-search">
								<div className="search">
									<img className="icon" src="images/robux-gray.svg" />
									<input
										placeholder="0.00"
										type="number"
										value={depositRobuxAmount}
										onChange={(e) => {
											setDepositRobuxAmount(parseFloat(e.target.value));
											setTotal((Math.ceil(e.target.value || 0) * (parseFloat(process.env.REACT_APP_ROBUX_RATE) / 1000)).toFixed(2));
										}}
									/>
									<div className="controllers">
										<button onClick={() => {
											setDepositRobuxAmount(100);
											setTotal((Math.ceil(100) * (parseFloat(process.env.REACT_APP_ROBUX_RATE) / 1000)).toFixed(2));
										}}>100</button>
										<button onClick={() => {
											setDepositRobuxAmount(1000);
											setTotal((Math.ceil(1000) * (parseFloat(process.env.REACT_APP_ROBUX_RATE) / 1000)).toFixed(2));
										}}>1000</button>
										<img src="images/question.svg" />
									</div>
								</div>
							</div>

							<div className="total">
								<p>Total price</p>
								<span>{total}$</span>
							</div>
						</div>
						<div className="buttons">
							<button onClick={() => setDepositModal(false)}>Close</button>
							<button
								className="active"
								onClick={() => {
									//setDepositModal(false);
									handleDeposit();
								}}
							>
								Continue
							</button>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	);
}

const BoxStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 600,
	height: 220,
	background: '#1E222C',
	border: '2px solid #2E323D',
	borderRadius: '16px',
	padding: '25px 40px',
	outline: 'none',
};

const VisaBoxStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 600,
	height: 'fit-content',
	background: '#1E222C',
	border: '2px solid #2E323D',
	borderRadius: '16px',
	padding: '5px 35px 30px 35px',
	outline: 'none',
};
