import { useState } from 'react';
import ItemsWithdraw from './items-withdraw';
import RobuxWithdraw from './robux-withdraw';
import { useUser } from '../../lib/hooks';
import './withdraw.scss';

export default function Withdraw({chatVisible}) {

    const { data: user, refetch } = useUser();
    const [withdrawType, setWithdrawType] = useState("menu");

    return (
        <div id="withdraw" className={chatVisible ? "chat-visible" : undefined}>
            { withdrawType === "menu" &&
            <>
            <h1>Choose a withdraw method</h1>
            <div className='withdraw-row'>
                <div className="row-header">
                    <p>Roblox</p>
                    <div className='line'></div>
                </div>
                <div className='withdraw-item-container'>
                    <div className='withdraw-item' onClick={() => setWithdrawType("robux")}>
                        <img src="images/deposit-icons/robux.png" alt="robux" />
                        <p>Robux</p>
                    </div>
                    {/*<div className='withdraw-item' onClick={() => setWithdrawType("items")}>
                        <img src="images/deposit-icons/items.png" alt="roblox items" />
                        <p>Roblox items</p>
                    </div>*/}
                </div>
            </div>
            </>
            }
            { /*withdrawType === "items" &&
                <ItemsWithdraw chatVisible={chatVisible} /> */
            }
            { withdrawType === "robux" &&
                <RobuxWithdraw refetch={refetch} user={user} />
            }
        </div>
    )
}