import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import reactStringReplace from 'react-string-replace';
import Popover from '@mui/material/Popover';
import MiniProfile from './mini-profile';
import socket from '../../socket';
import { useUser } from '../../lib/hooks';
import './chat.scss';

export default function Chat(props) {

    let room = props.room;
    const commands = ["siteban", "chatban", "unban", "mute", "unmute", "warn", "undo"];
    const reasons = ["Offense", "Hate-Speech", "Spam", "Harassment", "Trolling", "Advertising", "Disruptive Behavior", "Misinformation", "Scam"];
    const commandDescriptions = {
        "siteban": "Bans a user from website",
        "chatban": "Bans a user from chat",
        "unban": "Removes the ban for a user from website",
        "mute": "Mutes a user from chat",
        "unmute": "Removes the mute for a user from chat",
        "warn": "Warns a user from in-chat and with a notification",
        "undo": "Undos the most recent command"
    }

    const { data: user, refetch } = useUser();

    const [messages, setMessages] = useState({});
    const [message, setMessage] = useState("");
    const [suggestion, setSuggestion] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionType, setSuggestionType] = useState("");
    const [isAuthorized, setAuthorized] = useState(false);
    const [usernames, setUsernames] = useState([]);
    const [undo, setUndo] = useState("");
    const [counterDate, setCounterDate] = useState(null);
    const [clickedUser, setClickedUser] = useState(false);
    const [clickedMessage, setClickedMessage] = useState(null);
    const [clickedMessageDate, setClickedMessageDate] = useState(null);
    const [titleLoading, setTitleLoading] = useState(false);
    const [miniProfilePage, setMiniProfilePage] = useState("Main");

    const [mouseX, setMouseX] = useState();
    const [mouseY, setMouseY] = useState();

    useEffect(() => {
        if (user) {
            //User is muted
            if (user?.mute) {
                let diff = new Date(user.mute.end) - new Date();
                let ms = Math.abs(diff);

                let muteCounterInterval = setInterval(() => {
                    const days = Math.floor(ms / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((ms % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((ms % (1000 * 60)) / (1000));

                    let formattedTime;
                    if (days) formattedTime = `${days.toString().padStart(2, '0')}d:${hours.toString().padStart(2, '0')}h:${minutes.toString().padStart(2, '0')}m:${seconds.toString().padStart(2, '0')}s`;
                    else if (hours) formattedTime = `${hours.toString().padStart(2, '0')}h:${minutes.toString().padStart(2, '0')}m:${seconds.toString().padStart(2, '0')}s`;
                    else formattedTime = `${minutes.toString().padStart(2, '0')}m:${seconds.toString().padStart(2, '0')}s`;

                    setCounterDate(formattedTime);
                    ms -= 1000;

                    if (ms <= 0) {
                        setCounterDate(null);
                        clearInterval(muteCounterInterval);
                        refetch();
                    }
                }, 1000);
            }

            // Get usernames
			fetch(process.env.REACT_APP_API_ENDPOINT + "/auth/get-usernames", {
				method: "POST",
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					secret_id: user._id
				})
			})
			.then(data => data.json())
			.then(data => {
				setUsernames(data.usernames);
			});

            setAuthorized((user?.rank === "admin" || user?.rank === "mod" || user?.rank === "owner"));
        }
    }, [user, props.socketCounter]);

    useEffect(() => {
        if (props.dev !== false) {
            //Scroll bottom of the chat window
            let chatWindowEl = document.querySelector(".chat-window")
            chatWindowEl.scrollTo(0, chatWindowEl.scrollHeight);

            socket.emit("get-chat-request", {});

            socket.on("get-chat", datas => {
                // Chat messages and data
                let messagesObj = {};

                if (!datas?.singleRoom) datas.forEach(data => {
                    let messages = data.messages;
                    messagesObj[data.room] = messages;
                });
                else messagesObj[datas.singleRoom.room] = datas.singleRoom.messages;

                for (let room of Object.keys(messagesObj)) {
                    let messages = messagesObj[room];
                    messages.forEach(message => {
                        if (!message?.tagged) {
                            message.message = reactStringReplace(message.message, /@(\w+)/g, (match, _) => {
                                message.tagged = message.tagged?.length > 0 ? [...message.tagged, match] : [match];
                                return <span className="tag">{message.message[0] != "@" && <></>}@{match}{message.message.at(-1) != "@" && <></>}</span>
                            });
                        }
                    });
                }
                setMessages(messagesObj);
            });
        }
    }, [room, props.dev, user]);

    useEffect(() => { socket.emit("join-chat", props.tempID) }, []);

    useEffect(() => {
        if (user && messages) {
            // Tagging
            for (let i = 0; i < messages[room]?.length; i++) {
                let message = messages[room][i];
                // Find the first message in a message group of same user, set taggedMe to true
                if (message?.tagged?.includes(user?.username)) {
                    for (let j = i; j < messages[room].length; j++) {
                        if (messages[room]?.[j]?.sender != message.sender) {
                            messages[room][j - 1].taggedMe = true;
                            break;
                        }
                    }
                }
                setMessages(messages);
            }
        }
    }, [messages, user, room]);

    function sendMessage(e) {
        e.preventDefault();

        let opt = {
            hideProgressBar: true,
            theme: "dark"
        };

        toast.dismiss();

        if (message.length > 100)
            return toast.error(<span><b>Error</b><br />Message can't be longer than 100 characters.</span>, opt);

        else if (!user)
            return toast.error(<span><b>Error</b><br />Please first login to use chat.</span>, opt);

        else if (message?.trim()) {

            let raw_message;
            if (isAuthorized && message == "/undo") {
                raw_message = undo;
                setUndo("");
            }
            else raw_message = message

            socket.emit("send-message", {
                message: raw_message,
                secret_id: user?._id || null,
                user_id: user?.id || null,
                room: room
            });

            setMessage("");
            setSuggestion("");
            setSuggestions([]);
            setSuggestionType("");

            if (isAuthorized && message.startsWith("/")) {
                let commandUser = message.split(" ")?.[1];
                if (!commandUser) return;

                if (message.startsWith("/siteban") || message.startsWith("/chatban")) setUndo("/unban " + commandUser);
                else if (message.startsWith("/mute")) setUndo("/unmute " + commandUser);
            }
        }
    }

    function changeTitle(title) {
        setTitleLoading(true);
        handleClose();

        socket.emit("change-title", {
            user_id: user?.id,
            secret_id: user?._id,
            title
        });

        socket.on("title-changed", (datas) => {
            setTitleLoading(false);
            if (datas.success) user.title = title;
            socket.off("title-changed");
        })
    }

    function getClickedUser(sender) {
        fetch(process.env.REACT_APP_API_ENDPOINT + "/admin/get-user-by-username", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                admin_id: user._id,
                sender
            })
        })
            .then(data => data.json())
            .then(data => {
                if (data?.user) setClickedUser(data.user);
            });
    }

    function deleteMessagePopover(e, d) {
        if (isAuthorized && e.type === 'contextmenu') {
            setClickedMessageDate(d);
            e.preventDefault();
            setMouseX(e.clientX);
            setMouseY(e.clientY);
            setDeleteMessageAnchor(e.currentTarget);
        }
    }

    function deleteMessage(d) {
        if (clickedMessageDate) {
            socket.emit("delete-message", {
                user_id: user?._id,
                message_date: d,
                room
            });
            handleClose();
        }
    }

    function inputChange(e) {
        /* Suggestions */
        let new_message = e.target.value,
            message_arr = new_message.split(" ");

        function reset() {
            setSuggestion("");
            setSuggestions([]);
            setSuggestionType("");
        }

        if (!user) {
            setMessage(new_message);
            setSuggestion(new_message);
            return;
        }

        // User suggestions
        if (message_arr?.at(-1)?.[0] == "@" && message_arr?.at(-1)?.length > 1) {
            let raw_username = message_arr?.at(-1).replace("@", "");
            let matchedUsers = usernames.filter(u => u.username.startsWith(raw_username));
            
            if (matchedUsers.length) {
                message_arr[message_arr.length - 1] = "@" + matchedUsers[0].username;

                setSuggestion(message_arr.join(" "));
                setSuggestions(matchedUsers);
                setSuggestionType("users");
            }
            else reset();
        }

        // Command suggestions
        else if (
            new_message[0] == "/" &&
            message_arr.length == 1 &&
            !commands.includes(message_arr[0].replace("/", "")) &&
            new_message.indexOf(" ") == -1 &&
            isAuthorized
        ) {
            let raw_command = message_arr?.[0].replace("/", "");
            let matchedCommands = commands.filter(c => c.startsWith(raw_command));
            
            if (matchedCommands.length) {
                message_arr[0] = "/" + matchedCommands[0];

                setSuggestion(message_arr.join(" "));
                setSuggestions(matchedCommands);
                setSuggestionType("commands");
            } else reset();
        }

        // Command suggestion (Username)
        else if (
            commands.filter(c => c != "undo").includes(message_arr[0].replace("/", "")) && 
            (message_arr.at(-1) == "@" || message_arr.filter(f => f).length == 1) &&
            isAuthorized
        ) {
            message_arr[1] = "@username";
            setSuggestion(message_arr.join(" "));
            setSuggestions([]);
        }

        // Command suggestion (Date)
        else if (
            ["mute", "ban"].includes(message_arr[0].replace("/", "")) && 
            (message_arr.filter(f => f).length == 2) &&
            isAuthorized
        ) {
            message_arr[2] = "time (e.g. 1m/1h/1d)";
            setSuggestion(message_arr.join(" "));
            setSuggestions([]);
        }

        // Command Reason suggestions
        else if ((
            ( message_arr.length == 3 && message_arr[0] == "/siteban" ) ||
            ( message_arr.length == 3 && message_arr[0] == "/chatban" ) ||
            ( message_arr.length == 4 && message_arr[0] == "/mute") ||
            ( message_arr.length == 3 && message_arr[0] == "/warn")
            ) && isAuthorized
        ) {
            let raw_reason = message_arr?.at(-1);
            let matchedReasons = reasons.filter(r => r.toLowerCase().startsWith(raw_reason.toLowerCase()));
            
            if (matchedReasons.length) {
                message_arr[message_arr.length - 1] = matchedReasons[0];

                setSuggestion(message_arr.join(" "));
                setSuggestions(matchedReasons);
                setSuggestionType("reasons");
            } else reset();
        }

        else reset();

        setMessage(new_message);
    }

    function inputKeyDown(e) {
        setTimeout(() => { if (e.key == "Tab") e.target.focus(); }, 1); // Focus input after TAB key

        if (e.key === "Tab" && suggestions.length && suggestionType == "users") setSuggestedUser(e.target.value);
        else if (e.key === "Tab" && suggestions.length && suggestionType == "commands") setSuggestedCommand(e.target.value);
        else if (e.key === "Tab" && suggestions.length && suggestionType == "reasons") setSuggestedReason(e.target.value);
    }

    function setSuggestedUser(m, u = null) {
        let suggestionUsername = "@" + (u || suggestions[0].username)
        setSuggestion(suggestionUsername);
        setSuggestions([]);

        let message_arr = m.split(" ");
        message_arr[message_arr.length - 1] = suggestionUsername;

        setSuggestion(message_arr.join(" "));
        setMessage(message_arr.join(" "));
    }

    function setSuggestedCommand(m, u = null) {
        let suggestionCommand = "/" + (u || suggestions[0])
        setSuggestion(suggestionCommand);
        setSuggestions([]);

        let message_arr = m.split(" ");
        message_arr[message_arr.length - 1] = suggestionCommand;

        setSuggestion(message_arr.join(" "));
        setMessage(message_arr.join(" "));
    }

    function setSuggestedReason(m, u = null) {
        let suggestionReason = (u || suggestions[0])
        setSuggestion(suggestionReason);
        setSuggestions([]);

        let message_arr = m.split(" ");
        message_arr[message_arr.length - 1] = suggestionReason;

        setSuggestion(message_arr.join(" "));
        setMessage(message_arr.join(" "));
    }

    // Popover
    const [miniProfileAnchor, setMiniProfileAnchor] = useState(null);
    const miniProfileOpened = Boolean(miniProfileAnchor);
    const [deleteMessageAnchor, setDeleteMessageAnchor] = useState(null);
    const deleteMessageOpened = Boolean(deleteMessageAnchor);
    const [titleAnchor, setTitleAnchor] = useState(null);
    const titleOpened = Boolean(titleAnchor);
    const handleClose = () => {
        setMiniProfileAnchor(null);
        setDeleteMessageAnchor(null);
        setTitleAnchor(null);
    }

    return (
        <div id="chat">
            {props.mobile && <div className="shadow"></div>}
            <div className="chat-window" style={(miniProfileOpened) ? { overflowY: "hidden" } : {}}>
                {!messages?.[room] &&
                    <div style={{
                        color: "#7D8496",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        fontSize: "20px"
                    }}>Chat is empty :{"("}</div>
                }
                {messages?.[room]?.map((message, index) => {
                    let sameSender = messages?.[room]?.[index + 1]?.sender === message.sender;

                    // Ban/Mute Log
                    if (message?.type == "log") return (
                        <div className="log" key={index}>
                            <p>{message.message}</p>
                            {(undo && index == 0) && <p><span>/undo</span> to cancel an action</p>}
                        </div>
                    )

                    // Warning
                    if (message?.to == user?.username && message?.type == "warning") {
                        return (
                            <div className="warning" key={index}>
                                <h5>Warning</h5>
                                <p>{message.reason}</p>
                            </div>
                        )
                    }
                    else if (message?.type == "warning") return <></>;

                    return (
                        <div
                            key={index}
                            className={"message " + (message.sender === user?.username ? "sent" : "received")}
                            style={sameSender ? { marginTop: "-5px" } : { width: "calc(100% - 5px)" }}
                        >
                            {message.sender === user?.username ?
                                <>
                                    <div className="message-content">
                                        {!sameSender &&
                                            <div>
                                                {message?.rank === "mod" && <span className="who mod">Mod</span>}
                                                {message?.rank === "admin" && <span className="who admin">Admin</span>}
                                                {message?.rank === "owner" && <span className="who owner">Owner</span>}
                                                { <span className="who">Me</span> }
                                                
                                                <img className="title" src={"/images/titles/Type=" + user?.title + ".svg"} />
                                                <span className="sender-name">{message.sender}</span>
                                            </div>
                                        }
                                        <div onClick={e => deleteMessagePopover(e, message.date)} onContextMenu={e => deleteMessagePopover(e, message.date)}>
                                            <span style={sameSender ? { borderRadius: "16px" } : {}} className="message-text">{message.message}</span>
                                        </div>
                                    </div>
                                    <div className="message-info">
                                        {!sameSender &&
                                            <img src={message.pfp} />
                                        }
                                    </div>
                                </> :
                                <>
                                    <div className="message-info">
                                        {!sameSender &&
                                            <>
                                                {isAuthorized ? (
                                                    <>
                                                        <img
                                                            src={message.pfp}
                                                            onClick={e => {
                                                                setClickedMessage(message.date);
                                                                getClickedUser(message.sender);
                                                                setMiniProfileAnchor(e.currentTarget);
                                                            }}
                                                        />
                                                        {(clickedUser && clickedMessage == message.date) &&
                                                            <Popover
                                                                anchorEl={miniProfileAnchor}
                                                                open={miniProfileOpened}
                                                                onClose={handleClose}
                                                                className="header-info-popover"
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'right',
                                                                }}
                                                            >
                                                                <MiniProfile
                                                                    message={message}
                                                                    miniProfilePage={miniProfilePage}
                                                                    setMiniProfilePage={setMiniProfilePage}
                                                                    clickedUser={clickedUser}
                                                                    setMessage={setMessage}
                                                                    handleClose={handleClose}
                                                                    userAuth={user?.rank}
                                                                />
                                                            </Popover>
                                                        }
                                                    </>
                                                ) : (
                                                    <img src={message.pfp} />
                                                )}
                                            </>
                                        }
                                    </div>
                                    <div className="message-content">
                                        {!sameSender &&
                                            <div>
                                                {message?.taggedMe && <span className="who tagged">@</span>}

                                                {message?.rank === "mod" && <span className="who mod">Mod</span>}
                                                {message?.rank === "admin" && <span className="who admin">Admin</span>}
                                                {message?.rank === "owner" && <span className="who owner">Owner</span>}

                                                {/* Title */}
                                                { message?.title 
                                                ? <img className="title" src={"/images/titles/Type=" + message.title + ".svg"} alt={message.title} />
                                                : message?.rank != "user" 
                                                    ? <img className="title" src={"/images/titles/Type=" + (message.rank.charAt(0).toUpperCase() + message.rank.slice(1)) + ".svg"} alt={message.rank.charAt(0).toUpperCase() + message.rank.slice(1)} />
                                                    : <img className="title" src={"/images/titles/Type=Bronze.svg"} alt="Bronze" />
                                                }
                                                
                                                <span className="sender-name">{message.sender}</span>
                                            </div>
                                        }
                                        <div onClick={e => deleteMessagePopover(e, message.date)} onContextMenu={e => deleteMessagePopover(e, message.date)}>
                                            <span style={sameSender ? { borderRadius: "16px" } : {}} className="message-text">{message.message}</span>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    )
                })}
                {/* Delete Message Popover */}
                {(isAuthorized && deleteMessageOpened) &&
                    <Popover
                        anchorEl={deleteMessageAnchor}
                        open={deleteMessageOpened}
                        onClose={handleClose}
                        className="header-info-popover"
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: mouseY, left: mouseX }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <div className="delete-message" onClick={() => deleteMessage(clickedMessageDate)}>
                            Delete message
                        </div>
                    </Popover>
                }
            </div>
            <div className="send-message">
                <form onSubmit={counterDate !== null ? () => { } : sendMessage}>
                    { counterDate !== null ? (
                        <span className="counter-date">You have mute for {counterDate} for {user?.mute?.reason}</span>
                    ) : user?.ban?.type ? (
                        <span className="counter-date">You have a chat ban for {user?.ban?.reason}</span>
                    ) : (
                        <>
                            <div>
                                {/* SUGGESTION MENU */}
                                { (suggestions?.length && suggestionType) ?
                                    <div className="suggestions-menu">
                                        {suggestionType == "users" &&
                                            suggestions.map((s, i) => {
                                                return (
                                                    <div key={i} className="user" onClick={() => setSuggestedUser(message, s.username)}>
                                                        <div className="name">
                                                            <img src={s.pfp} />
                                                            <p>{s.username}</p>
                                                        </div>
                                                        <div className="buttons">
                                                            { i == 0 ? (
                                                                <p>Press Tab</p>
                                                            ) : (
                                                                <div className="icons">
                                                                    <img
                                                                        src="/images/up-arrow.svg"
                                                                        onClick={e => {
                                                                            e.stopPropagation();
                                                                            let old_suggestions = JSON.parse(JSON.stringify(suggestions));
                                                                            let a = {...old_suggestions[i]};
                                                                            old_suggestions[i] = old_suggestions[i - 1];
                                                                            old_suggestions[i - 1] = a;
                                                                            setSuggestions(old_suggestions);
                                                                        }}
                                                                    />
                                                                    { i != suggestions.length - 1 &&
                                                                        <img
                                                                            src="/images/down-arrow.svg"
                                                                            onClick={e => {
                                                                                e.stopPropagation();
                                                                                let old_suggestions = JSON.parse(JSON.stringify(suggestions));
                                                                                let a = {...old_suggestions[i]};
                                                                                old_suggestions[i] = old_suggestions[i + 1];
                                                                                old_suggestions[i + 1] = a;
                                                                                setSuggestions(old_suggestions);
                                                                            }}
                                                                        />
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                        {suggestionType == "commands" &&
                                            suggestions.map((s, i) => {
                                                return (
                                                    <div key={i} className="command" onClick={() => setSuggestedCommand(message, s)}>
                                                        <div className="command">
                                                            <p>/{s}</p>
                                                            <span className="desc">{commandDescriptions[s]}</span>
                                                        </div>
                                                        <div className="buttons">
                                                            { i == 0 ? (
                                                                <p>Press Tab</p>
                                                            ) : (
                                                                <div className="icons">
                                                                    <img
                                                                        src="/images/up-arrow.svg"
                                                                        onClick={e => {
                                                                            e.stopPropagation();
                                                                            let old_suggestions = JSON.parse(JSON.stringify(suggestions));
                                                                            let a = old_suggestions[i];
                                                                            old_suggestions[i] = old_suggestions[i - 1];
                                                                            old_suggestions[i - 1] = a;
                                                                            setSuggestions(old_suggestions);
                                                                        }}
                                                                    />
                                                                    { i != suggestions.length - 1 &&
                                                                        <img
                                                                            src="/images/down-arrow.svg"
                                                                            onClick={e => {
                                                                                e.stopPropagation();
                                                                                let old_suggestions = JSON.parse(JSON.stringify(suggestions));
                                                                                let a = old_suggestions[i];
                                                                                old_suggestions[i] = old_suggestions[i + 1];
                                                                                old_suggestions[i + 1] = a;
                                                                                setSuggestions(old_suggestions);
                                                                            }}
                                                                        />
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                        {suggestionType == "reasons" &&
                                            suggestions.map((s, i) => {
                                                return (
                                                    <div key={i} className="command" onClick={() => setSuggestedReason(message, s)}>
                                                        <div className="command">
                                                            <p>{s}</p>
                                                        </div>
                                                        <div className="buttons">
                                                            { i == 0 ? (
                                                                <p>Press Tab</p>
                                                            ) : (
                                                                <div className="icons">
                                                                    <img
                                                                        src="/images/up-arrow.svg"
                                                                        onClick={e => {
                                                                            e.stopPropagation();
                                                                            let old_suggestions = JSON.parse(JSON.stringify(suggestions));
                                                                            let a = old_suggestions[i];
                                                                            old_suggestions[i] = old_suggestions[i - 1];
                                                                            old_suggestions[i - 1] = a;
                                                                            setSuggestions(old_suggestions);
                                                                        }}
                                                                    />
                                                                    { i != suggestions.length - 1 &&
                                                                        <img
                                                                            src="/images/down-arrow.svg"
                                                                            onClick={e => {
                                                                                e.stopPropagation();
                                                                                let old_suggestions = JSON.parse(JSON.stringify(suggestions));
                                                                                let a = old_suggestions[i];
                                                                                old_suggestions[i] = old_suggestions[i + 1];
                                                                                old_suggestions[i + 1] = a;
                                                                                setSuggestions(old_suggestions);
                                                                            }}
                                                                        />
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div> : <></>
                                }
                                <div className="input-field" style={message ? { background: "#3B4256" } : undefined}>
                                    <input
                                        readOnly={counterDate !== null}
                                        value={message}
                                        onChange={e => inputChange(e)}
                                        onKeyDown={e => inputKeyDown(e)}
                                        maxLength={100}
                                        className={message ? "active" : undefined}
                                    />
                                    <span className='label'>Your message</span>
                                    <span className="suggestion">{suggestion}</span>
                                </div>
                                { user && (
                                    <div
                                        className="title"
                                        onClick={e => setTitleAnchor(e)}
                                    >
                                        {titleLoading 
                                            ? ( <img src="/images/loader.svg" /> ) 
                                            : ( <img src={"/images/titles/Type=" + user?.title + ".svg"} /> )
                                        }
                                    </div>
                                )}
                                <button onClick={sendMessage}><img src="/images/send.svg" /></button>
                                
                                {/* Titles Menu */}
                                { titleAnchor &&
                                    <Popover
                                        anchorEl={titleAnchor}
                                        open={titleOpened}
                                        onClose={handleClose}
                                        className="titles-popover"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <div className="titles">
                                            <div className="selectables">
                                                { user?.titles?.map((title, i) => {
                                                    if (title == user?.title) return <></>;
                                                    return (
                                                        <div key={i} className="title" onClick={() => changeTitle(title)}>
                                                            <img src={"/images/titles/Type=" + title + ".svg"} />
                                                            
                                                            <div className="title-name">{title}</div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className="title">
                                                <img src={"/images/titles/Type=" + user?.title + ".svg"} />
                                            </div>
                                        </div>
                                    </Popover>
                                }
                            </div>
                            {props.mobile && <>{props.children}</> }
                        </>
                    )}
                </form>
            </div>
        </div>
    )
}