import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { useMediaQuery } from '@mui/material';
import Currencies from '../../components/currencies';
import { useUser } from '../../lib/hooks';
// import ConnectDiscordModal from './discord-modal';
import { generateGamesColumns, generateTransactionsColumns } from './columns';
import { seperateBalance } from '../../helpers/balance';
import './profile.scss';

export default function Profile() {

    const { data: user, refetch } = useUser();

    const [discord, setDiscord] = useState(false);
    const [statisticType, setStatisticType] = useState("robux");
    const [historyType, setHistoryType] = useState("games");
    const [gamesDatas, setGamesDatas] = useState(user?.history?.games || []);
    const [transactionsDatas, setTransactionsDatas] = useState(user?.history?.transactions || []);
    const [gamesColumn, setGamesColumn] = useState([]);
    const [transactionsColumn, setTransactionsColumn] = useState([]);
    const [sorted, setSorted] = useState(true);
    const [rakebackDatas, setRakebackDatas] = useState({})

    const res_768 = useMediaQuery('(max-width:768px)');

    useEffect(() => {
        setGamesColumn(generateGamesColumns(res_768));
        setTransactionsColumn(generateTransactionsColumns(res_768));
    }, [res_768]);

    useEffect(() => {
        if (user) {
            const fragment = new URLSearchParams(window.location.hash.slice(1));
            const [accessToken, tokenType] = [fragment.get('access_token'), fragment.get('token_type')];

            if (accessToken) {
                fetch('https://discord.com/api/users/@me', {
                    headers: {
                        authorization: `${tokenType} ${accessToken}`,
                    },
                })
                    .then(res => {
                        return res.json();
                    })
                    .then(res => {
                        fetch(process.env.REACT_APP_API_ENDPOINT + "/user/set-discord", {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                discord_data: res,
                                user_id: user.id,
                                secret_id: user._id
                            })
                        })
                            .then(data => data.json())
                            .then(data => {
                                if (data.err) {
                                    toast.dismiss();
                                    toast.error(<span><b>Error</b><br />{data.err}</span>,
                                        {
                                            hideProgressBar: true,
                                            theme: "dark"
                                        });
                                } else {
                                    setDiscord(res);
                                    window.history.replaceState({}, null, "profile");
                                }
                            })
                    });
            }

            else if (user?.discord) setDiscord(user.discord);

            //Rakeback datas
            fetch(process.env.REACT_APP_API_ENDPOINT + "/user/get-rakeback", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_id: user.id,
                    secret_id: user._id
                })
            })
            .then(data => data.json())
            .then(data => {
                if (data?.err) {
                    toast.error(data.err, {
                        theme: 'dark',
                        hideProgressBar: true,
                    });
                } else if (data?.data) {
                    let r = data.data;

                    switch (r.rank) {
                        case "bronze":
                            r.color = "#D7562E"
                            break;
                        case "silver":
                            r.color = "#8C8C8C"
                            break;
                        case "gold":
                            r.color = "gold"
                            break;
                    }

                    setRakebackDatas(r);
                }
            });
        }
    }, [user]);

    // Payment-related requests
    useEffect(() => {
        async function fetchPaymentData() {
            if (user && rakebackDatas) {
                //Fiat Status
                await fetch(process.env.REACT_APP_API_ENDPOINT + "/payments/fiat-status", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        user_sid: user._id
                    })
                })
                .then(data => data.json())
                .then(data => {
                    refetch();
                });
                
                // Update transaction history if needed
                fetch(process.env.REACT_APP_API_ENDPOINT + '/payments/robux-status', {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id: user.id,
                        page: "Deposit",
                    })
                })
                .then(data => data.json())
                .then(data => {
                    if (data?.status) refetch();
                });
            }
        }
        fetchPaymentData();
    }, [rakebackDatas]);

    if (!user) return <></>;
    return (
        <div id="profile">
            <div className="profile-header">
                <div className="profile-info">
                    {rakebackDatas?.rank &&
                        <div className="pfp-container">
                            <img className="pfp" style={{ borderColor: rakebackDatas?.color }} src={user.pfp} /> {/* Roblox Avatar */}
                            <div className="secondary-pfp" style={{ borderColor: rakebackDatas?.color }}><img src={"images/levels/" + rakebackDatas?.rank + ".svg"} /></div> {/* Secondary Avatar */}
                        </div>
                    }
                    <div className="general-info">
                        <div className="id">#{user.id}</div> {/* Unique ID */}
                        <div className="username">{user.username}</div> {/* Roblox Username */}
                        {discord
                            ? (
                                <div className="discord active">
                                    <img src="images/discord.svg" />
                                    <span>{discord?.username}</span> {/* Discord Username */}
                                </div>
                            ) : (
                                <a
                                    className="discord inactive"
                                    href="https://discord.com/api/oauth2/authorize?client_id=1083394399406411886&redirect_uri=https%3A%2F%2Frbxocean.com%2Fprofile&response_type=token&scope=identify%20email"
                                >
                                    <img src="images/discord-active.svg" />
                                    <span>Connect discord</span> {/* Connect Roblox Link */}
                                </a>
                            )
                        }
                        <Currencies user={user} style={{ justifyContent: "flex-start" }} />
                    </div>
                </div>
                <div className="deposit-withdraw-buttons">
                    <Link to="/deposit">
                        <div className="deposit-button">
                            <img src="images/plus.svg" />
                            <span>Deposit</span>
                        </div>
                    </Link>
                    <Link to="/withdraw">
                        <div className="withdraw-button">
                            <img src="images/minus.svg" />
                            <span>Withdraw</span>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="profile-navbar">
                <div className="statistic">
                    <h2>Statistic</h2>
                    <div className="buttons">
                        <button
                            onClick={() => setStatisticType("robux")}
                            className={"left-button " + (statisticType === "robux" ? "active" : undefined)}
                        >Robux</button>
                        <button
                            onClick={() => setStatisticType("points")}
                            className={statisticType === "points" ? "active" : undefined}
                        >Points</button>
                    </div>
                </div>
                <div className="datas">
                    {/* Total deposits */}
                    <div>
                        <h3>Total deposits</h3>
                        {statisticType === "robux"
                            ? //Robux Selected
                            <div className="currency-container">
                                <img src="images/robux.svg" />
                                {seperateBalance(user?.statistics?.deposit?.[statisticType])}
                            </div>
                            : //Points Selected
                            <div className="currency-container">
                                <img src="images/points.png" className="points" />
                                {seperateBalance(user?.statistics?.deposit?.[statisticType])}
                            </div>
                        }
                    </div>
                    {/* Total withdrawn */}
                    <div>
                        <h3>Total withdrawn</h3>
                        {statisticType === "robux"
                            ? //Robux Selected
                            <div className="currency-container">
                                <img src="images/robux.svg" />
                                {seperateBalance(user?.statistics?.withdraw?.[statisticType])}
                            </div>
                            : //Points Selected
                            <div className="currency-container">
                                <img src="images/points.png" className="points" />
                                {seperateBalance(user?.statistics?.withdraw?.[statisticType])}
                            </div>
                        }
                    </div>
                    {/* Total wagered */}
                    <div>
                        <h3>Total wagered</h3>
                        {statisticType === "robux"
                            ? //Robux Selected
                            <div className="currency-container">
                                <img src="images/robux.svg" />
                                {seperateBalance(user?.statistics?.wager?.[statisticType])}
                            </div>
                            : //Points Selected
                            <div className="currency-container">
                                <img src="images/points.png" className="points" />
                                {seperateBalance(user?.statistics?.wager?.[statisticType])}
                            </div>
                        }
                    </div>
                    {/* Winnings */}
                    <div>
                        <h3>{statisticType === "robux" ? "Rain winnings" : "Winnings"}</h3>
                        {statisticType === "robux"
                            ? //Robux Selected
                            <div className="currency-container">
                                <img src="images/robux.svg" />
                                {seperateBalance(user?.statistics?.rainWinnings?.[statisticType])}
                            </div>
                            : //Points Selected
                            <div className="currency-container">
                                <img src="images/points.png" className="points" />
                                {seperateBalance(user?.statistics?.winnings?.[statisticType])}
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="profile-body">
                <div className="history-header">
                    <h2>History</h2>
                    <div>
                        <button
                            onClick={() => setHistoryType("games")}
                            className={"left-button " + (historyType === "games" ? "active" : undefined)}
                        >Games</button>
                        <button
                            onClick={() => setHistoryType("transactions")}
                            className={historyType === "transactions" ? "active" : undefined}
                        >Transactions</button>
                    </div>
                </div>
                {res_768 &&
                    <div className="sorting-container">
                        <div className="sort" onClick={() => {
                            setSorted(!sorted);
                            setGamesDatas([...gamesDatas]);
                            setTransactionsDatas([...transactionsDatas]);
                        }}>
                            { sorted ? (
                                <>
                                    <span>New ones first</span>
                                    <img src="images/down.svg" />
                                </>
                            ) : (
                                <>
                                    <span>Old ones first</span>
                                    <img src="images/down.svg" style={{ transform: "rotateZ(180deg)" }} />
                                </>
                            )}
                        </div>
                    </div>
                }
                <div>
                    <DataTable
                        className="profile-table"
                        columns={historyType === "games" ? gamesColumn : transactionsColumn}
                        data={historyType === "games" ? [...gamesDatas] : [...transactionsDatas].reverse()}
                        striped
                        noHeader
                    />
                </div>
            </div>

            {/* Connect Discord Modal */}
            {/*<ConnectDiscordModal 
                setDiscordModal={setDiscordModal} 
                discordModal={discordModal}
                setDiscord={setDiscord}
            />*/}
        </div>
    );
}