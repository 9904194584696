import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { setLang } from '../../store/reducers/languageReducer';
import Leaderboard from '../leaderboard';
import './leftnav.scss';

export default function LeftNav({ path, dev }) {

    const dispatch = useDispatch();
    const lang = useSelector(state => state.lang);

    const [leaderboardModal, setLeaderboard] = useState(false);

    return (
        <div id="left-nav">
            <div className="top-items">
                <div className={"item " + ((path === "/crash" || path === "/") && "active")}>
                    <Link className={path === "/crash" ? "active" : undefined} to="/crash">
                        {(path === "/crash" || path === "/")
                            ? <><img src="images/crash-active.svg" /><img className="light" src="images/light-3.png" /></>
                            : <img src="images/crash.svg" />}
                        <p>Crash</p>
                    </Link>
                </div>
                <div className={"item " + (path === "/mines" && "active")}>
                    <Link to="/mines">
                        {path === "/mines" 
                            ? <><img src="images/mines-active.svg" /><img className="light" src="images/light-3.png" /></>
                            : <img src="images/mines.svg" />}
                        <p>Mines</p>
                    </Link>
                </div>
                <div className={"item " + (path === "/roulette" && "active")}>
                    <Link to="/roulette">
                        {path === "/roulette" 
                            ? <><img src="images/roulette-active.svg" /><img className="light" src="images/light-3.png" /></>
                            : <img src="images/roulette.svg" />}
                        <p>Roulette</p>
                    </Link>
                </div>
            </div>
            <div className="bottom-items">
                <div className="item">
                    <a onClick={() => setLeaderboard(true)}>
                        <img src="images/trophy.svg" />
                    </a>
                </div>
                <div className="item">
                    <PopupState variant="popover">
                        {(popupState) => (
                            <div>
                                <a
                                    variant="contained"
                                    {...bindTrigger(popupState)}
                                >
                                    <img src={lang.flags[lang.lang]} />
                                </a>
                                <Popover
                                    {...bindPopover(popupState)}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'center',
                                        horizontal: 'left',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            backgroundColor:"#1E222C",
                                            height:"110px",
                                            display: "flex",
                                            flexDirection: "column",
                                            borderRadius: "0px 16px 16px 0px",
                                            border: "1px solid #2E323D"
                                        }}
                                    >
                                        <div className="language-menu left-nav-lang">
                                            { Object.keys(lang.flags).map((key, i) => {
                                                if (key !== lang.lang) {
                                                    return (
                                                        <div className='item' key={key}>
                                                            <a onClick={() => {
                                                                localStorage.setItem("language", key);
                                                                dispatch(setLang(key));
                                                                popupState.setOpen(false);
                                                            }}>
                                                                <img src={lang.flags[key]} style={{ width: "25px", height: "25px" }} />
                                                            </a>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </Typography>
                                </Popover>
                            </div>
                        )}
                    </PopupState>
                </div>
                <div className="item">
                    <a target="_blank" href="https://discord.gg/dxucmhMSMv">
                        <img src="images/support.svg" />
                    </a>
                </div>
            </div>

            { dev !== false &&
                <Leaderboard
                    leaderboardModal={leaderboardModal}
                    setLeaderboard={setLeaderboard}
                />
            }
        </div>
    )
}