import './item.scss';

const rarityTable = {
    red: "#C11B1B",
    purple: "#8C4AFF",
    green: "#38AF1A"
}

export default function Item({ image, name, price, rarity, selectedItems, setSelected, index }) {

    price = price.toFixed(2).toString();

    return (
        <div
            className={"game-item " + (selectedItems.includes(index) && "selected")}
            onClick={() => {
                if (selectedItems.includes(index)) {
                    let arr = [...selectedItems];
                    arr.splice(selectedItems.indexOf(index), 1);
                    setSelected(arr);
                } else {
                    let arr = [...selectedItems];
                    arr.push(index)
                    setSelected(arr);
                }
            }}
        >
            <div>
                <img className='item-image' src={image} />
            </div>
            <div className="item-name">
                <span>{name}</span>
            </div>
            <div className="price">
                <img src="images/points.png" className="points" />
                <span>{price?.split(".")[0]}<span className='float'>.{price?.split(".")[1]}</span></span>
            </div>

            <div className="rarity" style={{backgroundColor: rarityTable[rarity]}}></div>
            { selectedItems.includes(index) &&
                <img className='selected' src="images/selected.svg" />
            }
        </div>
    )
}